import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { selectIsFetchingBuildings } from 'redux/selectors/buildingSelectors'
import MyBuildingsListItem from 'modules/myBuildings/components/MyBuildingsListItem'
import Loading from 'components/atoms/Loading'

const MyBuildingsList = ({ buildings }) => {
  const isFetching = useAppSelector(selectIsFetchingBuildings)

  return isFetching ? <Loading /> : (
    <div>
      {buildings && buildings.map(building => (
        <MyBuildingsListItem key={building.id} building={building} />
      ))}
    </div>
  )
}

export default MyBuildingsList
