import React from 'react'
import Icon from './Icon'

export const FetchIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='9'
      height='9'
      viewBox='0 0 9 9'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
     <path d="M4.5 0V6.125L7.125 3.5L7.5 3.832L4.25 7.082L1 3.832L1.375 3.5L4 6.125V0H4.5ZM0 7.5H0.5V8.5H8V7.5H8.5V9H0V7.5Z" fill="#D14925"/>
    </Icon>
  )
}

export default FetchIcon
