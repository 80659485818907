import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Collapse } from '@mui/material'
import { isNotNilOrEmpty } from 'utils/ramda'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'

type ExpandableInfoTypes = {
  title?: string
  children?: ReactNode
  shortDescription?: ReactNode | string
  teal?: boolean
}

const ExpandableInfo = ({ title, children, shortDescription, teal }: ExpandableInfoTypes) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(prev => !prev)

  const hasChildren = isNotNilOrEmpty(children)

  return (
    <Wrapper teal={teal}>
      <Header onClick={hasChildren ? toggleOpen : () => {
      }}>
        <TitleWrapper>
          <StyledIcon teal={teal} />
          <div>
            <Title>{title}</Title>
            <ShortDesc>
              {shortDescription}
            </ShortDesc>
          </div>
        </TitleWrapper>
        {
          hasChildren && (
            <>
              {
                open
                  ? <KeyboardArrowUpOutlinedIcon />
                  : <KeyboardArrowDownOutlinedIcon />
              }
            </>
          )
        }
      </Header>
      <Collapse in={open}>
        <Content>
          {children}
        </Content>
      </Collapse>

    </Wrapper>
  )
}

export default ExpandableInfo

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.trout};
  background-color: #F7F8FD;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ teal }) => teal && css`
    background-color: #EDF6F7;
    border: 1px solid #B4E6EB;
  `}
`

const StyledIcon = styled(InfoOutlinedIcon)`
  color: ${({ theme }) => theme.colors.trout};

  ${({ teal }) => teal && css`
    color: ${({ theme }) => theme.colors.secondary};
  `}
`

const Title = styled.div`
  font-weight: bold;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const TitleWrapper = styled.div`
  display: flex;
  gap: 14px;
  cursor: pointer;
`

const Content = styled.div`
  overflow: hidden;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 40px;
`

const ShortDesc = styled.div`
  font-size: 12px;
`
