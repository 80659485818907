import React, { useEffect, useMemo, useState } from 'react'
import FiltersSection from 'modules/myBuildings/components/filters/FiltersSection'
import { useAppSelector } from 'redux/hooks'
import { selectBuildingFilters } from 'redux/selectors/dictionarySelectors'
import { keys, propOr, values as ramdaValues, equals } from 'ramda'
import StandaloneCheckbox from 'components/atoms/StandaloneCheckbox'
import { isNotNilOrEmpty } from 'utils/ramda'

const FaultReportEquipmentTypesFilter = ({
  onChange,
  counter,
  queryFilters
}) => {
  const buildingFilters = useAppSelector(selectBuildingFilters)
  const filters = propOr({}, 'faultReportEquipmentTypes', buildingFilters)
  const [values, setValues] = useState({})
  const [lastChosen, setLastChosen] = useState({})
  const isAnyOption = option => {
    return option.name === 'Any active reported fault'
  }

  useEffect(() => {
    if (isNotNilOrEmpty(values)) {
      const query = keys(values)
        .map(key => (values[key] ? key : null))
        .filter(v => isNotNilOrEmpty(v))
        .join(',')
      onChange('fault_report_equipment_types', query)
    }
  }, [values])

  const handleChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!equals(queryFilters, lastChosen)) {
      const newValues = {}
      for (const key in values) {
        newValues[key] = queryFilters.includes(key)
      }
      setValues(newValues)
      setLastChosen(queryFilters)
    }
  }, [queryFilters])

  useEffect(() => {
    const newFilters = {}
    if (isNotNilOrEmpty(queryFilters)) {
      queryFilters.forEach(v => {
        newFilters[v] = true
      })
      setValues(prev => ({ ...prev, ...newFilters }))
    }
  }, [])

  const content = useMemo(() => {
    if (isNotNilOrEmpty(filters)) {
      // @ts-ignore
      return ramdaValues(filters).map((option: any) => {
        return !isAnyOption(option) ? (
          <StandaloneCheckbox
            disabled={option.count === 0}
            key={`fault_report_equipment_types-${option.id}`}
            name={option.id}
            label={`${option.name} (${option.count})`}
            onChange={handleChange}
            initialValue={values[option.id] || false}
          />
        ) : null
      })
    } else {
      return null
    }
  }, [filters, queryFilters, values])

  return (
    <FiltersSection title='Faults' counter={counter}>
      {content}
    </FiltersSection>
  )
}

export default FaultReportEquipmentTypesFilter
