export const PATHS = {
  home: '/',
  homeWithSlug: '/:slug',
  login: '/login',
  register: '/register',
  registerConfirm: '/registerConfirm',
  buildingsList: '/buildings',
  buildingDetails: '/buildings/:id',
  terms: '/terms-and-conditions',
  cookies: '/cookie-policy',
  privacy: '/privacy-policy',
  emailConfirmation: '/email-verification',
  forgottenPassword: '/forgotten-password',
  passwordReset: '/password-reset'
}
