import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormSeparator } from 'components/formComponents'
import ResponsiblePersonForm from 'modules/forms/addBuildingForm/components/ResponsiblePersonForm'
import {dissoc, findIndex, last, propEq, update} from 'ramda'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  selectCurrentBuilding,
  selectIsAddingBuilding
} from 'redux/selectors/buildingSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import ThanksMessageModal from 'modules/forms/components/ThanksMessageModal'
import { setActiveStep, updateFormValues } from 'redux/slices/formSlice'
import TextButton from 'components/atoms/TextButton'
import { PlusIcon } from 'assets/icons'
import Button from 'components/atoms/Button'

const ResponsiblePersonsStep = () => {
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const [added, setAdded] = useState<any[]>([])
  const [thanksModalOpen, setThanksModalOpen] = useState(false)
  const [forms, setForms] = useState([
    {
      index: Math.ceil(Math.random() * 100000),
      trigger: 0,
      submitted: false,
      stepSubmit: false,
      values: null
    }
  ])
  const isEditForm = isNotNilOrEmpty(currentBuilding)
  const isAddingBuilding = useAppSelector(selectIsAddingBuilding)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isEditForm) {
      const responsiblePersons = currentBuilding.responsiblePersons || []
      dispatch(updateFormValues({ responsiblePersons }))
      setAdded(responsiblePersons)
      if (isNotNilOrEmpty(responsiblePersons)) {
        setForms([
          ...responsiblePersons.map(person => ({
            index: Math.ceil(Math.random() * 100000),
            trigger: 0,
            submitted: true,
            stepSubmit: false,
            values: person
          }))
        ])
      } else {
        setForms([
          {
            index: Math.ceil(Math.random() * 100000),
            submitted: false,
            trigger: 0,
            stepSubmit: false,
            values: null
          }
        ])
      }
    }
  }, [currentBuilding])

  const handleAddPerson = formIndex => data => {
    const index = Math.ceil(Math.random() * 100000)

    const getUpdatedForms = prev => {
      const form = prev.find(form => form.index === formIndex)
      const arrayIndex = findIndex(propEq('index', formIndex))(prev)

      return update(
        arrayIndex,
        {
          ...form,
          submitted: true
        },
        prev
      )
    }

    setAdded(prev => [...prev, dissoc('index', data)])
    // @ts-ignore
    setForms(prev => [
      ...getUpdatedForms(prev),
      {
        index,
        submitted: false,
        trigger: 0,
        stepSubmit: false,
        values: null
      }
    ])
    const top = document.getElementById('scroll')
    top && top.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  useEffect(() => {
    dispatch(
      updateFormValues({
        responsiblePersons: added
      })
    )
  }, [added])

  const handleGoBack = () => {
    dispatch(setActiveStep('building_information'))
  }

  const handleEditPerson = index => values => {
    const arrayIndex = findIndex(propEq('index', index))(forms)
    setAdded(prev => update(arrayIndex, values, prev))
  }

  const handleRemovePerson = index => () => {
    const arrayIndex = findIndex(propEq('index', index))(forms)
    setAdded(prev => prev.filter((_, index) => index !== arrayIndex))
    setForms(prev => prev.filter((_, index) => index !== arrayIndex))
  }

  const triggerSubmit =
    (index, stepSubmit = false) =>
      () => {
        const form = forms.find(form => form.index === index)
        const arrayIndex = findIndex(propEq('index', index))(forms)
        // @ts-ignore
        setForms(prev =>
          update(
            arrayIndex,
            {
              ...form,
              // @ts-ignore
              trigger: form.trigger + 1,
              stepSubmit
            },
            prev
          )
        )
      }

  return (
    <div>
      <span id='scroll' />
      <ThanksMessageModal
        open={thanksModalOpen}
        onClose={() => setThanksModalOpen(false)}
        mode='buildingPlans'
      />
      {forms.map(form => (
        <div key={`rp-form-${form.index}`}>
          <ResponsiblePersonForm
            added={added}
            onSubmit={handleAddPerson(form.index)}
            onTrigger={triggerSubmit(form.index)}
            onEdit={handleEditPerson(form.index)}
            onRemove={handleRemovePerson(form.index)}
            triggerSubmit={form.trigger}
            stepSubmit={form.stepSubmit}
            formsCount={forms.length}
            values={form?.values || null}
          />
        </div>
      ))}
      <GDPRBox>
        <GDPRTitle>What we do with your data?</GDPRTitle>
        <GDPRDescription>
          Names and contact information is stored in line with GDPR and in
          accordance with Building Passport's&nbsp;
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.buildingpassport.com/privacy-policy'
          >
            Privacy Policy
          </a>
        </GDPRDescription>
      </GDPRBox>
      <TextButton
        label='Add another responsible person'
        icon={<PlusIcon />}
        onClick={() => {
          const lastForm = last(forms)
          // @ts-ignore
          if (lastForm?.submitted) {
            const index = Math.ceil(Math.random() * 100000)
            setForms(prev => [
              ...prev,
              {
                index,
                submitted: false,
                trigger: 0,
                stepSubmit: false,
                values: null
              }
            ])
          } else {
            triggerSubmit(lastForm?.index)()
          }
        }}
      />
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={handleGoBack}>
          Back
        </Button>
        <Button
          onClick={triggerSubmit(last(forms)?.index, true)}
          disabled={isAddingBuilding}
        >
          {isEditForm ? 'Save changes' : 'Add building'}
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default ResponsiblePersonsStep

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px !important;
  }
`

const GDPRBox = styled.div`
  background-color: #edf1f7;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 13px;
  margin: 30px;
`

const GDPRTitle = styled.div`
  font-weight: bold;
`

const GDPRDescription = styled.div`
  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`
