import React from 'react'
import { Chip, Stack } from '@mui/material'
import { useLocation, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'
import { propOr, omit } from 'ramda'
import { snakeCase } from 'lodash'

interface ChosenFilterProps {
  label: string
  key: string
}
const ChosenFilter = (props: ChosenFilterProps) => {
  const { label, key } = props
  // @ts-ignore
  const getKeyFromLabel = label.match(/^([^:]*)/)[1]
  const { search } = useLocation()
  const [, setSearchParams] = useSearchParams()

  const handleDelete = () => {
    const labelKey = snakeCase(getKeyFromLabel)
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const filtersFromQuery: {} = propOr({}, 'filter', parsedQuery)
    const isKeyInQuery = Object.keys(filtersFromQuery).includes(labelKey)

    if (isKeyInQuery) {
      const filterPayload = omit([labelKey], {
        ...filtersFromQuery
      })
      // @ts-ignore
      const query = qs.stringify({
        ...parsedQuery,
        filter: filterPayload
      })
      setSearchParams(query)
    }
  }

  return (
    <Container>
      <Stack direction='row' spacing={1} key={key}>
        <Chip size='medium' label={label} onDelete={handleDelete} />
      </Stack>
    </Container>
  )
}

export default ChosenFilter

const Container = styled.div`
  padding: 5px;
  max-width: 900px;
`
