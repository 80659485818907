import React, { useState } from 'react'
// @ts-ignore
import EditIcon from 'assets/icons/EditIcon'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import { useAppSelector } from 'redux/hooks'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import { getFloorOptions } from 'utils/buildings'
import Select from 'components/atoms/Select'
import { useForm } from 'react-hook-form'
import { isNilOrEmpty } from 'utils/ramda'
import Button from 'components/atoms/Button'

const EditFloorPlanModal = ({ file, onChange }) => {
  const [open, setOpen] = useState(false)
  const formValues = useAppSelector(selectActiveFormValues)
  const floorOptions = getFloorOptions(formValues)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      floorNumbers: file.floorNumbers || []
    }
  })

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const submit = () => {
    handleSubmit(data => {
      onChange(data.floorNumbers)
      handleClose()
    })()
  }

  const selectedFloors = watch('floorNumbers')

  return (
    <>
      <StyledEditIcon onClick={handleOpen} />
      <Modal open={open} onClose={handleClose} title='Edit floor plan'>
        <Wrapper>
          <FileName>{file?.displayName}.pdf</FileName>
          <Select
            name='floorNumbers'
            multiple
            control={control}
            label='Applicable floors'
            error={errors.floorNumbers?.message}
            options={floorOptions}
          />
          <ButtonsWrapper>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={isNilOrEmpty(selectedFloors)}
              onClick={submit}
            >
              Save
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Modal>
    </>
  )
}

export default EditFloorPlanModal

const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.primary};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 15px;

  button {
    width: 150px;
  }
`

const FileName = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  width: 500px;
`
