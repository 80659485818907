import React from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import { Pagination } from '@mui/material'
import { isNotNilOrEmpty } from 'utils/ramda'
import { Link } from 'react-router-dom'

type PaginationTypes = {
  currentPage: number
  totalPages: number
}

type CellTypes = {
  path?: string
  children: any
  preventRedirect?: boolean
  inactive?: boolean
  active?: boolean
  align?: string
}

type RowCellTypes = {
  align?: string
  preventRedirect?: boolean
  children: any
}

type RowTypes = {
  cells: RowCellTypes[]
  redirectPath?: string
  isInactive?: boolean
  isActive?: boolean
}

type TableTypes = {
  headers: object[]
  rows: RowTypes[]
  emptyState?: string
  pagination?: PaginationTypes
  onPageChange?: any
  onRowClick?: any
}

type HeaderTypes = {
  children?: any
  align?: string
}

const Td = ({ path = '', children, preventRedirect }: CellTypes) => isNotNilOrEmpty(path) && !preventRedirect
  ? <TableCell><Link to={path}>{children}</Link></TableCell>
  : <TableCell>{children}</TableCell>

const Table = ({
  headers,
  rows,
  emptyState,
  pagination,
  onPageChange,
  onRowClick
}: TableTypes) => {
  const handleRowClick = (rowIndex: number) => () => {
    onRowClick && onRowClick(rowIndex)
  }

  return (
    <>
      <RoundedWrapper>
        <TableWrapper>
          <thead>
          <HeaderRow>
            {headers.map((header: HeaderTypes, index: number) => (
              <HeaderCell
                key={`table-header-${index}`}
                align={header.align}
              >
                <HeaderContent>
                  <div>
                    {header.children}
                  </div>
                </HeaderContent>
              </HeaderCell>
            ))}
          </HeaderRow>
          </thead>
          <tbody>
          {rows.map((row, rowIndex) => (
            <TableRow
              withPointer={isNotNilOrEmpty(onRowClick)}
              key={`table-row-${rowIndex}`}
              onClick={handleRowClick(rowIndex)}
            >
              {row.cells.map((cell, cellIndex) => (
                <Td
                  path={row.redirectPath}
                  inactive={row.isInactive}
                  active={row.isActive}
                  key={`table-cell-${rowIndex}-${cellIndex}`}
                  align={cell.align}
                  preventRedirect={cell.preventRedirect}
                >
                  <CellChildren>
                    {cell.children}
                  </CellChildren>
                </Td>
              ))}
            </TableRow>
          ))}
          </tbody>
        </TableWrapper>
      </RoundedWrapper>
      <PaginationWrapper>
        {pagination && pagination.totalPages > 1 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.currentPage}
            onChange={onPageChange}
          />
        )}
      </PaginationWrapper>
      {isEmpty(rows) && <EmptyState>{emptyState}</EmptyState>}
    </>
  )
}

Table.defaultProps = {
  headers: [],
  rows: [],
  emptyState: 'Table is empty',
  onRowClick: () => {
  },
  onPageChange: () => {
  }
}

export default Table

const RoundedWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #EAECF0;
`

const TableWrapper = styled.table`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(101, 118, 184, 0.08), 0 2px 4px rgba(101, 118, 184, 0.12), 0 8px 16px rgba(101, 118, 184, 0.06), 0 1px 2px rgba(101, 118, 184, 0.06);

  tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid #EAECF0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }

  td, th {
    padding: 5px 10px;
  }

  th {
    vertical-align: center;
    background-color: #F7F8FD;
    text-transform: uppercase;
    height: 44px;
    font-size: 12px;
  }

  td {
    height: 72px;
    font-size: 14px;
  }
`

const HeaderRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border} !important;
  background-color: transparent !important;
`

const HeaderCell = styled.th`
  text-align: ${({ align }) => align || 'left'};
  vertical-align: middle;
  font-size: 14px;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`

const TableCell = styled.td`
  color: ${({ theme, inactive }) => inactive ? theme.colors.disabled : theme.colors.text};
  text-align: ${({ align }) => align || 'left'};
  font-size: 13px;
  background-color: ${({ theme, active }) => active ? theme.colors.lightGrey : 'inherit'};
`

const CellChildren = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const EmptyState = styled.div`
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top: -10px;
`

const PaginationWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
`

const TableRow = styled.tr`
  cursor: ${({ withPointer }) => withPointer ? 'pointer' : 'default'};
`
