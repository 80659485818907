import React from 'react'
import styled from 'styled-components'
import ContentItem from './ContentItem'

const InformationPanel = () => {
  const items = [
    {
      title: 'Floor plans and building plan',
      content: [
        'Regulation 6 of the Fire Safety (England) Regulations 2022 requires responsible persons of high-rise residential buildings in England to share up-to-date plans of the building with the fire and rescue service. The plans will be used by operational fire-fighters during an incident and need to be clear, simple and easy to use.'
      ]
    },
    {
      title: 'Design and materials of external walls',
      content: [
        'Regulation 5 of the Fire Safety (England) Regulations 2022 require responsible persons of high-rise residential buildings to prepare a record of the design of the external walls and share it with the fire and rescue service. The purpose of providing this information is to assist with operational pre-planning and to provide information that will be of value to responding crews at the time of a fire.' +
          '\n' + '\n' +
          'As well as providing details of the materials used in the wall construction, responsible persons will also be required to provide information on the level of risk of fire spread that the external wall structure poses, and any steps that have been taken to mitigate these risks.'
      ]
    },
    {
      title:
        'Faults and repairs of lifts and essential fire-fighting equipment',
      content: [
        'Regulation 7 of the Fire Safety (England) Regulations 2022 requires responsible persons of high-rise residential buildings to undertake monthly routine checks of specific fire safety equipment.  The equipment covered by this requirement is:\n' +
          '\n' +
          '• Lifts for use by firefighters or evacuation lifts\n' +
          '• Automatic door release mechanisms\n' +
          '• Evacuation alert systems\n' +
          '• Fire detection and alarm systems\n' +
          '• Rising mains\n' +
          '• Smoke control systems\n' +
          '• Suppression systems\n' +
          '\n' +
          'Where faults with any of this equipment are identified, the responsible person must take steps to rectify the fault.  If the fault cannot be rectified within 24 hours, then the fault must be reported to the fire and rescue service.  The fire and rescue service must be informed once the fault has been rectified.\n' +
          '\n' +
          'This information will assist fire and rescue services by highlighting faults with equipment, which both residents and firefighters may rely on for their safety, so that it can be factored into their operational response. '
      ]
    }
  ]
  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>Fire safety regulations</HeaderTitle>
        <HeaderContent data-end='?'>
          What information needs to be shared
        </HeaderContent>
      </HeaderWrapper>
      <ContentWrapper>
        {items.map((item, index) => (
          <ContentItem key={index} title={item.title} content={item.content} />
        ))}
      </ContentWrapper>
    </Wrapper>
  )
}

export default InformationPanel

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0 96px;
  background-color: ${({ theme }) => theme.colors.background};
`
const HeaderWrapper = styled.div`
  text-align: center;
  width: 800px;
`
const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.copper};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bolder;
`
const HeaderContent = styled.div`
  font-size: 36px;
  margin: 12px 0 24px;
  &::after {
    content: attr(data-end);
    color: ${({ theme }) => theme.colors.copper};
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 846px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(101, 118, 184, 0.12);

  > * {
    margin: 0 24px;
    padding: 26px 0;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    }
  }
`
