import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from 'redux/slices/authSlice'
import { dictionaryReducer } from 'redux/slices/dictionarySlice'
import { formSliceReducer } from 'redux/slices/formSlice'
import { buildingReducer } from 'redux/slices/buildingSlice'
import { faultsReducer } from 'redux/slices/faultsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dictionary: dictionaryReducer,
    form: formSliceReducer,
    buildings: buildingReducer,
    faults: faultsReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
