import { createSelector } from 'reselect'
import { RootState } from 'redux/store'

const formState = (state: RootState) => state.faults

export const selectFaultsList = createSelector(
  formState,
  state => state.faultsList
)

export const selectFaultsListPagination = createSelector(
  formState,
  state => state.faultsListPagination
)

export const selectFaultToEdit = createSelector(
  formState,
  state => state.faultToEdit
)
