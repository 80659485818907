import React from 'react'
import styled from 'styled-components'

type ProgressBarTypes = {
  value: number
}

const ProgressBar = ({ value }: ProgressBarTypes) => {
  const progress = Math.max(0, Math.min(100, value))

  return (
    <Wrapper>
      <BarTrack>
        <Bar progress={progress} />
      </BarTrack>
      <Value>{Math.round(value)}%</Value>
    </Wrapper>
  )
}

export default ProgressBar

const Wrapper = styled.div`
  min-width: 80px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`

const Value = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.trout};
`

const BarTrack = styled.div`
  background-color: #EAECF0;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  position: relative;
`

const Bar = styled.div`
  background-color: ${({ progress, theme }) => progress === 100 ? theme.colors.success : '#A4A9BF'};
  width: ${({ progress }) => `${progress}%`};
  height: 8px;
  border-radius: 4px;
  transition: all .3s;
`
