import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Collapse } from '@mui/material'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { ArrowDownIcon } from 'assets/icons'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { getBuildingAddress } from 'utils/building'
import ResponsiblePerson from './ResponsiblePerson'

type propsType = {
  background?: string
  isCollapsible?: boolean
  withSmallFont?: boolean
  fullWidth?: boolean
}

const BuildingDetailsInForm = ({
  background,
  isCollapsible,
  fullWidth,
  withSmallFont
}: propsType) => {
  const building = useAppSelector(selectCurrentBuilding)
  const [showDetails, setShowDetails] = useState(false)
  const responsiblePersons = building?.responsiblePersons || []

  const toggleDetails = () => {
    setShowDetails(prev => !prev)
  }

  const address = useMemo(() => {
    return getBuildingAddress(building)
  }, [building])

  return (
    <Wrapper background={background} withSmallFont={withSmallFont}>
      <HeaderWrapper onClick={toggleDetails}>
        <Details>
          <BuildingName withSmallFont={withSmallFont}>
            {building?.name}
          </BuildingName>
          {isCollapsible && (
            <UprnNumber>UPRN: {propOr('---', 'uprn', building)}</UprnNumber>
          )}
        </Details>
        {isCollapsible && (
          <ArrowWrapper>
            <ArrowPointer open={showDetails} />
          </ArrowWrapper>
        )}
      </HeaderWrapper>
      <Collapse in={isCollapsible ? showDetails : true}>
        <BuildingAddress withSmallFont={withSmallFont}>
          {address}
        </BuildingAddress>
        {isNotNilOrEmpty(responsiblePersons) && (
          <>
            <ResponsibleSectionTitle withSmallFont={withSmallFont}>
              Responsible person(s)
            </ResponsibleSectionTitle>
            <Persons fullWidth={fullWidth}>
              {isNotNilOrEmpty(responsiblePersons) ? (
                responsiblePersons.map(rp => {
                  return (
                    <PersonWrapper key={rp.email}>
                      <ResponsiblePerson
                        key={rp.email}
                        person={rp}
                        withSmallFont={withSmallFont}
                      />
                    </PersonWrapper>
                  )
                })
              ) : (
                <div>No responsible persons have been added yet</div>
              )}
            </Persons>
          </>
        )}
      </Collapse>
    </Wrapper>
  )
}

export default BuildingDetailsInForm

const Wrapper = styled.div`
  width: 100%;
  padding: ${({ withSmallFont }) => (withSmallFont ? '13px' : '16px')};
  gap: 16px;
  background: ${({ theme, background }) => background || theme.colors.copperBg};
  border-radius: 8px;
  margin-bottom: 24px;
`
const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`
const ArrowWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.copper};
`

const ArrowPointer = styled(ArrowDownIcon)`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
`

const BuildingName = styled.div`
  font-weight: bold;
  ${({ withSmallFont }) =>
          withSmallFont ? `font-size: 16px;` : `font-size: 24px;`}
  line-height: 21px;
`
const UprnNumber = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.lightTrout};
`
const Persons = styled.div`
  display: flex;
  ${({ fullWidth }) =>
          fullWidth
                  ? css`
                    justify-content: space-between;
                  `
                  : css`
                    flex-direction: column;
                    gap: 12px;
                  `}
`
const Details = styled.div`
  width: 100%;
`
const BuildingAddress = styled.div`
  width: 100%;
  ${({ withSmallFont }) => withSmallFont && `font-size: 13px;`}
  font-weight: 400;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.trout};
`
const ResponsibleSectionTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
  ${({ withSmallFont }) => withSmallFont && `font-size: 13px;`}
  font-weight: 400;
  color: #696d80;
`
const PersonWrapper = styled.div`
  flex: 1;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
