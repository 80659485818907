import React, { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { CheckIcon, DoubleCheckIcon, EditIcon } from 'assets/icons'
import { STATUS_COLOR, STATUS_LABEL } from 'utils/statuses'

type StatusTypes = {
  status: string
  icon?: ReactNode
  withIcon?: boolean
}

const Status = ({ status, icon, withIcon }: StatusTypes) => {
  const lowercaseStatus = status.toLowerCase()
  const statusIcon = useMemo(() => {
    switch (lowercaseStatus) {
      case 'complete':
      case 'completed':
      case 'submitted':
      case 'reported':
        return <CheckIcon fill={STATUS_COLOR[lowercaseStatus].font} />
      case 'rectified':
        return <DoubleCheckIcon fill={STATUS_COLOR[lowercaseStatus].font} />
      case 'draft':
        return <EditIconSmall />
      default:
        return null
    }
  }, [status])

  return (
    <Wrapper colors={STATUS_COLOR[lowercaseStatus]}>
      {withIcon && (statusIcon || icon)}
      {STATUS_LABEL[lowercaseStatus]}
    </Wrapper>
  )
}

export default Status

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 2px 8px;
  background-color: ${({ theme, colors }) => colors ? colors.background : theme.colors.lightTrout};
  color: ${({ theme, colors }) => colors ? colors.font : theme.colors.trout};
  font-size: 12px;
  border-radius: 16px;

  ${({ double }) =>
          double &&
          css`
            border: 2px solid #fff;
            margin-left: -50px;
          `}
`
const EditIconSmall = styled(EditIcon)`
  width: 10px;
  height: 10px;
`
