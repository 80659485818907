import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import styled from 'styled-components'
import { CalendarIcon } from 'assets/icons'

interface DatePickerComponentTypes {
  label?: string
  startValue?: any
  control: any
  name: string
  error?: string
  disabled?: boolean
  minDate?: any
}
const DatePickerComponent = (props: DatePickerComponentTypes) => {
  const { label, control, name, error, disabled, minDate } = props

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Wrapper>
            <LabelWrapper>{label}</LabelWrapper>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePickerWrapper
                {...field}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D0D5DD'
                        },
                        '&:hover fieldset': {
                          borderColor: '#33becc'
                        },
                        '&.Mui-error fieldset': {
                          borderColor: '#D0D5DD'
                        }
                      }
                    }}
                  />
                )}
                inputFormat='DD.MM.YYYY'
                showDaysOutsideCurrentMonth
                disabled={disabled}
                minDate={minDate}
                components={{
                  OpenPickerIcon: () => <CalendarIcon />
                }}
                inputProps={{
                  placeholder: 'dd.mm.yyyy',
                  sx: {
                    fontSize: '14px',
                    height: '1px',
                    width: '220px',
                  }
                }}
              />
            </LocalizationProvider>
            {error && <Error>{error}</Error>}
          </Wrapper>
        )
      }}
    />
  )
}

export default DatePickerComponent

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const DatePickerWrapper = styled(DatePicker)`
  border: 1px solid blue;
  background-color: ${({ disabled }) => (disabled ? 'transparent' : '#fff')};
  button {
    width: auto;
    svg {
      min-width: 15px;
    }
  }
`
const LabelWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`
const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
