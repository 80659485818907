import React, { useEffect, useState } from 'react'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, bool, string, number, lazy } from 'yup'
import styled from 'styled-components'
import { equals, find, propEq, propOr, pathOr } from 'ramda'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'

import { useAppSelector, useAppDispatch } from 'redux/hooks'

import { selectFaultsDictionary } from 'redux/selectors/dictionarySelectors'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'

import { ExclamationIconOutlined } from 'assets/icons'

import TrueFalseSelect from 'modules/forms/wallsForm/components/TrueFalseSelect'
import { getFaults } from 'redux/slices/faultsSlice'

import theme from 'utils/theme'

import { FormSeparator } from 'components/formComponents'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import DatePickerComponent from 'components/atoms/DatePickerComponent'
import Input from 'components/atoms/Input'
import moment from 'moment'
import qs from 'qs'

import { createOrUpdateRectification } from 'services/FaultsService'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { getBuildingDetails } from 'redux/slices/buildingSlice'

const FaultRectificationStep = ({ handleClose, fault, setFormValues }) => {
  const dispatch = useAppDispatch()
  const faultsDictionary = useAppSelector(selectFaultsDictionary)
  const [chosenFault, setChosenFault] = useState<any>({})
  const [lastValues, setLastValues] = useState({})

  const isChosenFault = isNotNilOrEmpty(chosenFault)

  const evacuationStrategyChangeValue = pathOr(
    '',
    ['evacuationStrategyChange', 'value'],
    chosenFault
  )

  const isEvacuationStrategyChange = equals(
    evacuationStrategyChangeValue,
    'yes'
  )

  const schema = object({
    rectifyFurtherDetails: string().nullable().notRequired(),
    faultReferenceNumber: number()
      .nullable()
      .required('You have to select a fault')
      .typeError('You have to select a fault'),

    isReinstated: lazy(() => {
      if (isEvacuationStrategyChange) {
        return bool().required('This field is required').nullable()
      }
      return bool().nullable().notRequired()
    }),
    reportDate: object().nullable().required('Report date is required'),
    rectificationDate: object()
      .nullable()
      .required('Rectification date is required')
  })

  useEffect(() => {
    isNotNilOrEmpty(fault) && setChosenFault(fault)
  }, [fault])

  const building = useAppSelector(selectCurrentBuilding)
  const buildingFaults: [] = propOr([], 'faultReports', building)

  const nameOfEquipmentValue = faultObject => {
    const equipmentType = find(
      propEq('id', propOr('', 'equipmentTypeId', faultObject)),
      faultsDictionary
    )
    return propOr('', 'name', equipmentType)
  }

  const dateOfFault = propOr('', 'createdAt', chosenFault)

  const faultsOptions = buildingFaults
    .filter(fault => {
      // @ts-ignore
      return fault?.rectification?.status?.value !== 'rectified' && fault?.status?.value !== 'draft'
    })
    .map(fault => {
      const faultSerialNumber = propOr('', 'serial', fault)
      return {
        label: `#${faultSerialNumber} ${nameOfEquipmentValue(fault)}`,
        value: faultSerialNumber
      }
    })

  const defaultValues = {
    reportDate: fault?.createdAt || '',
    rectificationDate: isNotNilOrEmpty(fault?.rectification?.rectificationDate)
      ? moment(fault.rectification.rectificationDate)
      : '',
    // @ts-ignore
    fireSafetyEquipment: fault ? nameOfEquipmentValue(fault) : '',
    rectifyFurtherDetails: fault?.rectification?.details || '',
    faultReferenceNumber: fault?.serial || '',
    isReinstated: isNotNil(fault?.rectification?.evacuationStrategyReinstated)
      ? fault.rectification.evacuationStrategyReinstated
      : null
  }

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { errors }
  } = useForm({
    // @ts-ignore
    defaultValues,
    resolver: yupResolver(schema)
  })

  const values = watch()

  useEffect(() => {
    if (!equals(lastValues, values)) {
      setFormValues({
        ...values,
        faultId: chosenFault?.id
      })
      setLastValues(values)
    }
  }, [values])

  useEffect(() => {
    // @ts-ignore
    setValue('reportDate', moment(dateOfFault))
  }, [dateOfFault])

  const faultReferenceIdFromValues = propOr('', 'faultReferenceNumber', values)

  useEffect(() => {
    const faultInfo = find(
      propEq('serial', faultReferenceIdFromValues),
      buildingFaults
    )

    if (isNotNilOrEmpty(faultReferenceIdFromValues)) {
      setChosenFault(faultInfo)
    }
  }, [faultReferenceIdFromValues])

  const submit = () => {
    const dateRectified =
      // @ts-ignore
      typeof values.rectificationDate &&
      moment(values.rectificationDate)?.toISOString(true)
    const faultId = propOr('', 'id', chosenFault)
    const payload = {
      rectificationDate: dateRectified,
      details: values.rectifyFurtherDetails,
      evacuationStrategyReinstated: values.isReinstated,
      buildingId: building.id,
      faultId,
      submit: true
    }
    createOrUpdateRectification(payload)
      .then(() => {
        toast.success('Fault rectification form has been submitted.')
        const query = {
          limit: 20,
          page: 1,
          filter: { is_rectified: true }
        }
        dispatch(getFaults({ id: building.id, query: qs.stringify(query) }))
        dispatch(getBuildingDetails({ id: building.id }))
        // onClose()
      })
      .catch(e => toast.error(getApiErrors(e)))
  }

  return (
    <div>
      <ExpandableInfo title='Reporting a fault'>
        <div>
          Regulation 7 of the Fire Safety (England) Regulations 2022 requires
          the Responsible Person of all high-rise residential buildings to
          inform their local Fire and Rescue Service where faults with key
          firefighting equipment are identified that cannot be rectified within
          24 hours. The local Fire and Rescue Service should be notified again
          once the fault has been rectified.
        </div>
      </ExpandableInfo>
      <Select
        label='Fault reference number'
        control={control}
        noMarginBottom
        name='faultReferenceNumber'
        // @ts-ignore
        options={faultsOptions}
        error={errors.faultReferenceNumber?.message}
      />
      <ToolTipForFaultRefNumber>
        Select one from already reported faults
      </ToolTipForFaultRefNumber>
      {isChosenFault ? (
        <>
          <ExpandableSection>
            <Input
              register={register}
              label='Fire Safety Equipment'
              placeholder='Fire Safety Equipment'
              name='fireSafetyEquipment'
              disabled
              // @ts-ignore
              value={nameOfEquipmentValue(chosenFault)}
            />
            <ToolTipForFaultRefNumber>
              Type of equipment this rectification report relates to
            </ToolTipForFaultRefNumber>
            <DateWrapper>
              <DatePickerComponent
                name='reportDate'
                control={control}
                startValue={dateOfFault}
                label='Report date'
                disabled
                // @ts-ignore
                error={errors.reportDate?.message}
              />
              <DatePickerComponent
                name='rectificationDate'
                control={control}
                label='Rectification date'
                // @ts-ignore
                error={errors.rectificationDate?.message}
              />
            </DateWrapper>
            <Input
              name='rectifyFurtherDetails'
              multiline
              register={register}
              label='Provide further details (optional)'
              placeholder='Enter a description...'
              error={errors.rectifyFurtherDetails?.message}
              hasCounter
              maxCharacters={512}
            />
            <ToolTipForFaultRefNumber>
              Any additional information in relation to the rectification of the
              fault
            </ToolTipForFaultRefNumber>
          </ExpandableSection>
          <FormSeparator />
          {isEvacuationStrategyChange ? (
            <>
              <SectionTitle>Fault mitigation</SectionTitle>
              <FaultMitigationInfo>
                <MitigationIconWrapper>
                  <ExclamationIconOutlined
                    height='16'
                    width='16'
                    stroke={theme.colors.copper}
                  />
                </MitigationIconWrapper>
                Fault necessitated a temporary change in evacuation strategy
                from stay put to simultaneous
              </FaultMitigationInfo>
              <TrueFalseSelect
                control={control}
                initialValue={defaultValues.isReinstated}
                name='isReinstated'
                // @ts-ignore
                onChange={value => setValue('isReinstated', value)}
                title='With the fault now rectified, has the stay put policy been reinstated?'
                // @ts-ignore
                error={errors.isReinstated?.message}
              />
              <FormSeparator />
            </>
          ) : null}
          <ButtonsWrapper>
            <Button onClick={handleSubmit(submit)}>Submit Rectification</Button>
          </ButtonsWrapper>
        </>
      ) : null}
    </div>
  )
}

export default FaultRectificationStep

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    width: 170px !important;
  }
`

const ToolTipForFaultRefNumber = styled.div`
  color: ${({ theme }) => theme.colors.lightTrout};
  font-size: 13px;
  margin-bottom: 16px;
`
const ExpandableSection = styled.div`
  color: ${({ theme }) => theme.colors.trout};
  background-color: #f7f8fd;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 20px;
`
const SectionTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`
const MitigationIconWrapper = styled.span`
  margin-right: 6px;
`
const FaultMitigationInfo = styled.div`
  display: flex;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.lightTrout};
  background-color: #faf2f0;
  padding: 6px;
  margin-bottom: 16px;
`
const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`
