import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'

type TrueFalseSelectTypes = {
  title: string
  description?: string
  onChange: any
  addNotSureOption?: boolean
  initialValue?: boolean | string | null
  isColumn?: boolean
  control: any
  name: string
  error?: string
  trigger?: any
}

const TrueFalseSelect = ({
  title,
  isColumn,
  description,
  onChange,
  addNotSureOption,
  initialValue,
  control,
  name,
  error
}: TrueFalseSelectTypes) => {
  const initial = initialValue === '0' ? false : initialValue === '1' ? true : initialValue
  const [value, setValue] = useState(initial)

  const handleSelect = value => () => {
    setValue(value)
  }

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={field => (
          <Wrapper
            {...field}
            isColumn={isColumn}
            hasError={isNotNilOrEmpty(error)}
          >
            <TextWrapper>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </TextWrapper>
            <Options isColumn={isColumn}>
              <Option
                active={addNotSureOption ? value === 'yes' : value === true}
                onClick={handleSelect(addNotSureOption ? 'yes' : true)}
              >
                <Radio active={addNotSureOption ? value === 'yes' : value === true}>
                  <span className='dot' />
                </Radio>
                Yes
              </Option>
              <Option
                active={addNotSureOption ? value === 'no' : value === false}
                onClick={handleSelect(addNotSureOption ? 'no' : false)}
              >
                <Radio active={addNotSureOption ? value === 'no' : value === false}>
                  <span className='dot' />
                </Radio>
                No
              </Option>
              {
                addNotSureOption && (
                  <Option
                    active={value === 'dont_know'}
                    onClick={handleSelect('dont_know')}
                  >
                    <Radio active={value === 'dont_know'}>
                      <span className='dot' />
                    </Radio>
                    Don't know
                  </Option>
                )
              }
            </Options>
          </Wrapper>
        )}
      />
      {
        error && (
          <Error isColumn={isColumn}>
            {error}
          </Error>
        )
      }
    </>
  )
}

export default TrueFalseSelect

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  ${({ isColumn }) => isColumn && css`
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 20px;
  `}

  ${({ hasError }) => hasError && css`
    margin-bottom: 10px;
  `}
`

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.trout};
  font-size: 13px;
`

const Options = styled.div`
  display: flex;
  gap: 6px;
  border-radius: 8px;
  flex-wrap: nowrap;

  ${({ isColumn }) => isColumn && css`
    & > div {
      flex: 1;
    }
  `}

`

const Option = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 42px;
  white-space: nowrap;
  cursor: pointer;

  ${({ active }) => active && css`
    box-shadow: 0 0 4px rgba(209, 73, 37, 0.08), 0 2px 4px rgba(209, 73, 37, 0.12), 0 8px 16px rgba(209, 73, 37, 0.06), 0 1px 2px rgba(209, 73, 37, 0.06);
    border-color: ${({ theme }) => theme.colors.primary};
  `}
`

const Radio = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.copper};;
    border-radius: 50%;
    opacity: 0;
  }

  ${({ active }) => active && css`
    border-color: ${({ theme }) => theme.colors.copper};

    .dot {
      opacity: 1;
    }
  `}
`

const TextWrapper = styled.div`
  flex: 1;
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};

  ${({ isColumn }) => isColumn && css`
    margin-bottom: 20px;
  `}
`
