import React from 'react'
import Icon from './Icon'

export const FaultsIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='25'
      height='24'
      viewBox='0 0 25 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M2.1665 21L12.6665 2.81299L23.1665 21H2.1665ZM21.4345 20L12.6665 4.81299L3.8985 20H21.4345ZM12.1665 14V9.99999H13.1665V14H12.1665ZM12.1665 16H13.1665V18H12.1665V16Z' />
    </Icon>
  )
}

export default FaultsIcon
