import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import CloseFormModal from 'components/atoms/CloseFormModal'
import BuildingDetailsInForm from 'components/atoms/BuildingDetailsInForm'
import TextButton from 'components/atoms/TextButton'
import FaultsFormStep from 'modules/forms/faultsForm/FaultsFormStep'
import FaultRectificationStep from 'modules/forms/rectificationForm/steps/FaultRectificationStep'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { pathOr, propOr } from 'ramda'
import { createOrUpdateRectification } from 'services/FaultsService'
import { toast } from 'react-hot-toast'
import { getFaults } from 'redux/slices/faultsSlice'
import { getApiErrors } from 'utils/errors'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import moment from 'moment'
import qs from 'qs'

type RectFormTypes = {
  fault?: any
  triggerLabel?: string
  hasFaultReport?: boolean
}

const RectForm = ({ fault, triggerLabel = 'Rectify', hasFaultReport }: RectFormTypes) => {
  const [open, setOpen] = useState(false)
  const isFaultList = isNotNilOrEmpty(fault)
  const [formValues, setFormValues] = useState({})
  const building = useAppSelector(selectCurrentBuilding)
  const faultReports = propOr([], 'faultReports', building)
  const isFaultReports = isNilOrEmpty(faultReports)
  const dispatch = useAppDispatch()

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveAsDraft = () => {
    const dateRectified =
      // @ts-ignore
      // prettier-ignore
      formValues.rectificationDate && moment(formValues.rectificationDate)?.toISOString(true)
    const faultId = propOr('', 'faultId', formValues)

    if (isNotNilOrEmpty(faultId)) {
      const payload = {
        rectificationDate: dateRectified,
        // @ts-ignore
        details: formValues?.rectifyFurtherDetails,
        // @ts-ignore
        evacuationStrategyReinstated: formValues?.isReinstated,
        // @ts-ignore
        buildingId: building?.id,
        faultId,
        submit: false
      }

      createOrUpdateRectification(payload)
        .then(() => {
          const query = {
            limit: 20,
            page: 1,
            filter: { is_rectified: false }
          }
          toast.success('Rectification form has been saved as draft')
          dispatch(getFaults({ id: building.id, query: qs.stringify(query) }))
          handleClose()
          // onClose()
        })
        .catch(e => toast.error(getApiErrors(e)))
    } else {
      toast.error('You have to select fault to rectify')
    }
  }

  return (
    <>
      {isFaultList ? (
        <Button
          size='medium'
          onClick={handleOpen}
          variant={
            pathOr(null, ['rectification', 'status', 'value'], fault) !==
            'draft'
              ? 'outlined'
              : undefined
          }
        >
          {triggerLabel}
        </Button>
      ) : (
        <TextButton
          label={triggerLabel}
          onClick={handleOpen}
          disabled={isFaultReports || !hasFaultReport}
        />
      )}
      {open && (
        <>
          <Backdrop onClick={handleClose}>
            <FakeModal onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <ModalTitle>
                  Report to FRS - Rectification of Fire Safety Equipment Fault
                </ModalTitle>
                <ModalActions>
                  <Divider />
                  <CloseFormModal
                    closeAction={handleClose}
                    onDraftSave={handleSaveAsDraft}
                  />
                </ModalActions>
              </ModalHeader>
              <ModalContent>
                <StepsPanel>
                  <div>
                    <BuildingDetailsInForm />
                  </div>
                </StepsPanel>
                <FormContentWrapper>
                  <FormContent>
                    <FaultsFormStep
                      title='Fault Rectification'
                      open
                      onOpen={() => {}}
                    >
                      <FaultRectificationStep
                        setFormValues={setFormValues}
                        handleClose={handleClose}
                        fault={fault}
                      />
                    </FaultsFormStep>
                  </FormContent>
                </FormContentWrapper>
              </ModalContent>
            </FakeModal>
          </Backdrop>
        </>
      )}
    </>
  )
}

export default RectForm

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
`

const FakeModal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
`

const ModalHeader = styled.div`
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafbfc;
  filter: drop-shadow(0px 0px 4px rgba(101, 118, 184, 0.08))
    drop-shadow(0px 2px 4px rgba(101, 118, 184, 0.12))
    drop-shadow(0px 8px 16px rgba(101, 118, 184, 0.06))
    drop-shadow(0px 1px 2px rgba(101, 118, 184, 0.06));
  border-bottom: 1px solid #dce0f2;
  border-radius: 8px 8px 0 0;
`

const ModalTitle = styled.div`
  font-size: 24px;
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`

const Divider = styled.div`
  height: 32px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.border};
`

const ModalContent = styled.div`
  display: flex;
  height: calc(100% - 80px);
`

const StepsPanel = styled.div`
  min-width: 280px;
  max-width: 280px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 16px;
  height: 100%;
  border-radius: 0 0 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FormContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
`

const FormContent = styled.div`
  width: 640px;
  margin: 0 auto;
`
