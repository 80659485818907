const theme = {
  colors: {
    white: '#ffffff',
    primary: '#D24724',
    secondary: '#33becc',
    border: '#D0D5DD',
    navigation: '#FAFBFC',
    grey: '#b2b2b2',
    darkGrey: '#84889C',
    lightGrey: '#f2f2f2',
    background: '#f7f8fd',
    error: '#CF1F3F',
    success: '#37AB93',
    haiti: '#0C1229',
    text: '#0C1229',
    disabled: '#BCC1D6',
    disabledBg: '#F7F8FD',
    copper: '#d14925',
    lightCopper: '#E59985',
    copperBg: '#FCF4F2',
    copperBorder: '#FAE4DE',
    teal: '#138194',
    darkTeal: '#0F6C7D',
    borderLight: '#E5E7EB',
    trout: '#4F5366',
    lightTrout: '#696D80',
    green: '#0C826A',
    lightGreen: '#E6F7F4',
    buttonSecondary: '#EDF1F7',
    buttonHover: '#363A4F',
    buttonSecondaryHover: '#DFE2F2'
  },
  dimensions: {
    topNavHeight: '72px'
  }
}

export default theme
