import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import {
  addNewBuilding,
  fetchBuildingDetails,
  fetchUserBps,
  fetchUserBuildings,
  updateBuilding
} from 'services/BuildingsService'
import { uploadFile } from 'services/FilesService'
import { pathOr, propOr } from 'ramda'
import { fileToUploadPath, thumbnailToUploadPath } from 'utils/files'
import { isNotNilOrEmpty } from 'utils/ramda'

export type BuildingStateTypes = {
  isFetchingBps: boolean
  isAddingBuilding: boolean
  userBps: any[]
  userBuildings: any[]
  currentBuilding: any | null
  isFetchingBuildings: boolean
  isFetchingBuildingDetails: boolean
  userBuildingsPagination: any
  isUploading: boolean
  isSavingBuilding?: boolean
}

const initialState: BuildingStateTypes = {
  isFetchingBps: false,
  isAddingBuilding: false,
  userBps: [],
  userBuildings: [],
  isFetchingBuildings: false,
  isFetchingBuildingDetails: false,
  currentBuilding: null,
  userBuildingsPagination: null,
  isUploading: false,
  isSavingBuilding: false
}

export const getUserBuildingPassports = createAsyncThunk('building/getUserBuildingPassports', async () => {
  return await fetchUserBps()
    .then(resp => resp.data.data)
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const addBuilding = createAsyncThunk('building/addBuilding', async (payload: any) => {
  return await addNewBuilding(payload)
    .then(resp => {
      toast.success('Building has been added successfully')
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const editBuilding = createAsyncThunk('building/editBuilding', async (payload: any) => {
  return await updateBuilding(payload)
    .then(resp => {
      toast.success('Building has been updated')
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const getUserBuildings = createAsyncThunk('building/getUserBuildings', async (payload: any) => {
  return await fetchUserBuildings(payload)
    .then(resp => {
      const buildings = resp.data.data
      const pagination = {
        itemsCount: resp.data.meta.total,
        totalPages: resp.data.meta.lastPage,
        perPage: resp.data.meta.perPage,
        currentPage: resp.data.meta.currentPage
      }
      return { buildings, pagination }
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

type UploadFilePayload = {
  file: any
  buildingId: string
  buildingName: string
  fileTypeId: string
}

export const uploadBuildingFile = createAsyncThunk('building/uploadBuildingFile', async (payload: UploadFilePayload) => {
  return await uploadFile(payload)
    .then(resp => {
      const path = propOr('', 'universalPath', resp)
      const fileName = pathOr('', ['original_file_name', 0], resp)

      const data = {
        fileTypeId: payload.fileTypeId,
        path: fileToUploadPath(path, true),
        thumbnailPath: thumbnailToUploadPath(path, true),
        displayName: fileName,
        originalFileName: fileName
      }

      return updateBuilding({
        id: payload.buildingId,
        name: payload.buildingName,
        files: [data]
      })
        .then(resp => {
          toast.success('File has been uploaded successfully')
          return resp.data.data
        })
        .catch(err => {
          toast.error(getApiErrors(err))
        })
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const justUploadBuildingFile = createAsyncThunk('building/justUploadBuildingFile', async (payload: any) => {
  return await uploadFile(payload)
    .then(resp => {
      const path = propOr('', 'universalPath', resp)
      const fileName = pathOr('', ['original_file_name', 0], resp)
      return {
        fileTypeId: payload.fileTypeId,
        path: fileToUploadPath(path, true),
        thumbnailPath: thumbnailToUploadPath(path, true),
        displayName: fileName,
        originalFileName: fileName
      }
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})


type getBuildingDetailsTypes = {
  id: string
}

export const getBuildingDetails = createAsyncThunk('building/getBuildingDetails', async (payload: getBuildingDetailsTypes) => {
  return await fetchBuildingDetails(payload)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    clearCurrentBuilding: (state) => {
      state.currentBuilding = initialState.currentBuilding
    }
  },
  extraReducers: builder => {
    // fetch current user bps
    builder.addCase(getUserBuildingPassports.pending, (state) => {
      state.isFetchingBps = true
    })
    builder.addCase(getUserBuildingPassports.fulfilled, (state, action) => {
      state.isFetchingBps = false
      state.userBps = action.payload
    })
    builder.addCase(getUserBuildingPassports.rejected, state => {
      state.isFetchingBps = false
    })
    // Add building
    builder.addCase(addBuilding.pending, (state) => {
      state.isAddingBuilding = true
    })
    builder.addCase(addBuilding.fulfilled, (state) => {
      state.isAddingBuilding = false
    })
    builder.addCase(addBuilding.rejected, state => {
      state.isAddingBuilding = false
    })

    // edit building
    builder.addCase(editBuilding.pending, (state) => {
      state.isSavingBuilding = true
    })
    builder.addCase(editBuilding.fulfilled, (state, action) => {
      if (isNotNilOrEmpty(action.payload)) {
        state.currentBuilding = action.payload
      }
      state.isSavingBuilding = false
    })
    builder.addCase(editBuilding.rejected, state => {
      state.isSavingBuilding = false
    })

    // Get user buildings
    builder.addCase(getUserBuildings.pending, (state) => {
      state.isFetchingBuildings = true
    })
    builder.addCase(getUserBuildings.fulfilled, (state, action) => {
      state.isFetchingBuildings = false
      state.userBuildings = action.payload?.buildings
      state.userBuildingsPagination = action.payload?.pagination
    })
    builder.addCase(getUserBuildings.rejected, state => {
      state.isFetchingBuildings = false
    })

    // Get building details
    builder.addCase(getBuildingDetails.pending, (state) => {
      state.isFetchingBuildingDetails = true
    })
    builder.addCase(getBuildingDetails.fulfilled, (state, action) => {
      state.isFetchingBuildingDetails = false
      state.currentBuilding = action.payload
    })
    builder.addCase(getBuildingDetails.rejected, state => {
      state.isFetchingBuildingDetails = false
    })

    // Upload file
    builder.addCase(uploadBuildingFile.pending, (state) => {
      state.isUploading = true
    })
    builder.addCase(uploadBuildingFile.fulfilled, (state, action) => {
      state.isUploading = false
      state.currentBuilding = action.payload
    })
    builder.addCase(uploadBuildingFile.rejected, state => {
      state.isUploading = false
    })

    builder.addCase(justUploadBuildingFile.pending, (state) => {
      state.isUploading = true
    })
    builder.addCase(justUploadBuildingFile.fulfilled, (state, action) => {
      state.isUploading = false
    })
    builder.addCase(justUploadBuildingFile.rejected, state => {
      state.isUploading = false
    })
  }
})

export const { clearCurrentBuilding } = buildingSlice.actions

export const buildingReducer = buildingSlice.reducer
