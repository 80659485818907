import React, { useMemo, useState, useEffect } from 'react'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, boolean, object, string } from 'yup'
import styled from 'styled-components'
import { setActiveStep, setFinishedStep, updateFormValues } from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import QuestionWithAnswers from 'modules/forms/wallsForm/components/QuestionWithAnswers'
import { FormSeparator } from 'components/formComponents'
import { selectFormsDictionary } from 'redux/selectors/dictionarySelectors'
import {equals, pathOr, propOr} from 'ramda'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { addFinishedFormStep } from 'utils/forms'
import { editBuilding } from 'redux/slices/buildingSlice'
import { isNotNilOrEmpty } from 'utils/ramda'
import ThanksMessageModal from 'modules/forms/components/ThanksMessageModal'
import Button from 'components/atoms/Button'

const schema = object({
  fireRiskAssessmentStatus: string()
    .required('This field is required')
    .nullable(),
  fireSpreadRiskLevel: string()
    .required('This field is required')
    .nullable(),
  areAdditionalMeasuresUnnecessary: boolean()
    .required('This field is required')
    .nullable(),
  fireRiskMitigationAdditionalMeasures: array()
    .when('areAdditionalMeasuresUnnecessary', {
      is: false,
      then: schema => schema.min(1, 'This field is required'),
      otherwise: schema => schema.notRequired().nullable()
    })
    .nullable(),
  otherAdditionalMeasuresTaken: string()
    .when('fireRiskMitigationAdditionalMeasures', {
      is: value => value.some(v => v.includes('other:')),
      then: schema => schema.required('This field is required'),
      otherwise: schema => schema.notRequired().nullable()
    })
    .nullable()
})

const RiskAndMitigationStep = () => {
  const dictionary = useAppSelector(selectFormsDictionary)
  const dispatch = useAppDispatch()
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const formValues = useAppSelector(selectActiveFormValues)
  const [thanksModalOpen, setThanksModalOpen] = useState(false)
  const [lastValues, setLastValues] = useState({})

  const defaultValues = {
    fireRiskAssessmentStatus: currentBuilding.fireRiskAssessmentStatus?.value || null,
    fireSpreadRiskLevel: currentBuilding.fireSpreadRiskLevel?.value || null,
    areAdditionalMeasuresUnnecessary: currentBuilding.areAdditionalMeasuresUnnecessary || false,
    fireRiskMitigationAdditionalMeasures: (currentBuilding.fireRiskMitigationAdditionalMeasures || []).map(v => v.id),
    otherAdditionalMeasuresTaken: currentBuilding.otherAdditionalMeasuresTaken || ''
  }

  useEffect(() => {
    dispatch(updateFormValues(defaultValues))
  }, [])

  const { handleSubmit, setValue, formState: { errors }, watch } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const submit = () => {
    handleSubmit(async data => {
      const result = await dispatch(editBuilding({
        id: currentBuilding.id,
        name: currentBuilding.name,
        submitWallDesign: true,
        ...formValues,
        ...data,
        externalWallSystems: (formValues?.externalWallSystems || []).filter(system => {
          const hasMaterials = isNotNilOrEmpty(system.externalFacingMaterials)
          const hasIsCombustible = isNotNilOrEmpty(system.isCombustible)
          return hasMaterials && hasIsCombustible
        }),
        fireRiskMitigationAdditionalMeasures: formValues
          .fireRiskMitigationAdditionalMeasures?.filter(v => !v.includes('other:')),
        buildingFormsState: addFinishedFormStep(
          'wall_design',
          'risk_and_mitigation',
          formValues?.buildingFormsState || []
        )
      }))
      if (isNotNilOrEmpty(result.payload)) {
        dispatch(setFinishedStep('risk_and_mitigation'))
        setThanksModalOpen(true)
      }
    })()
  }

  const handleGoBack = () => {
    dispatch(setActiveStep('wall_features_and_attachments'))
  }

  const fireRiskMitigationAdditionalMeasuresOptions = useMemo(() => {
    // @ts-ignore
    return propOr([], 'fireRiskMitigationAdditionalMeasures', dictionary).map(item => ({
      label: item.name,
      value: item.id
    }))
  }, [dictionary])

  const values = watch()

  useEffect(() => {
    const val = {
      ...values,
      fireRiskMitigationAdditionalMeasures: values
        .fireRiskMitigationAdditionalMeasures?.filter(v => !v.includes('other:')),
    }
    if (!equals(val, lastValues)) {
      dispatch(updateFormValues(val))
      setLastValues(val)
    }
  }, [values])

  const handleAnswerChange = val => {
    if (!equals(values.fireRiskMitigationAdditionalMeasures, val)) {
      const otherOption = val.find(v => v.includes('other:'))
      const isNotNeeded = val.find(v => v.includes('no_needed'))
      const withoutOther = val.filter(v => !v.includes('other:'))

      if (isNotNeeded) {
        setValue('areAdditionalMeasuresUnnecessary', true)
        setValue('otherAdditionalMeasuresTaken', '')
        setValue('fireRiskMitigationAdditionalMeasures', [])
      } else {
        setValue('areAdditionalMeasuresUnnecessary', false)
        if (otherOption) {
          setValue('otherAdditionalMeasuresTaken', otherOption.replace('other:', ''))
          setValue('fireRiskMitigationAdditionalMeasures', val)
        } else {
          setValue('otherAdditionalMeasuresTaken', '')
          setValue('fireRiskMitigationAdditionalMeasures', withoutOther)
        }
      }
    }
  }

  // @ts-ignore
  const isFormSubmitted = pathOr('', ['wallDesignStatus', 'value'], currentBuilding) === 'submitted'

  return (
    <div>
      <ThanksMessageModal
        open={thanksModalOpen}
        onClose={() => setThanksModalOpen(false)}
        mode='wallDesign'
      />
      <ExpandableInfo
        title='What information should be provided?'
      >
        <div>
          It is a requirement under the Fire Safety Order to consider the risk of external fire spread as part of the
          buildings fire risk assessment. Where the fire risk assessor cannot be assured that the risk of external fire
          spread to be low, then a more detailed fire risk appraisal of the external wall (FRAEW) will be required. The
          outcome of any FRAEW should be recorded within the fire risk assessment.
        </div>
      </ExpandableInfo>
      <QuestionWithAnswers
        onChange={value => setValue('fireRiskAssessmentStatus', value)}
        // @ts-ignore
        error={errors.fireRiskAssessmentStatus?.message}
        initialRadioValue={defaultValues.fireRiskAssessmentStatus}
        question='Following the buildings fire risk assessment, was a further fire risk appraisal of the external walls required?'
        answers={[
          { label: 'Yes, it has been completed', value: 'completed' },
          { label: 'Yes, it is required but not yet completed', value: 'required' },
          { label: 'No, it was not required', value: 'not_required' }
        ]}
      />
      <QuestionWithAnswers
        onChange={value => setValue('fireSpreadRiskLevel', value)}
        // @ts-ignore
        error={errors.fireSpreadRiskLevel?.message}
        initialRadioValue={defaultValues.fireSpreadRiskLevel}
        question='What is the overall level of risk of fire spread due to the design and construction of the external walls?'
        answers={[
          {
            label: 'Low Risk',
            value: 'low',
            description: 'No increased risk of external fire spread, no remediation necessary'
          },
          {
            label: 'Medium Risk',
            value: 'medium',
            description: 'Increased risk of external fire spread, remediation may not be necessary'
          },
          {
            label: 'High Risk',
            value: 'high',
            description: 'Significantly increased risk of external fire spread and remediation works are necessary'
          },
          {
            label: 'Not Determined',
            value: 'not_determined',
            description: 'The overall level of risk of the external walls has not been determined'
          }
        ]}
      />
      <QuestionWithAnswers
        multi
        withOther
        onChange={handleAnswerChange}
        question='What actions have been taken to mitigate the risk of fire spread relating to the external wall?'
        // @ts-ignore
        error={errors.fireRiskMitigationAdditionalMeasures?.message || errors.otherAdditionalMeasuresTaken?.message}
        initialValues={defaultValues.fireRiskMitigationAdditionalMeasures}
        initialOtherValue={defaultValues.otherAdditionalMeasuresTaken}
        primaryOptionSelected={defaultValues.areAdditionalMeasuresUnnecessary}
        answers={[
          { label: 'No additional measures are needed', value: 'no_needed', primary: true },
          ...fireRiskMitigationAdditionalMeasuresOptions
        ]}
      />
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={handleGoBack}>Back</Button>
        <Button onClick={submit}>
          {isFormSubmitted ? 'Save Changes' : 'Submit Wall Design'}</Button>
      </ButtonsWrapper>
    </div>
  )
}

export default RiskAndMitigationStep

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 170px !important;
  }
`
