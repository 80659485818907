import React, { useEffect } from 'react'
import styled from 'styled-components'
import FormModal from 'components/atoms/FormModal'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setActiveForm } from 'redux/slices/formSlice'
import Tabs from 'components/atoms/Tabs'
import MyBuildingsList from 'modules/myBuildings/components/MyBuildingsList'
import { getFrsStations } from 'redux/slices/dictionarySlice'
import { selectUserBuildings } from 'redux/selectors/buildingSelectors'
import { isEmpty } from 'ramda'
import {
  clearCurrentBuilding,
  getUserBuildings
} from 'redux/slices/buildingSlice'
import MyBuildingsEmptyState from 'modules/myBuildings/components/MyBuildingsEmptyState'
import Button from 'components/atoms/Button'
import { PlusIcon } from 'assets/icons'
import { isNotNilOrEmpty } from 'utils/ramda'

const MyBuildings = () => {
  const dispatch = useAppDispatch()
  const buildings = useAppSelector(selectUserBuildings)
  const hasNoBuildings = isEmpty(buildings)

  useEffect(() => {
    const query = '?limit=100&page=1&sort=-updated_at'
    dispatch(getUserBuildings(query))
  }, [])

  const handleFormModalOpen = () => {
    dispatch(setActiveForm('add_building'))
  }

  useEffect(() => {
    dispatch(getFrsStations())
    dispatch(clearCurrentBuilding())
  }, [])

  const completedBuildings = buildings.filter(building => {
    return building.floorsCompletionPercentage === 100 && building.wallDesignCompletionPercentage === 100  && isNotNilOrEmpty(building.responsiblePersons)
  })

  const inProgressBuildings = buildings.filter(building => {
    return building.floorsCompletionPercentage < 100 || building.wallDesignCompletionPercentage < 100 || !isNotNilOrEmpty(building.responsiblePersons)
  })

  const faultyBuildings = buildings.filter(building => {
    return building.activeFaultReportsCount > 0
  })

  const tabs = [
    {
      key: 'all',
      title: 'All',
      children: hasNoBuildings ? <div /> : <MyBuildingsList buildings={buildings} />,
      counter: buildings?.length || 0,
      disabled: hasNoBuildings
    },
    {
      key: 'in_progress',
      title: 'In progress',
      children: isEmpty(inProgressBuildings) ? <div /> : <MyBuildingsList buildings={inProgressBuildings} />,
      counter: inProgressBuildings?.length || 0,
      disabled: isEmpty(inProgressBuildings)
    },
    {
      key: 'complete',
      title: 'Complete',
      children: isEmpty(completedBuildings) ? <div /> : <MyBuildingsList buildings={completedBuildings} />,
      counter: completedBuildings?.length || 0,
      disabled: isEmpty(completedBuildings)
    },
    {
      key: 'with_faults',
      title: 'With faults',
      children: isEmpty(faultyBuildings) ? <div /> : <MyBuildingsList buildings={faultyBuildings} />,
      counter: faultyBuildings?.length || 0,
      disabled: isEmpty(faultyBuildings)
    }
  ]

  return (
    <PageWrapper>
      <ContentWrapper>
        <Header>
          <PageTitle>My buildings</PageTitle>
          {!hasNoBuildings && (
            <Button
              onClick={handleFormModalOpen}
              icon={<PlusIcon />}
              size='medium'
            >
              Add new
            </Button>
          )}
        </Header>
        <Tabs tabs={tabs} />
        {hasNoBuildings && (
          <MyBuildingsEmptyState openAddModal={handleFormModalOpen} />
        )}
        <FormModal />
      </ContentWrapper>
    </PageWrapper>
  )
}

export default MyBuildings

const PageWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContentWrapper = styled.div`
  width: 900px;
`

const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PageTitle = styled.div`
  font-size: 36px;
  font-weight: lighter;
`
