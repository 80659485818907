import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import theme from 'utils/theme'
import PasswordRequirement from 'components/atoms/password/PasswordRequirement'
import { Collapse } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { debounce } from 'lodash'

type propsType = {
  error?: string
  label?: string
  placeholder?: string
  register: any
  name: string
  inputValue?: any
  showRequirements?: boolean
  passwordRecover?: boolean
}

// eslint-disable-next-line
export const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})'
)

const PasswordInput = (props: propsType) => {
  const { label, register, name, error, inputValue } = props
  const [visible, setVisible] = useState(false)
  const [requirements, setRequirements] = useState(false)
  const navigate = useNavigate()

  const passwordRequirements = [
    {
      id: 'min-length',
      value: 'Min 12 characters',
      regex: /(?=.{12,})/
    },
    {
      id: 'lowercase',
      value: '1 lowercase',
      regex: /(?=.*[a-z])/
    },
    {
      id: 'uppercase',
      value: '1 uppercase',
      regex: /(?=.*[A-Z])/
    },
    {
      id: 'digit',
      value: '1 digit',
      regex: /(?=.*[0-9])/
    },
    {
      id: 'special-character',
      value: '1 special character',
      regex: /(?=.*[!@#$%^&*])/
    }
  ]

  const debouncedHideRequirements = debounce(() => setRequirements(false), 200)

  const toggleVisibility = () => {
    setVisible(prev => !prev)
  }

  const showRequirements = () => {
    setRequirements(true)
  }

  const hideRequirements = () => {
    debouncedHideRequirements()
  }

  return (
    <>
      <Wrapper>
        <LabelWrapper>
          {label && <Label>{label}</Label>}
          {props.passwordRecover && (
            <PasswordRecover onClick={() => navigate(PATHS.forgottenPassword)}>
              I forgot my password
            </PasswordRecover>
          )}
        </LabelWrapper>

        <InputWrapper>
          <StyledInput
            {...register(name)}
            {...props}
            type={visible ? 'text' : 'password'}
            onFocus={showRequirements}
            onBlur={hideRequirements}
          />
          <Icon onClick={toggleVisibility}>
            {visible ? (
              <VisibilityIcon
                style={{
                  color: theme.colors.grey,
                  height: '22px',
                  width: '22px'
                }}
              />
            ) : (
              <VisibilityOffIcon
                style={{
                  color: theme.colors.grey,
                  height: '22px',
                  width: '22px'
                }}
              />
            )}
          </Icon>
        </InputWrapper>
        {error && <Error>{error}</Error>}
        {props.showRequirements && (
          <Collapse in={requirements}>
            <Requirements>
              {passwordRequirements.map(item => (
                <PasswordRequirement
                  key={item.id}
                  item={item}
                  valid={item.regex.test(inputValue)}
                />
              ))}
            </Requirements>
          </Collapse>
        )}
      </Wrapper>
    </>
  )
}

export default PasswordInput

const Wrapper = styled.div`
  margin-bottom: 15px;
`

const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  outline: none;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 48px 10px 14px;

  &[type='password'] {
    font-family: caption;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`
const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`
const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
`
const PasswordRecover = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.copper};
  font-weight: bolder;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.lightCopper};
  }
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`
const bumpAnimation = keyframes`
  0% {
    transform: scale(1.1, 1.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.9, 0.9);
    opacity: 0.9;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
`
const Icon = styled.div`
  height: 36px;
  width: 36px;
  margin-left: -44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    animation: ${bumpAnimation} 0.35s linear;
    cursor: pointer;
  }
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
const Requirements = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`
