import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import noFiles from 'assets/noFiles.svg'

const ReportedNoFaults = () => {
  return (
    <Wrapper>
      <FileImgWrapper>
        <FileBgImg src={noFiles} />
      </FileImgWrapper>
      <Content>
        <ContentHeader>
          No fire safety equipment faults have been reported yet
        </ContentHeader>
        <ContentText>
          Remember: if the fault cannot be rectified within 24 hours, then it
          must be reported to the fire and rescue service.
          <br />
          Additionally, the fire and rescue service must be informed once the
          fault has been rectified.
        </ContentText>
      </Content>
    </Wrapper>
  )
}

export default ReportedNoFaults

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 0 auto;
`
const FileImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const FileBgImg = styled.img`
  height: 65px;
  z-index: 10;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentHeader = styled.div`
  font-size: 14px;
  font-weight: bolder;
  color: ${({ theme }) => theme.colors.lightTrout};
  padding: 8px 0;
`
const ContentText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightTrout};
`
