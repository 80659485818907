import React from 'react'
import styled from 'styled-components'

type propsType = {
  item: { id: string; value: string }
  valid: boolean
}

const PasswordRequirement = ({ item, valid }: propsType) => {
  return (
    <RequirementsItem key={item.id}>
      <RadioInput type='radio' valid={valid} />
      <label htmlFor={item.id}>{item.value}</label>
    </RequirementsItem>
  )
}

export default PasswordRequirement

const RequirementsItem = styled.div`
  font-size: 14px;
  margin-top: 7px;
  width: 50%;
`

const RadioInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  background: green;
  border: 5px solid ${({ theme, valid }) =>
          valid ? theme.colors.secondary : theme.colors.borderLight};
  margin-right: 10px;
`
