import React, { ReactNode } from 'react'
import styled from 'styled-components'
import TextButton from 'components/atoms/TextButton'
import { useAppDispatch } from 'redux/hooks'
import { setActiveForm } from 'redux/slices/formSlice'

type propsType = {
  title: string
  content?: string
  buttonLabel: string
  icon?: ReactNode
  color?: string
  activeForm?: string
  disabled?: boolean
}

const SectionNotCompletedInfo = ({
  title,
  content,
  buttonLabel,
  icon,
  color,
  activeForm,
  disabled
}: propsType) => {
  const dispatch = useAppDispatch()

  const handleOpenEdit = () => {
    dispatch(setActiveForm(activeForm))
  }

  return (
    <>
      <Item>
        {title}
        <span>{content}</span>
      </Item>
      <TextButton
        disabled={disabled}
        label={buttonLabel}
        onClick={handleOpenEdit}
        icon={icon}
        color={color}
      />
    </>
  )
}

export default SectionNotCompletedInfo

const Item = styled.div`
  color: ${({ theme }) => theme.colors.trout};
  display flex;
  flex-direction: column;
  font-weight: bolder;
  margin-bottom: 20px;

  span {
    font-weight: initial;
  }
`
