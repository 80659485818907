import { createSelector } from 'reselect'
import { RootState } from 'redux/store'

const formState = (state: RootState) => state.form

export const selectActiveForm = createSelector(
  formState,
  state => state.activeForm
)

export const selectActiveStep = createSelector(
  formState,
  state => state.activeStep
)

export const selectFormSteps = createSelector(
  formState,
  state => state.formSteps
)

export const selectFormTitle = createSelector(
  formState,
  state => state.formTitle
)

export const selectFormVisibleSteps = createSelector(
  formState,
  state => state.visibleSteps
)

export const selectFinishedSteps = createSelector(
  formState,
  state => state.finishedSteps
)

export const selectActiveFormValues = createSelector(
  formState,
  state => state.values
)
