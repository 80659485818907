import React from 'react'
import Icon from './Icon'

export const EditIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='12'
      height='12'
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M11.1375 3.36136L9.58284 4.91603L7.08284 2.41603L8.63751 0.861361C8.69937 0.799397 8.77284 0.750239 8.85372 0.716698C8.93459 0.683158 9.02129 0.665894 9.10884 0.665894C9.1964 0.665894 9.2831 0.683158 9.36397 0.716698C9.44485 0.750239 9.51832 0.799397 9.58018 0.861361L11.1375 2.41869C11.1995 2.48055 11.2486 2.55402 11.2822 2.6349C11.3157 2.71578 11.333 2.80247 11.333 2.89003C11.333 2.97758 11.3157 3.06428 11.2822 3.14516C11.2486 3.22603 11.1995 3.2995 11.1375 3.36136ZM-0.000488281 9.49869L6.70951 2.78936L9.20951 5.28936L2.49951 11.9987H0.000178337V9.49869H-0.000488281ZM9.08084 1.36269L8.05418 2.38869L9.61218 3.94536L10.6375 2.91936L9.08084 1.36269ZM8.23818 5.31936L6.68151 3.76203L0.666845 9.77603V11.3334H2.22418L8.23818 5.31936Z' />
    </Icon>
  )
}

export default EditIcon
