import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useAppDispatch } from 'redux/hooks'
import { getUserBuildingPassports } from 'redux/slices/buildingSlice'
import { isNilOrEmpty, isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { formatDate } from 'utils/date'
import styled, { css } from 'styled-components'

const FetchFromBuildingPassportModal = ({ open, onClose, onFetch }) => {
  const [activeBp, setActiveBp] = useState(null)
  const [activeBpIndex, setActiveBpIndex] = useState(null)
  const [userBuildings, setUserBuildings] = useState<any[]>([])
  const dispatch = useAppDispatch()

  const fetchBps = async () => {
    if (open) {
      const result = await dispatch(getUserBuildingPassports())
      if (isNotNil(result.payload)) {
        setUserBuildings(result.payload)
      }
    } else {
      setUserBuildings([])
    }
  }

  const handleSelect = (bp, index) => () => {
    setActiveBp(bp)
    setActiveBpIndex(index)
  }

  useEffect(() => {
    fetchBps()
  }, [open])

  const headers = [
    '',
    'Building',
    'Address',
    'Postcode',
    'Updated'
  ]

  const handleClose = () => {
    onClose()
    setActiveBpIndex(null)
    setActiveBp(null)
    onFetch(activeBp)
  }

  const rows = userBuildings.map((row, index) => {
    return (
      <TableRow
        active={activeBpIndex === index}
        key={row.id}
        onClick={handleSelect(row, index)}
      >
        <RadioCell>
          <Radio active={activeBpIndex === index}>
            <div className='dot' />
          </Radio>
        </RadioCell>
        <TableCell>
          <BuildingName>{row.name}</BuildingName>
          {row.uprn && <Uprn>UPRN: {row.uprn}</Uprn>}
        </TableCell>
        <TableCell>
          <CellText>
            {row.street}, {row.city}
          </CellText>
        </TableCell>
        <TableCell>
          <CellText>
            {row.postalCode}
          </CellText>
        </TableCell>
        <TableCell>
          {formatDate(row.updatedAt)}
        </TableCell>
      </TableRow>
    )
  })

  return (
    <>
      <Modal
        disabled={isNilOrEmpty(activeBp)}
        open={open}
        title='Fetch from Building Passport'
        onClose={handleClose}
        withCancel
        onCancel={handleClose}
        withSubmit
        submitButtonText='Fetch information'
        onSubmit={() => {
          onFetch(activeBp)
          handleClose()
        }}
      >
        <Title>Fetch building information from your Building Passport</Title>
        <Description>
          Choose one of your Building Passports to automatically fill in some building information in Information Exchange.
          <br /><br />
          Please note that only empty fields are going to be filled with this data. You can check and edit them at any time.
        </Description>
        <TableContent>
          <TableHeaders>
            {headers.map(header => <TableHeader key={header}>{header}</TableHeader>)}
          </TableHeaders>
          {
            isNotNilOrEmpty(userBuildings)
            ? rows
            : (
                <EmptyState>
                  No Building Passports found
                </EmptyState>
              )
          }
        </TableContent>
      </Modal>
    </>
  )
}

export default FetchFromBuildingPassportModal

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
`

const Description = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`

const TableContent = styled.div`
  width: 670px;
  border: 1px solid #EAECF0;
  border-radius: 8px;
`

const TableHeaders = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 220px 220px 90px 100px;
  height: 44px;
  background-color: #F7F8FD;
  color: ${({ theme }) => theme.colors.trout};
  border-bottom: 1px solid #EAECF0;
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 40px 220px 220px 90px 100px;
  height: 72px;
  margin-top: -1px;
  position: relative;
  border: 1px solid #EAECF0;
  cursor: pointer;
  
  ${({ active }) => active && css`
    z-index: 1;
    border: 1px solid ${({ theme }) => theme.colors.copper} !important;
    background-color: ${({ theme }) => theme.colors.copperBg};
  `}
  
  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }
`

const TableCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.trout};
  padding: 0 5px;
`

const CellText = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.trout};
`

const BuildingName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Uprn = styled.div`
  font-size: 12px;
`

const RadioCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Radio = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  
  .dot {
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.copper};;
    border-radius: 50%;
    opacity: 0;
  }
  
  ${({ active }) => active && css`
    border-color: ${({ theme }) => theme.colors.copper};
    .dot {
      opacity: 1;
    }
  `}
`

const EmptyState = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
