import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import {
  CheckUserPayloadTypes,
  fetchExternalFacingMaterials,
  fetchFileTypes,
  fetchFrsStations,
  fetchUsersByEmail,
  fetchFormsDictionary, fetchBuildingFilters, fetchFaultsDictionary
} from 'services/DictionaryService'

type FRS = {
  name: string
  domain: string
  id: string
}

export type DictionaryStateTypes = {
  frs: FRS[]
  isCheckingUser: boolean
  foundUsers: any[]
  fileTypes: any[]
  buildingFilters: any[]
  externalFacingMaterials: any[]
  formsDictionary: any
  faultsDictionary: any
}

const initialState: DictionaryStateTypes = {
  frs: [],
  isCheckingUser: false,
  foundUsers: [],
  buildingFilters: [],
  fileTypes: [],
  externalFacingMaterials: [],
  formsDictionary: {},
  faultsDictionary: []
}

export const getFrsStations = createAsyncThunk('dictionary/getFrsStations', async () => {
  return await fetchFrsStations()
    .then(resp => resp.data.data)
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const getFileTypes = createAsyncThunk('dictionary/getFileTypes', async () => {
  return await fetchFileTypes()
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const getBuildingFilters = createAsyncThunk('dictionary/getBuildingFilters', async () => {
  return await fetchBuildingFilters()
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const checkUserEmail = createAsyncThunk('dictionary/checkUserEmail', async (payload: CheckUserPayloadTypes) => {
  return await fetchUsersByEmail(payload)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const getExternalFacingMaterials = createAsyncThunk('dictionary/getExternalFacingMaterials', async () => {
  return await fetchExternalFacingMaterials()
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const getFormsDictionary = createAsyncThunk('dictionary/getFormsDictionary', async () => {
  return await fetchFormsDictionary()
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})

export const getFaultsDictionary = createAsyncThunk('dictionary/getFaultsDictionary', async () => {
  return await fetchFaultsDictionary()
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      toast.error(getApiErrors(err))
    })
})


export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // fetch frs stations
    builder.addCase(getFrsStations.fulfilled, (state, action) => {
      state.frs = action.payload
    })
    builder.addCase(getFrsStations.rejected, state => {
      state.frs = initialState.frs
    })
    // fetch file types
    builder.addCase(getFileTypes.fulfilled, (state, action) => {
      state.fileTypes = action.payload
    })
    builder.addCase(getFileTypes.rejected, state => {
      state.fileTypes = initialState.fileTypes
    })

    // fetch file types
    builder.addCase(getBuildingFilters.fulfilled, (state, action) => {
      state.buildingFilters = action.payload
    })
    builder.addCase(getBuildingFilters.rejected, state => {
      state.buildingFilters = initialState.buildingFilters
    })

    // check user email
    builder.addCase(checkUserEmail.pending,state => {
      state.isCheckingUser = true
    })
    builder.addCase(checkUserEmail.rejected,state => {
      state.isCheckingUser = false
      state.foundUsers = initialState.foundUsers
    })
    builder.addCase(checkUserEmail.fulfilled,(state, action) => {
      state.isCheckingUser = false
      state.foundUsers = action.payload
    })

    // get external facing materials
    builder.addCase(getExternalFacingMaterials.fulfilled,(state, action) => {
      state.externalFacingMaterials = action.payload
    })

    // get forms dictionary
    builder.addCase(getFormsDictionary.fulfilled,(state, action) => {
      state.formsDictionary = action.payload
    })

    // get faults dictionary
    builder.addCase(getFaultsDictionary.fulfilled,(state, action) => {
      state.faultsDictionary = action.payload
    })
  }
})

export const dictionaryReducer = dictionarySlice.reducer
