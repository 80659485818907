import React, { useState } from 'react'
import { CloseIcon } from 'assets/icons'
import styled from 'styled-components'
import Modal from 'components/atoms/Modal'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { clearActiveForm } from 'redux/slices/formSlice'
import Button from 'components/atoms/Button'
import { getBuildingDetails } from 'redux/slices/buildingSlice'
import { isNotNilOrEmpty } from 'utils/ramda'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'

type CloseFormModalTypes = {
  onDraftSave: any
  closeAction?: any
}

const CloseFormModal = ({ onDraftSave, closeAction }: CloseFormModalTypes) => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const building = useAppSelector(selectCurrentBuilding)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleCloseWithoutSaving = () => {
    if (closeAction) {
      closeAction()
    } else {
      dispatch(clearActiveForm())
      isNotNilOrEmpty(building) && dispatch(getBuildingDetails({ id: building.id }))
    }
  }

  return (
    <>
      <IconWrapper onClick={handleOpen}>
        <CloseIcon />
      </IconWrapper>
      <Modal title='Close without saving' open={open} onClose={handleClose}>
        <ModalContent>
          Are you sure you want to close the form without
          saving any changes? All provided information will be lost and this
          action cannot be undone.
          <ButtonsWrapper>
            <Button variant='outlined' onClick={onDraftSave}>
              Save & Close
            </Button>
            <Button onClick={handleCloseWithoutSaving}>
              Close without saving
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CloseFormModal

const IconWrapper = styled.div`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.copper};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  gap: 10px;

  button {
    width: fit-content;
  }
`

const ModalContent = styled.div`
  width: 500px;
`
