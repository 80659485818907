import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { login } from 'redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { useForm } from 'react-hook-form'
import Input from 'components/atoms/Input'
import { string, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import { selectAuthLoading } from 'redux/selectors/authSelectors'
import PasswordInput from 'components/atoms/password/PasswordInput'
import AuthFormWrapper from 'modules/auth/AuthFormWrapper'
import Button from 'components/atoms/Button'

const schema = object({
  email: string()
    .required('Email is required')
    .email('Incorrect email address'),
  password: string().required('Password is required')
})

const Login = () => {
  const isLoading = useAppSelector(selectAuthLoading)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLoginUser = async data => {
    const result = await dispatch(login(data))
    if (result.payload) {
      navigate(PATHS.buildingsList)
    }
  }

  return (
    <AuthFormWrapper>
      <FormWrapper>
        <HeaderText>
          Welcome to&nbsp;
          <span>Building Passport Information Exchange</span>
        </HeaderText>
        <form onSubmit={handleSubmit(handleLoginUser)}>
          <Input
            register={register}
            name='email'
            error={errors.email?.message}
            label='Email'
          />
          <PasswordInput
            register={register}
            error={errors.password?.message}
            name='password'
            label='Password'
            inputValue={watch('password')}
            passwordRecover
          />
          <ButtonWrapper>
            <Button isLoading={isLoading} disabled={isLoading} type='submit'>
              Log in
            </Button>
            <ButtonSeparator>
              <span>Don't have Building Passport account?</span>
            </ButtonSeparator>
            <Button variant='outlined' onClick={() => navigate('/register')}>
              Sign up
            </Button>
          </ButtonWrapper>
        </form>
      </FormWrapper>
    </AuthFormWrapper>
  )
}

export default Login

const FormWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  padding: 35px 70px;
  background-color: #fff;
  margin-bottom: ${({ theme }) => theme.dimensions.topNavHeight};
`

const ButtonWrapper = styled.div`
  margin-top: 30px;

  button {
    width: 100%;
  }

`

const HeaderText = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;

  span {
    font-weight: bold;
  }
`
const ButtonSeparator = styled.div`
  display: flex;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.trout};
  padding: 20px 0;

  span {
    padding: 0 6px;
  }

  :before,
  :after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    margin: auto;
  }
`
