import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type StandaloneCheckboxTypes = {
  name: string
  label: ReactNode | string
  error?: string | null
  disabled?: boolean
  color?: string
  colorBg?: string
  onChange: any
  initialValue?: boolean
  noMargin?: boolean 
}

const StandaloneCheckbox = ({
  disabled,
  name,
  label,
  error,
  color,
  colorBg,
  onChange,
  initialValue = false,
  noMargin
}: StandaloneCheckboxTypes) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = e => {
    setValue(e.target.checked)
  }

  useEffect(() => {
    onChange(name, value)
  }, [value])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <>
      <Wrapper noMargin={noMargin}>
        <StandaloneCheckboxLabel
          error={error}
          disabled={disabled}
          color={color}
          colorBg={colorBg}
        >
          <StandaloneCheckboxInput
            type='checkbox'
            disabled={disabled}
            color={color}
            colorBg={colorBg}
            onChange={handleChange}
            checked={value}
          />
          {label}
          <Checkmark color={color} disabled={disabled} />
        </StandaloneCheckboxLabel>
        {error && <Error>{error}</Error>}
      </Wrapper>
    </>
  )
}

export default StandaloneCheckbox

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ noMargin }) => noMargin ? '0' : '15px'};
  cursor: pointer;
`
const StandaloneCheckboxInput = styled.input`
  margin-top: 1px;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme, colorBg }) =>
      colorBg || theme.colors.copperBg};
    border-color: ${({ theme, color }) => color || theme.colors.copper};
  }

  &:checked ~ span::after {
    display: block;
  }
`
const StandaloneCheckboxLabel = styled.label`
  display: flex;
  position: relative;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  padding-left: 30px;
  color: ${({ theme, error, disabled }) =>
    disabled ? theme.colors.trout : error ? theme.colors.error : 'inherit'};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};

  a {
    color: ${({ theme, color }) => color || theme.colors.copper};

    &:hover {
      color: ${({ theme, colorBg }) => colorBg || theme.colors.lightCopper};
    }
  }
`
const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 8px;
`
const Checkmark = styled.span`
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.border};
      }
    `};

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme, color }) => color || theme.colors.copper};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
