import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { fetchFaults } from 'services/FaultsService'

export type FaultsStateTypes = {
  faultsList: any[]
  faultsListPagination: any
  isFetchingFaults: boolean
  faultToEdit: any
}

const initialState: FaultsStateTypes = {
  faultsList: [],
  faultsListPagination: null,
  isFetchingFaults: false,
  faultToEdit: {}
}

export const getFaults = createAsyncThunk(
  'faults/getFaults',
  async (payload: any) => {
    return await fetchFaults(payload)
      .then(resp => {
        const faults = resp.data.data
        const pagination = {
          itemsCount: resp.data.meta.total,
          totalPages: resp.data.meta.lastPage,
          perPage: resp.data.meta.perPage,
          currentPage: resp.data.meta.currentPage
        }
        return { faults, pagination }
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }
)

export const faultsSlice = createSlice({
  name: 'faults',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Get faults
    builder.addCase(getFaults.pending, state => {
      state.isFetchingFaults = true
    })
    builder.addCase(getFaults.fulfilled, (state, action) => {
      state.isFetchingFaults = false
      state.faultsList = action.payload?.faults
      state.faultsListPagination = action.payload?.pagination
    })
    builder.addCase(getFaults.rejected, state => {
      state.isFetchingFaults = false
    })
  }
})

export const faultsReducer = faultsSlice.reducer
