import React, { useEffect, useState } from 'react'
import FileInput from 'components/atoms/FileInput'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import styled from 'styled-components'
import Select from 'components/atoms/Select'
import { useForm } from 'react-hook-form'
import { FormSeparator, SectionTitle } from 'components/formComponents'
import {
  setActiveStep,
  setFinishedStep,
  updateFormValues
} from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import { getFloorOptions } from 'utils/buildings'
import {
  selectCurrentBuilding,
  selectIsUploading
} from 'redux/selectors/buildingSelectors'
import { selectFloorPlanFileTypeId } from 'redux/selectors/dictionarySelectors'
import { justUploadBuildingFile } from 'redux/slices/buildingSlice'
import AddedFile from 'modules/forms/plansForm/components/AddedFile'
import { findIndex, propEq, update } from 'ramda'
import { addFinishedFormStep } from 'utils/forms'
import TextButton from 'components/atoms/TextButton'
import Button from 'components/atoms/Button'

const FloorPlansStep = () => {
  const [showError, setShowError] = useState(false)
  const dispatch = useAppDispatch()
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const formValues = useAppSelector(selectActiveFormValues)
  const floorOptions = getFloorOptions(formValues)
  const [addedFiles, setAddedFiles] = useState<any>([])
  const fileTypeId = useAppSelector<any>(selectFloorPlanFileTypeId)
  const isUploading = useAppSelector(selectIsUploading)

  useEffect(() => {
    const files = formValues.files || []
    const floorPlans = files?.filter(file => file.fileTypeId === fileTypeId)
    if (isNotNilOrEmpty(floorPlans)) {
      setAddedFiles(floorPlans)
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues: {
      floorNumbers: [],
      file: {}
    }
  })

  const file = watch('file')
  const floors = watch('floorNumbers')

  // @ts-ignore
  const fileExtension = file.path?.slice(-3)
  const isExtensionAllowed = isNotNilOrEmpty(fileExtension) && ['pdf', 'dwg'].includes(fileExtension)

  const handleFileUpload = file => {
    setAddedFiles(prev => [...prev, file])
  }

  const uploadFile = () => {
    handleSubmit(async data => {
      const result = await dispatch(
        justUploadBuildingFile({
          ...data,
          fileTypeId,
          buildingId: currentBuilding.id
        })
      )

      const payload = result.payload

      if (isNotNilOrEmpty(payload)) {
        handleFileUpload({
          // @ts-ignore
          ...payload,
          floorNumbers: floors
        })
        setValue('floorNumbers', [])
        setValue('file', {})
      }
    })()
  }

  useEffect(() => {
    const otherFiles =
      formValues?.files?.filter(file => file.fileTypeId !== fileTypeId) || []
    const filtered = addedFiles.filter(file => isNotNilOrEmpty(file))
    dispatch(updateFormValues({ files: [...otherFiles, ...filtered] }))
  }, [addedFiles])

  const handleGoNext = () => {
    dispatch(setFinishedStep('floor_plans'))
    dispatch(setActiveStep('building_plans'))
    dispatch(
      updateFormValues({
        buildingFormsState: addFinishedFormStep(
          'plans',
          'floor_plans',
          formValues?.buildingFormsState || []
        )
      })
    )
  }

  const handleValidate = () => {
    if (isNotNilOrEmpty(addedFiles)) {
      setShowError(false)
      handleGoNext()
    } else {
      setShowError(true)
    }
  }

  const handleGoBack = () => {
    dispatch(setActiveStep('number_of_floors'))
  }

  const handleEdit = url => floorNumbers => {
    const fileIndex = findIndex(propEq('url', url), addedFiles)
    const updated = update(fileIndex, {
      ...addedFiles[fileIndex],
      floorNumbers
    })
    setAddedFiles(updated)
  }

  const handleRemove = url => () => {
    setAddedFiles(prev => prev.filter(v => v.url !== url))
  }

  const handleDeselectFloorNumber = floorNumber => {
    const filtered = floors.filter(v => v !== floorNumber)
    setValue('floorNumbers', filtered)
  }

  return (
    <div>
      <ExpandableInfo
        title='What information should be provided?'
        shortDescription={
          <div>
            Floor plans must show the location of specific equipment as defined
            by the{' '}
            <a
              href='https://www.gov.uk/government/publications/fire-safety-england-regulations-2022'
              target='_blank'
              rel='noreferrer'
            >
              Regulations
            </a>
            .
          </div>
        }
      >
        <InfoContent>
          <p>
            The plans must clearly identify and distinguish between the
            following:
          </p>
          <ul>
            <li>Passenger lifts</li>
            <li>Lift for use by firefighters</li>
            <li>Evacuation lifts</li>
            <li>PasInlets / outlets for dry-rising mainssenger</li>
            <li>Inlets / outlets for wet-rising mains</li>
            <li>Smoke control systems</li>
            <li>Suppression systems</li>
          </ul>
          <p>
            A plan will need to be prepared for each floor; where floors are
            identical it is permissible to prepare a single plan providing the
            floors to which the plans refer to are clearly indicated.
          </p>
        </InfoContent>
      </ExpandableInfo>
      {addedFiles.length > 0 && (
        <AddedFiles>
          {addedFiles.map(file => (
            <AddedFile
              key={file.displayName}
              file={file}
              isFloorPlan
              onEdit={handleEdit(file.url)}
              onRemove={handleRemove(file.url)}
            />
          ))}
        </AddedFiles>
      )}
      <SectionTitle>Add file</SectionTitle>
      <Select
        name='floorNumbers'
        multiple
        control={control}
        label='Applicable floors'
        error={errors.floorNumbers?.message}
        options={floorOptions}
        onItemDeselect={handleDeselectFloorNumber}
      />
      <FileInput
        onChange={v => setValue('file', v)}
        isLoading={isUploading}
        accept='.pdf,.dwg'
        isUploaded={isNotNilOrEmpty(file)}
      />
      {
        !isExtensionAllowed && isNotNilOrEmpty(file) && (
          <Error>File extension not allowed. Please use PDF od DWG file.</Error>
        )
      }
      <AddFileButtonWrapper>
        <TextButton
          label='Upload selected file'
          onClick={uploadFile}
          disabled={isUploading || isNilOrEmpty(file) || isNilOrEmpty(floors) || !isExtensionAllowed}
        />
      </AddFileButtonWrapper>
      {showError && <Error>You have to upload at least one floor plan</Error>}
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={handleGoBack}>
          Back
        </Button>
        <Button variant='outlined' onClick={handleValidate}>
          Next step
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default FloorPlansStep

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ul {
    list-style: disc;
    font-weight: bold;
    padding-left: 10px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px !important;
  }
`

const AddFileButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AddedFiles = styled.div`
  margin: 10px 0 20px;
`

const Error = styled.div`
  font-size: 14px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.error};
`
