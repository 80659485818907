import React, { useEffect, useMemo } from 'react'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import { FormSeparator } from 'components/formComponents'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, object, string } from 'yup'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import {
  setActiveStep,
  setFinishedStep,
  updateFormValues
} from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import QuestionWithAnswers from 'modules/forms/wallsForm/components/QuestionWithAnswers'
import { selectFormsDictionary } from 'redux/selectors/dictionarySelectors'
import { equals, keys, pick, propOr } from 'ramda'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import { addFinishedFormStep } from 'utils/forms'
import Button from 'components/atoms/Button'

const schema = object({
  wallFeaturesOrAttachments: array().notRequired(),
  otherWallFeatures: string()
    .when('wallFeaturesOrAttachments', {
      is: value => value.some(v => v.includes('other:')),
      then: schema => schema.required('This field is required'),
      otherwise: schema => schema.notRequired().nullable()
    })
    .nullable(),
  wallFeaturesAdditionalInfo: string()
    .notRequired()
    .nullable()
    .max(250, 'Description should not be longer than 250 characters')
})

const WallFeaturesAndAttachments = () => {
  const dispatch = useAppDispatch()
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const defaultValues = {
    wallFeaturesOrAttachments: (
      currentBuilding.wallFeaturesOrAttachments || []
    ).map(v => v.id),
    otherWallFeatures: currentBuilding.otherWallFeatures || '',
    wallFeaturesAdditionalInfo: currentBuilding.wallFeaturesAdditionalInfo || ''
  }
  const formValues = useAppSelector(selectActiveFormValues)

  useEffect(() => {
    dispatch(updateFormValues(defaultValues))
  }, [])

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const dictionary = useAppSelector(selectFormsDictionary)

  const values = watch()

  useEffect(() => {
    if (!equals(values, pick(keys(defaultValues), formValues || {}))) {
      dispatch(updateFormValues(values))
    }
  }, [values])

  const wallFeaturesOptions = useMemo(() => {
    // @ts-ignore
    return propOr([], 'wallFeaturesOrAttachments', dictionary).map(item => ({
      label: item.name,
      value: item.id
    }))
  }, [dictionary])

  const submit = () => {
    handleSubmit(data => {
      // @ts-ignore
      const filtered = data.wallFeaturesOrAttachments?.filter(
        v => !v.includes('other:')
      )
      const payload = {
        wallFeaturesOrAttachments: filtered,
        buildingFormsState: addFinishedFormStep(
          'wall_design',
          'wall_features_and_attachments',
          formValues?.buildingFormsState || []
        )
      }
      dispatch(updateFormValues(payload))
      dispatch(setActiveStep('risk_and_mitigation'))
      dispatch(setFinishedStep('wall_features_and_attachments'))
    })()
  }

  const handleGoBack = () => {
    dispatch(setActiveStep('external_wall_system'))
  }

  const handleAnswerChange = values => {
    const otherOption = values.find(v => v.includes('other:'))
    const withoutOther = values.filter(v => !v.includes('other:'))

    if (otherOption) {
      setValue('otherWallFeatures', otherOption.replace('other:', ''))
      setValue('wallFeaturesOrAttachments', withoutOther)
    } else {
      setValue('otherWallFeatures', '')
      setValue('wallFeaturesOrAttachments', withoutOther)
    }
    setTimeout(() => {
      dispatch(updateFormValues(values))
    }, 100)
  }

  return (
    <div>
      <ExpandableInfo
        title='Attachments incorporated into the external walls of the building can contribute to external fire spread' />
      <QuestionWithAnswers
        multi
        withOther
        onChange={handleAnswerChange}
        question='Does the building include any of the following features / attachments?'
        description='Select all that apply'
        answers={wallFeaturesOptions}
        initialValues={defaultValues.wallFeaturesOrAttachments}
        initialOtherValue={defaultValues.otherWallFeatures}
      />
      <Input
        name='wallFeaturesAdditionalInfo'
        multiline
        register={register}
        label='Where the features / attachments selected above are likely to ignite and spread fire easily, provide further information below.'
        error={errors.wallFeaturesAdditionalInfo?.message}
        placeholder='Enter a description...'
      />
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={handleGoBack}>
          Back
        </Button>
        <Button variant='outlined' onClick={submit}>
          Next step
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default WallFeaturesAndAttachments

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px !important;
  }
`
