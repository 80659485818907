import React from 'react'
import Icon from './Icon'

export const FlameIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='12'
      height='12'
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7.28272 6C7.42114 5.77268 7.56422 5.53764 7.70554 5.2953C8.17854 4.48444 8.26534 3.66742 7.96361 2.86689C7.35477 1.25167 5.31956 0.267533 4.84839 0.110512L4.51708 0L3.99998 1.0344L4.24952 1.23825C4.25232 1.24049 4.52949 1.48268 4.54796 1.84983C4.56374 2.16324 4.39192 2.50193 4.03741 2.85654C3.79122 3.10271 3.52102 3.34074 3.23493 3.59272C2.03126 4.65274 0.666992 5.85453 0.666992 8.12803C0.666992 8.15926 0.667286 8.1903 0.667873 8.22115C0.676596 8.72285 0.784349 9.21789 0.984943 9.67782C1.18554 10.1378 1.47502 10.5535 1.83676 10.9013C2.56316 11.6078 3.53713 12.0021 4.55045 12H6.86841L6.58227 11.4193C5.4197 9.05982 6.24185 7.70952 7.28271 6.00001L7.28272 6ZM11.2155 8.02206C11.2228 8.04889 11.2298 8.07561 11.2365 8.10222C11.3528 8.56231 11.3618 9.04294 11.263 9.50708C11.1642 9.97123 10.9602 10.4065 10.6666 10.7793C10.3713 11.1605 9.99235 11.4688 9.55906 11.6804C9.12577 11.892 8.64968 12.0014 8.16748 12H7.8937L7.78596 11.7655C7.22892 10.5539 7.07172 9.42499 7.30525 8.31424C7.51007 7.34024 7.9799 6.48172 8.52738 5.54045L8.76763 5.12749L9.134 5.43409C9.31644 5.58678 10.9249 6.95666 11.2155 8.02206Z' />
    </Icon>
  )
}

export default FlameIcon


