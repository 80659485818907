import APIService from './APIService'

const api = APIService()

export type CheckUserPayloadTypes = {
  email: string
}

export const fetchFileTypes = () => api.get('/dictionaries/file-types-info-exchange')
export const fetchBuildingFilters = () => api.get('/dictionaries/building-filters')
export const fetchFrsStations = () => api.get('/dictionaries/frs-domains')
export const fetchExternalFacingMaterials = () => api.get('/dictionaries/external-facing-materials')
export const fetchFormsDictionary = () => api.get('/dictionaries/info-exchange-frs-forms')
export const fetchUsersByEmail = (payload: CheckUserPayloadTypes) => api.get(`/dictionaries/users?filter[email]=${payload.email}`)
export const fetchFaultsDictionary = () => api.get(`/dictionaries/fault-equipment-types`)
export const getFrsSlug = payload => api.get(`/emergency-services/${payload}`)
