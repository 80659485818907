import React, { useState } from 'react'
import styled from 'styled-components'
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons'
import { Collapse } from '@mui/material'

type propsType = {
  title: string
  content: string[]
}

const ContentItem = ({ title, content }: propsType) => {
  const [showContent, setShowContent] = useState(false)

  const toggleContent = () => {
    setShowContent(prev => !prev)
  }

  return (
    <Wrapper>
      <TitleWrapper onClick={toggleContent}>
        <Title>{title}</Title>
        {showContent ? (
          <IconWrapper>
            <ArrowUpIcon />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <ArrowDownIcon />
          </IconWrapper>
        )}
      </TitleWrapper>
      <Collapse in={showContent}>
        {content.map(item => (
          <Content key={item}>{item}</Content>
        ))}
      </Collapse>
    </Wrapper>
  )
}

export default ContentItem

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`
const Title = styled.h2`
  font-size: 18px;
  font-weight: bolder;
`
const Content = styled.div`
  font-size: 16px;
  margin: 10px 24px 0 0;
  white-space: pre-wrap;
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
`
