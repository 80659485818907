import React from 'react'
import styled, { keyframes } from 'styled-components'
// @ts-ignore
import logoShort from 'assets/logo-short.png'

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0.9
  }
  50% {
    transform: scale(1);
    opacity: 1
  }
  100% {
    transform: scale(0.9);
    opacity: 0.9
  }
`

const Loading = () => {
  return (
    <Wrapper>
      <>
        <img src={logoShort} alt='loading-logo' />
        <div className='lds-ellipsis'>
          <div />
          <div />
          <div />
          <div />
        </div>
      </>
    </Wrapper>
  )
}

export default Loading

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 99;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ellipsis div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  img {
    width: 70px;
      //animation: ${pulseAnimation} 2s infinite;
    margin-bottom: 20px;
  }
`
