import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Collapse } from '@mui/material'
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons'

type ExpandableStepInfoTypes = {
  title: string
  children?: ReactNode
  isCompleted?: boolean
  status?: boolean
}

const ExpandableStepInfo = ({
  title,
  children,
  isCompleted,
  status
}: ExpandableStepInfoTypes) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(prev => !prev)
  const hasChildren = !!children

  return isCompleted && hasChildren ? (
    <Wrapper open={open} color={isCompleted}>
      <Header onClick={hasChildren ? toggleOpen : () => {
      }}>
        <TitleWrapper>
          <div>
            <Title>{title}</Title>
          </div>
        </TitleWrapper>
        {hasChildren && (
          <>
            {open ? (
              <IconWrapper>
                <ArrowUpIcon />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <ArrowDownIcon />
              </IconWrapper>
            )}
          </>
        )}
      </Header>
      <Collapse in={open}>
        <Content>{children}</Content>
      </Collapse>
    </Wrapper>
  ) : (
    <Wrapper color={isCompleted}>
      <Header>
        <TitleWrapper>
          <div>
            <Title>{title}</Title>
          </div>
        </TitleWrapper>
        {isCompleted && <div>{status ? 'Yes' : 'No'}</div>}
      </Header>
    </Wrapper>
  )
}

export default ExpandableStepInfo

const Wrapper = styled.div`
  padding: 18px 0;
  color: ${({ theme, color }) => !color && theme.colors.lightTrout};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`
const Title = styled.div`
  font-weight: bold;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
const TitleWrapper = styled.div`
  display: flex;
  gap: 14px;
  cursor: pointer;
`
const Content = styled.div``

const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
`
