import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import Input from 'components/atoms/Input'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { FormSeparator, InputDescription, SectionTitle } from 'components/formComponents'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectFrsStations } from 'redux/selectors/dictionarySelectors'
import Autocomplete from 'components/atoms/Autocomplete'
import { setActiveStep, setFinishedStep, updateFormValues } from 'redux/slices/formSlice'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { debounce } from 'lodash'
import { omit } from 'ramda'
import { addFinishedFormStep } from 'utils/forms'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import Button from 'components/atoms/Button'
import ExpandableInfo from 'components/atoms/ExpandableInfo'

const schema = object({
  name: string().required('Building name is required'),
  uprn: string()
    .nullable()
    .test(
      'match',
      'UPRN number is incorrect',
      // @ts-ignore
      value => isNilOrEmpty(value) || /^\d*$/.test(value)
    )
    .test(
      'max',
      'UPRN should not be longer than 12 characters',
      // @ts-ignore
      value => isNilOrEmpty(value) || value.length <= 12
    ),
  postalCode: string()
    .required('Postal Code is required')
    .max(8, 'Postal code should not be longer than 8 characters'),
  units: string().required('Units number is required'),
  street: string().required('Address is required'),
  city: string()
    .required('City is required')
    .matches(/^[a-zA-Z]+$/, { message: 'Incorrect city name' }),
  frs: object({
    label: string().required(),
    value: string().required()
  })
    .required('Responsible FRS is required')
    .nullable()
})

type BuildingInformationStepTypes = {
  fetchedBp?: any
}

const BuildingInformationStep = ({ fetchedBp }: BuildingInformationStepTypes) => {
  const dispatch = useAppDispatch()
  const frsStations = useAppSelector(selectFrsStations)
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const formValues = useAppSelector(selectActiveFormValues)

  const defaultValues = {
    name: currentBuilding?.name || '',
    uprn: currentBuilding?.uprn || '',
    postalCode: currentBuilding?.postalCode || '',
    city: currentBuilding?.city || '',
    street: currentBuilding?.street || '',
    units: currentBuilding?.buildingType?.units || '',
    frs: isNotNilOrEmpty(currentBuilding?.frs)
      ? {
          label: currentBuilding?.frs?.name,
          value: currentBuilding?.frs?.id
        }
      : ''
  }

  const { register, handleSubmit, formState: { errors }, control, setValue, watch } = useForm({
    // @ts-ignore
    defaultValues,
    resolver: yupResolver(schema)
  })

  const frsOptions = useMemo(() => {
    return frsStations
      ? frsStations.map(station => ({
          label: station.name,
          value: station.id
        }))
      : []
  }, [frsStations])

  const values = watch()

  const updateValues = debounce(v => {
    dispatch(
      updateFormValues({
        ...omit(['frs', 'units'], v),
        frsId: v.frs?.value,
        buildingType: {
          type: 'residential',
          units: v.units
        }
      })
    )
  }, 500)

  useEffect(() => {
    updateValues(values)
  }, [values])

  useEffect(() => {
    if (isNotNilOrEmpty(fetchedBp)) {
      setValue('name', fetchedBp.name)
      setValue('street', fetchedBp.street)
      setValue('city', fetchedBp.city)
      setValue('postalCode', fetchedBp.postalCode)
      setValue('uprn', fetchedBp.uprn)
    }
  }, [fetchedBp])

  const submit = () => {
    dispatch(setActiveStep('responsible_persons'))
    dispatch(
      updateFormValues({
        buildingFormsState: addFinishedFormStep(
          'edit_building',
          'building_information',
          formValues?.buildingFormsState || []
        )
      })
    )
    dispatch(setFinishedStep('building_information'))
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        register={register}
        label='Building Name / Number'
        placeholder='eg. 17 Basil Street'
        name='name'
        error={errors.name?.message}
      />
      <Input
        register={register}
        label='UPRN (optional)'
        placeholder='up to 12 digits'
        name='uprn'
        error={errors.uprn?.message}
        additionalLabel={
          <Tooltip
            title='UPRN stands for Unique Property Reference Number and was created by the Ordnance Survey (OS).
           It consists of numbers of up to 12 digits in length. Local governments in the UK have allocated
           a unique number for each land or property.'
          >
            <UprnHint>
              <QuestionIcon />
              <UprnHintText>What is the UPRN?</UprnHintText>
            </UprnHint>
          </Tooltip>
        }
      />
      <InputDescription>
        If you don’t know your UPRN, you can find it on&nbsp;
        <a
          href='https://www.findmyaddress.co.uk/search'
          target='_blank'
          rel='noreferrer'
        >
          GeoPlace
        </a>
      </InputDescription>
      <Row>
        <Input
          register={register}
          label='Post Code'
          name='postalCode'
          error={errors.postalCode?.message}
        />
        <Input
          register={register}
          label='City'
          name='city'
          error={errors.city?.message}
        />
      </Row>
      <Input
        register={register}
        label='Address'
        name='street'
        error={errors.street?.message}
      />
      <FormSeparator />
      <SectionTitle>Building details</SectionTitle>
      <ExpandableInfo title='Building type - residential'>
        We understand that your building contains residential units.
        Please fill in the number of separate units (i.e apartments, hotel rooms, student rooms) in the
        building. If
        your building also contains other types of use (such as retail, office etc) you can optionally add this
        later to
        your Building Passport.
      </ExpandableInfo>
      <Row>
        <div>
          <FakeInputDescription>Building </FakeInputDescription>
          <FakeBox>
            <span>Residential</span>
          </FakeBox>
        </div>
        <div>
          <Input
            register={register}
            label='Residential units'
            name='units'
            error={errors.units?.message}
          />
          <InputDescription>
            Provide a number of residential units
          </InputDescription>
        </div>
      </Row>
      <FormSeparator />
      <SectionTitle>FRS Details</SectionTitle>
      <Autocomplete
        options={frsOptions}
        control={control}
        label='Responsible FRS'
        name='frs'
        error={errors.frs?.message}
      />
      <InputDescription>
        If you are not sure, you can find it on&nbsp;
        <a
          href='http://www.cfoa.org.uk/frs?postcode'
          target='_blank'
          rel='noreferrer'
        >
          The Chief Fire Officers’ Assosiation website
        </a>
      </InputDescription>
      <FormSeparator />
      <ButtonWrapper>
        <Button type='submit' variant='outlined'>
          Next step
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export default BuildingInformationStep

const QuestionIcon = styled(HelpIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-right: 5px;
  font-size: 16px !important;
`

const UprnHint = styled.div`
  display: flex;
  align-items: center;
`

const UprnHintText = styled.div`
  font-size: 12px;
`

const Row = styled.div`
  display: flex;
  gap: 24px;

  & > * {
    flex: 1;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    width: 110px !important;
  }
`
const FakeBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 284px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  margin-top: 6px;
  padding-left: 16px;
  background-color: #F7F8FD;

  span {
    color: #696d80;
  }
`

const FakeInputDescription = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
`
