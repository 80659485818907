import { createSlice } from '@reduxjs/toolkit'
import { getFormContent } from 'utils/forms'
import { isNotNil } from 'utils/ramda'
import { equals } from 'ramda'

const addStepIfMissing = (steps, stepName) => {
  return steps.includes(stepName) ? steps : [...steps, stepName]
}

type Step = {
  title: string
  key: string
}

export type FormSliceType = {
  activeForm: string | null
  activeStep: string | null
  formTitle: string | null
  formSteps: Step[]
  values: any | null
  visibleSteps: string[]
  finishedSteps: string[]
}

const initialState: FormSliceType = {
  activeForm: null,
  activeStep: null,
  formTitle: null,
  formSteps: [],
  values: null,
  visibleSteps: [],
  finishedSteps: []
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setActiveForm: (state, action) => {
      const formContent = getFormContent(action.payload)
      state.activeForm = action.payload
      state.values = initialState.values
      state.formTitle = formContent.title
      state.formSteps = formContent.steps
    },
    clearActiveForm: state => {
      state.activeForm = initialState.activeForm
      state.activeStep = initialState.activeStep
      state.formTitle = initialState.formTitle
      state.formSteps = initialState.formSteps
      state.values = initialState.values
      state.visibleSteps = initialState.visibleSteps
      state.finishedSteps = initialState.finishedSteps
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload
      state.visibleSteps = addStepIfMissing(state.visibleSteps, action.payload)
    },
    setFinishedStep: (state, action) => {
      state.finishedSteps = addStepIfMissing(
        state.finishedSteps,
        action.payload
      )
    },
    updateFinishedSteps: (state, action) => {
      state.finishedSteps = action.payload
    },
    updateVisibleSteps: (state, action) => {
      state.visibleSteps = action.payload
    },
    setFormValues: (state, action) => {
      state.values = action.payload
    },
    updateFormValues: (state, action) => {
      const payload = action.payload
      const selected = {}

      Object.keys(payload).forEach(function (key) {
        if (payload[key] === undefined) {
          payload[key] = null
        }
      })

      for (const key in payload) {
        const stateCopy = JSON.parse(JSON.stringify(state))
        const currentValues = stateCopy?.values || {}
        const hasValue = isNotNil(currentValues[key])

        selected[key] = hasValue ? currentValues[key] : null
      }

      if (!equals(payload, selected)) {
        state.values = {
          ...state.values,
          ...payload
        }
      }
    }
  }
})

export const {
  setActiveForm,
  clearActiveForm,
  setActiveStep,
  setFinishedStep,
  updateFormValues,
  updateFinishedSteps,
  updateVisibleSteps
} = formSlice.actions

export const formSliceReducer = formSlice.reducer
