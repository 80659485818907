import React, { useMemo } from 'react'
import styled from 'styled-components'
import Status from 'components/atoms/Status'
import { isNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { LevelBarsIcon } from 'assets/icons'
import InfoWithIcon from './InfoWithIcon'

type propsType = {
  item: {
    list?: { id: string; name: string }[] | string[]
    status: { label: string; value: string }
    riskLevel: { label: string; value: string }
    noAdditionalMeasures?: boolean
  }
}

const BuildingUploadedRiskItem = ({ item }: propsType) => {
  const { list, status, riskLevel, noAdditionalMeasures } = item

  const description = useMemo(() => {
    switch (riskLevel.value) {
      case 'low':
        return ' No increased risk of external fire spread, no remediation necessary'
      case 'medium':
        return 'Increased risk of external fire spread, remediation may not be necessary'
      case 'high':
        return 'Significantly increased risk of external fire spread and remediation works are necessary'
      default:
        return 'The overall level of risk of the external walls has not been determined'
    }
  }, [riskLevel])

  return (
    <ItemList>
      <ItemInfo>
        <span>Further fire risk appraisal</span>
        <ItemInfoValue value={riskLevel.label}>
          <Status status={status.value} />
        </ItemInfoValue>
      </ItemInfo>
      <InfoWithIcon
        title='Overall level of risk of fire spread'
        icon={
          <IconWrapper>
            <LevelBarsIcon level={riskLevel.value} />
          </IconWrapper>
        }
        labelText={riskLevel.label}
      />
      {description && <ItemDescription>{description}</ItemDescription>}
      <ItemInfo color={isNilOrEmpty(list)}>
        Actions taken to mitigate the risk of fire spread:
      </ItemInfo>
      {list && (
        <StyledUl>
          {list.map((listItem, index) => (
            <li key={listItem.id}>{propOr('', 'name', listItem)}</li>
          ))}
        </StyledUl>
      )}
      {noAdditionalMeasures && <div>Not necessary</div>}
    </ItemList>
  )
}

export default BuildingUploadedRiskItem

const ItemList = styled.div`
  margin-top: 12px;
`
const ItemInfo = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-size: bolder;
  color: ${({ theme, color }) => color && theme.colors.lightTrout};
`
const ItemInfoValue = styled.div`
  color: ${({ theme, value }) =>
          value !== 'high' ? theme.colors.lightTrout : theme.colors.copper};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  gap: 4px;
`

const ItemDescription = styled.div`
  margin-top: 12px;
  padding: 6px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.lightTrout};
  background-color: ${({ theme }) => theme.colors.copperBg};
`

const StyledUl = styled.ul`
  list-style-type: none;

  li {
    color: ${({ theme }) => theme.colors.lightTrout};
    margin-top: 6px;
  }

  li:before {
    content: '• ';
  }
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.copper};
`
