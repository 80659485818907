import React, { useEffect, useMemo, useState } from 'react'
import FiltersSection from 'modules/myBuildings/components/filters/FiltersSection'
import { useAppSelector } from 'redux/hooks'
import { selectBuildingFilters } from 'redux/selectors/dictionarySelectors'
import { equals, keys, propOr } from 'ramda'
import StandaloneCheckbox from 'components/atoms/StandaloneCheckbox'
import { isNotNilOrEmpty } from 'utils/ramda'
import { snakeCase, startCase } from 'lodash'

const WallDesignCompletenessFilter = ({ onChange, counter, queryFilters }) => {
  const buildingFilters = useAppSelector(selectBuildingFilters)
  const filters = propOr({}, 'wallDesignCompleteness', buildingFilters)
  const [values, setValues] = useState({})
  const [lastChosen, setLastChosen] = useState({})

  useEffect(() => {
    if (isNotNilOrEmpty(values)) {
      const query = keys(values)
        .map(key => (values[key] ? snakeCase(key) : null))
        .filter(v => isNotNilOrEmpty(v))
        .join(',')
      onChange('wall_design_completeness', query)
    }
  }, [values])

  const handleChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!equals(queryFilters, lastChosen)) {
      const newValues = {}
      for (const key in values) {
        newValues[snakeCase(key)] = queryFilters.includes(snakeCase(key))
      }
      setValues(newValues)
      setLastChosen(queryFilters)
    }
  }, [queryFilters])

  useEffect(() => {
    const newFilters = {}
    if (isNotNilOrEmpty(queryFilters)) {
      queryFilters.forEach(v => {
        newFilters[v] = true
      })
      setValues(prev => ({ ...prev, ...newFilters }))
    }
  }, [])

  const content = useMemo(() => {
    if (isNotNilOrEmpty(filters)) {
      return keys(filters).map(key => {
        return (
          <StandaloneCheckbox
            // @ts-ignore
            disabled={filters[key] === 0}
            key={`wall-design-completeness${key}`}
            name={snakeCase(key)}
            // @ts-ignore
            label={`${startCase(key)} (${filters[key]})`}
            onChange={handleChange}
            initialValue={values[snakeCase(key)] || false}
          />
        )
      })
    } else {
      return null
    }
  }, [filters, queryFilters, values])

  return (
    <FiltersSection
      title='Wall Design - Information Exchange'
      counter={counter}
    >
      {content}
    </FiltersSection>
  )
}

export default WallDesignCompletenessFilter
