import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  selectFinishedSteps,
  selectFormSteps,
  selectFormVisibleSteps
} from 'redux/selectors/formSelectors'
import FloorNumbersStep from 'modules/forms/plansForm/steps/FloorNumbersStep'
import ProgressBar from 'components/atoms/ProgressBar'
import styled from 'styled-components'
import FormStep from 'modules/forms/components/FormStep'
import FloorPlansStep from 'modules/forms/plansForm/steps/FloorPlansStep'
import BuildingPlansStep from 'modules/forms/plansForm/steps/BuildingPlansStep'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { updateFormValues } from 'redux/slices/formSlice'

const PlansForm = () => {
  const steps = useAppSelector(selectFormSteps)
  const finishedSteps = useAppSelector(selectFinishedSteps)
  const visibleSteps = useAppSelector(selectFormVisibleSteps)
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const dispatch = useAppDispatch()

  const isVisible = stepName => visibleSteps.includes(stepName)

  const progressBarCount = (finishedSteps.length / steps.length) * 100

  useEffect(() => {
    const files = currentBuilding.files || []
    dispatch(updateFormValues({ files }))
  }, [])

  return (
    <div>
      <ProgressBarWrapper>
        <ProgressBar value={progressBarCount} />
      </ProgressBarWrapper>
      {
        isVisible('number_of_floors') && (
          <FormStep
            stepKey='number_of_floors'
            title='Number of Floors'
          >
            <FloorNumbersStep />
          </FormStep>
        )
      }
      {
        isVisible('floor_plans') && (
          <FormStep
            stepKey='floor_plans'
            title='Floor Plans'
          >
            <FloorPlansStep />
          </FormStep>
        )
      }
      {
        isVisible('building_plans') && (
          <FormStep
            stepKey='building_plans'
            title='Building Plans'
          >
            <BuildingPlansStep />
          </FormStep>
        )
      }
    </div>
  )
}

export default PlansForm

const ProgressBarWrapper = styled.div`
  width: 80%;
  margin: 0 auto 20px;
`
