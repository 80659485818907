import { CircularProgress } from '@mui/material'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'

type ButtonTypes = {
  children: ReactNode
  isLoading?: boolean
  loadingText?: string
  variant?: 'secondary' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  onClick?: any
  type?: string
  icon?: ReactNode
  disabled?: boolean
}

const Button = (props: ButtonTypes) => {
  const {
    children,
    isLoading,
    loadingText,
    size,
    onClick,
    type,
    icon,
    disabled,
    variant
  } = props

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      size={size}
      variant={variant}
      type={type}
      isLoading={isLoading}
    >
      {isLoading && (
        <CircularProgress
          size={20}
          color='inherit'
          style={{ margin: '0 15px' }}
        />
      )}
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {isLoading ? isNotNilOrEmpty(loadingText) && loadingText : children}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  allign-items: center;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: bold;

  ${({ variant }) => {
    switch (variant) {
      case 'secondary':
        return css`
          border: 1px solid ${({ theme, disabled }) =>
                  disabled
                          ? theme.colors.disabledBg
                          : theme.colors.buttonSecondary};
          background-color: ${({ theme, disabled }) =>
                  disabled ? theme.colors.disabledBg : theme.colors.buttonSecondary};
          color: ${({ theme, disabled }) =>
                  disabled ? theme.colors.disabled : theme.colors.trout};

          &:hover:enabled {
            background-color: ${({ theme }) =>
                    theme.colors.buttonSecondaryHover};
            border: 1px solid ${({ theme }) => theme.colors.buttonSecondaryHover};
          }

          &:active:enabled {
            background-color: ${({ theme }) => theme.colors.disabled};
            border: 1px solid ${({ theme }) => theme.colors.disabled};
          }

          ${({ isLoading }) =>
                  isLoading &&
                  css`
                    border: 1px solid ${({ theme }) => theme.colors.buttonSecondary};
                    background-color: ${({ theme }) => theme.colors.buttonSecondary};
                    color: ${({ theme }) => theme.colors.trout};
                  `}
        `
      case 'outlined':
        return css`
          border: 1px solid ${({ theme, disabled }) =>
                  disabled ? theme.colors.disabled : theme.colors.trout};
          background-color: ${({ theme, disabled }) =>
                  disabled ? theme.colors.disabledBg : '#fff'};
          color: ${({ theme, disabled }) =>
                  disabled ? theme.colors.disabled : theme.colors.trout};

          &:hover:enabled {
            background-color: ${({ theme }) =>
                    theme.colors.buttonSecondaryHover};
          }

          &:active:enabled {
            background-color: ${({ theme }) => theme.colors.disabled};
          }

          ${({ isLoading }) =>
                  isLoading &&
                  css`
                    background-color: #fff;
                    color: ${({ theme }) => theme.colors.trout};
                  `}
        `
      default:
        return css`
          background-color: ${({ theme, disabled }) =>
                  disabled ? theme.colors.buttonSecondary : theme.colors.haiti};
          border: 1px solid ${({ theme, disabled }) =>
                  disabled ? theme.colors.buttonSecondary : theme.colors.haiti};

          &:hover:enabled {
            background-color: ${({ theme }) => theme.colors.trout};
            border: 1px solid ${({ theme }) => theme.colors.trout};
          }

          &:active:enabled {
            background-color: ${({ theme }) => theme.colors.lightTrout};
            border: 1px solid ${({ theme }) => theme.colors.lightTrout};
          }

          ${({ isLoading }) =>
                  isLoading &&
                  css`
                    border: 1px solid ${({ theme }) => theme.haiti};
                    background-color: ${({ theme }) => theme.colors.haiti};
                    color: ${({ theme }) => theme.colors.lighTrout};
                  `}
        `
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          padding: 6px 12px;
          font-size: 12px;
        `
      case 'medium':
        return css`
          padding: 8px 12px;
          font-size: 14px;
        `
      case 'large':
        return css`
          padding: 20px 24px;
          font-size: 16px;
        `
      default:
        return css`
          padding: 12px 24px;
          font-size: 16px;
        `
    }
  }};
`
const IconWrapper = styled.div`
  fill: #fff;
  display: flex;
  align-items: center;
  margin-right: 6px;
`
