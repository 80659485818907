import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Checkbox from 'components/atoms/Checkbox'
import { useForm } from 'react-hook-form'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, omit, keys, propEq, findIndex, equals } from 'ramda'
import Radio from 'components/atoms/Radio'
import { FormSeparator } from 'components/formComponents'

type Answer = {
  label: string
  value: string | boolean
  description?: string
  activeDescription?: string
  primary?: boolean
  trigger?: any
}

type QuestionWithAnswersTypes = {
  question: string
  description?: string
  answers: Answer[]
  multi?: boolean
  onChange: any
  withOther?: boolean
  error?: string,
  initialValues?: any
  initialOtherValue?: string
  primaryOptionSelected?: boolean
  initialRadioValue?: any
}

const QuestionWithAnswers = ({
  withOther,
  onChange,
  question,
  description,
  answers,
  multi,
  error,
  initialValues,
  initialOtherValue,
  primaryOptionSelected,
  initialRadioValue
}: QuestionWithAnswersTypes) => {
  const [savedAnswers, setSavedAnswers] = useState([])
  const [savedValues, setSavedValues] = useState({})
  const {register, watch, control, setValue} = useForm({
    defaultValues: {
      other: '',
      'radio-value': ''
    }
  })
  const [rerender, setRerender] = useState(0)

  const values = watch()

  useEffect(() => {
    if (!equals(savedAnswers, answers)) {
      if (multi) {
        if (primaryOptionSelected) {
          // @ts-ignore
          setValue('answer-0', true)
        } else {
          if (isNotNilOrEmpty(initialValues)) {
            initialValues.forEach(v => {
              // @ts-ignore
              const index = findIndex(propEq('value', v), answers)
              // @ts-ignore
              index !== -1 && setValue(`answer-${index}`, true)
            })
          }
          if (isNotNilOrEmpty(initialOtherValue) && values.other !== initialOtherValue) {
            // @ts-ignore
            setValue(`answer-${answers.length}`, true)
            // @ts-ignore
            setValue('other', initialOtherValue)
          }
        }
      } else {
        if (isNotNilOrEmpty(initialRadioValue) && initialRadioValue !== values['radio-value']) {
          const isValueInOptions = answers?.map(a => a.value).some(a => a === initialRadioValue)

          if (isValueInOptions) {
            setValue('radio-value', initialRadioValue)
          } else {
            setValue('radio-value', 'other')
            initialOtherValue && setValue('other', initialOtherValue)
          }
        }
      }
      setRerender(prev => prev + 1)
      // @ts-ignore
      setSavedAnswers(answers)
    }
  }, [answers, savedAnswers])

  useEffect(() => {
    if (!equals(savedValues, values)) {
      if (multi) {
        let valuesArray = []
        if (answers[0]?.primary) {
          for (const key in dissoc('other', values)) {
            const idx = key.replace('answer-', '')
            if (values['answer-0']) {
              const optionKeys = keys(omit(['answer-0', 'other'], values))
              optionKeys.forEach(key => {
                // @ts-ignore
                values[key] && setValue(key, false)
              })
              // @ts-ignore
              valuesArray = [answers[0]?.value]
            } else if (values[key]) {
              if (idx === String(answers.length)) {
                valuesArray[idx] = `other:${values.other}`
              } else {
                valuesArray[idx] = answers[idx]?.value
              }
            }
          }
        } else {
          for (const key in dissoc('other', values)) {
            const idx = key.replace('answer-', '')
            if (values[key]) {
              if (idx === String(answers.length)) {
                valuesArray[idx] = `other:${values.other}`
              } else {
                valuesArray[idx] = answers[idx]?.value
              }
            }
          }
        }
        onChange(valuesArray.filter(v => isNotNilOrEmpty(v)))
      } else {
        const isOther = values[`radio-value`] === 'other'
        const result = isOther ? values.other : values[`radio-value`]
        isNotNilOrEmpty(result) && onChange(result)
      }
      // @ts-ignore
      setSavedValues(values)
    }
  }, [values, savedValues])

  const answersWithStatus = answers.map((answer) => ({
    ...answer,
    isActive: values[`radio-value`] === answer.value
  }))

  const radioAnswers = withOther
    ? [...answersWithStatus, {
      label: (
        <InputOther
          {...register('other')}
          placeholder='Other...'
          disabled={values[`radio-value`] !== 'other'}
        />
      ), value: 'other'
    }]
    : answersWithStatus

  return (
    <Wrapper key={rerender}>
      <Question>{question}</Question>
      {description && <Description>{description}</Description>}
      {
        multi
          ? (
            <Checkboxes>
              {answers.map((answer, index) => {
                const value = values[`answer-${index}`]
                return (
                  <CheckboxAnswer key={`answer-${index}-${answer.value}`}>
                    <Checkbox
                      disabled={answers[0]?.primary && values['answer-0'] && index !== 0}
                      label={(
                        <div>
                          <div>{answer.label}</div>
                          {answer.description && <LabelDescription>{answer.description}</LabelDescription>}
                        </div>
                      )}
                      name={`answer-${index}`}
                      register={register}
                      checked={typeof value === 'boolean' ? value : isNotNilOrEmpty(value)}
                    />
                    {
                      answers[0]?.primary && index === 0 && (
                        <FormSeparator smallMargin />
                      )
                    }
                  </CheckboxAnswer>
                )
              })}
              {
                withOther && (
                  <CheckboxAnswer key='option-other'>
                    <Checkbox
                      disabled={answers[0]?.primary && values['answer-0']}
                      label={(
                        <div>
                          <InputOther
                            {...register('other')}
                            maxLength={250}
                            placeholder='Other...'
                            disabled={!values[`answer-${answers.length}`]}
                          />
                        </div>
                      )}
                      checked={values[`answer-${answers.length}`]}
                      name={`answer-${answers.length}`}
                      register={register}
                    />
                  </CheckboxAnswer>
                )
              }
            </Checkboxes>
          )
          : (
            <Radios>
              <Radio
                name='radio-value'
                options={radioAnswers}
                control={control}
              />
            </Radios>
          )
      }
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default QuestionWithAnswers

const Wrapper = styled.div`
  margin-bottom: 20px;
`
const Question = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const Description = styled.div`
  font-size: 13px;
  color: ${({theme}) => theme.colors.trout};
`

const Checkboxes = styled.div`
  margin-top: 10px;
`

const Radios = styled.div`

`

const CheckboxAnswer = styled.div`

`

const LabelDescription = styled.div`
  color: ${({theme}) => theme.colors.trout};
`

const InputOther = styled.input`
  width: 100%;
  height: 34px;
  border: 1px solid ${({theme}) => theme.colors.border};
  outline: none;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 12px;

  &:focus {
    border-color: ${({theme}) => theme.colors.secondary};
  }

  &:disabled {
    color: ${({theme}) => theme.colors.disabled};
  }
`

const Error = styled.div`
  font-size: 12px;
  color: ${({theme}) => theme.colors.error};
  margin-top: 5px;
`
