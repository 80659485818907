import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import logoSrc from 'assets/logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { logout } from 'redux/slices/authSlice'
import DecorativeButton from 'components/atoms/DecorativeButton'

const TopNav = () => {
  const currentUser = useAppSelector(selectCurrentUser)
  const isLoggedIn = isNotNilOrEmpty(currentUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleUserLogout = async () => {
    await dispatch(logout())
      .then(() => navigate(PATHS.login))
  }

  return (
    <TopNavWrapper>
      <Link to={PATHS.home}>
        <Logo src={logoSrc} alt='app-logo' />
      </Link>
      <NavLinks>
        {
          isLoggedIn
            ? (
              <>
                <Link to={PATHS.buildingsList}>Buildings list</Link>
                <LogoutButton onClick={handleUserLogout}>Logout</LogoutButton>
              </>
            )
            : (
              <>
                <Link to={PATHS.register}>Sign up</Link>
                <DecorativeButton size='small' onClick={() => navigate(PATHS.login)}>Log in</DecorativeButton>
              </>
            )
        }
      </NavLinks>
    </TopNavWrapper>
  )
}

export default TopNav

const TopNavWrapper = styled.div`
  height: ${({ theme }) => theme.dimensions.topNavHeight};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.navigation};
  border-bottom: 1px solid #DCE0F2;
  z-index: 10;
`

const Logo = styled.img`
  width: 160px;
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all .3s;
  
  & > * {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const LogoutButton = styled.div`
  cursor: pointer;
`
