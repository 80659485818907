import React from 'react'
import Icon from './Icon'

export const PlansIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.71157 8.05749L11.9853 11.1936L20.2585 8.05749L11.9853 4.92134L3.71157 8.05749ZM11.9853 12.115C11.9294 12.115 11.8736 12.1052 11.821 12.0852L2.28957 8.47235C2.11483 8.40565 2 8.24113 2 8.05749C2 7.87385 2.11483 7.70932 2.28957 7.64262L11.821 4.0298C11.9267 3.99023 12.0434 3.99023 12.1491 4.0298L21.6805 7.64262C21.8552 7.70932 21.9705 7.87385 21.9705 8.05749C21.9705 8.24113 21.8552 8.40565 21.6805 8.47235L12.1491 12.0852C12.0965 12.1052 12.0406 12.115 11.9853 12.115Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9853 16.0057C11.9299 16.0057 11.8741 15.996 11.8214 15.9759L2.29003 12.3631C2.11528 12.2964 2 12.1319 2 11.9483C2 11.7646 2.11528 11.6001 2.29003 11.5334L7.05572 9.72721C7.28947 9.63873 7.55226 9.75211 7.64304 9.98111C7.73336 10.2106 7.61717 10.468 7.38342 10.5565L3.71157 11.9483L11.9853 15.0844L20.259 11.9483L16.5871 10.5565C16.3534 10.468 16.2372 10.2106 16.3275 9.98111C16.4183 9.75256 16.6802 9.63917 16.9148 9.72721L21.6805 11.5334C21.8552 11.6001 21.9705 11.7646 21.9705 11.9483C21.9705 12.1319 21.8552 12.2964 21.6805 12.3631L12.1491 15.9759C12.0965 15.996 12.0406 16.0057 11.9853 16.0057Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9853 19.5629C11.9299 19.5629 11.8741 19.5531 11.8214 19.5331L2.29003 15.9203C2.11528 15.8536 2 15.6891 2 15.5054C2 15.3218 2.11528 15.1573 2.29003 15.0906L6.82878 13.3675C7.06298 13.2786 7.32532 13.3924 7.4161 13.6219C7.50642 13.8504 7.39023 14.1079 7.15648 14.1973L3.71111 15.505L11.9853 18.6416L20.2594 15.505L16.814 14.1973C16.5803 14.1079 16.4641 13.8504 16.5544 13.6219C16.6452 13.3924 16.908 13.279 17.1417 13.3675L21.6805 15.0906C21.8552 15.1573 21.9705 15.3218 21.9705 15.5054C21.9705 15.6891 21.8552 15.8536 21.6805 15.9203L12.1491 19.5331C12.0965 19.5531 12.0406 19.5629 11.9853 19.5629Z'
      />
    </Icon>
  )
}

export default PlansIcon
