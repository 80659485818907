import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { isNil, propOr } from 'ramda'
import styled, { css } from 'styled-components'

type Tab = {
  title: string
  key: string
  children: ReactNode
  icon?: any
  counter?: number
  disabled?: boolean
}

type TabsTypes = {
  tabs: Tab[]
}
const Tabs = ({ tabs }: TabsTypes) => {
  const [activeTab, setActiveTab] = useState('')

  const tabsHeaders = useMemo(() => {

    return tabs.map(tab => (
      <TabTitle
        disabled={tab.disabled}
        key={tab.key}
        onClick={tab.disabled ? () => {
        } : () => setActiveTab(tab.key)}
        active={tab.key === activeTab}
      >
        {tab.icon && (
          <IconWrapper active={tab.key === activeTab}>{tab.icon}</IconWrapper>
        )}
        {tab.title}
        {!isNil(tab.counter) && (
          <Counter disabled={tab.disabled} active={tab.key === activeTab}>
            {tab.counter}
          </Counter>
        )}
      </TabTitle>
    ))
  }, [tabs, activeTab])

  const tabContent = useMemo(() => {
    const tab = tabs.find(item => item.key === activeTab)
    return propOr(null, 'children', tab)
  }, [tabs, activeTab])

  useEffect(() => {
    setActiveTab(tabs[0].key)
  }, [tabs])

  return (
    <>
      <TabsWrapper>{tabsHeaders}</TabsWrapper>
      <TabContent>{tabContent}</TabContent>
    </>
  )
}

Tabs.defaultProps = {
  tabs: []
}

export default Tabs

const TabsWrapper = styled.div`
  display: flex;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const TabTitle = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: -1px;
  padding: 0 5px;
  border-bottom: 1px solid transparent;
  font-size: 14px;
  transition: all 0.3s;

  ${({ active }) =>
          active &&
          css`
            color: ${({ theme }) => theme.colors.primary};
            border-color: ${({ theme }) => theme.colors.primary};
          `}

  ${({ disabled }) =>
          disabled &&
          css`
            color: ${({ theme }) => theme.colors.disabled};
            cursor: default;
            border-color: transparent;
          `}
`

const TabContent = styled.div`
  width: 100%;
  padding: 20px 0;
`

const Counter = styled.div`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.trout};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  transition: all 0.3s;

  ${({ active }) =>
          active &&
          css`
            color: ${({ theme }) => theme.colors.primary};
            background-color: ${({ theme }) => theme.colors.copperBg};
          `}

  ${({ disabled }) =>
          disabled &&
          css`
            color: ${({ theme }) => theme.colors.disabled};
          `}
`
const IconWrapper = styled.div`
  ${({ active }) =>
          active &&
          css`
            fill: ${({ theme }) => theme.colors.primary};
          `}
`
