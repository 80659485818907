import { createSelector } from 'reselect'
import { RootState } from 'redux/store'

const authState = (state: RootState) => state.auth

export const selectCurrentUser = createSelector(authState, state => state.user)

export const selectAuthLoading = createSelector(
  authState,
  state => state.loading
)

export const selectStatus = createSelector(
  authState,
  state => state.verificationStatus
)
