import React from 'react'
import { ArrowLeftIcon } from 'assets/icons'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type BackButtonTypes = {
  path: string
  label: string
}

const BackButton = ({ path, label }: BackButtonTypes) => {
  return (
    <Wrapper to={path}>
      <IconWrapper>
        <ArrowLeftIcon />
      </IconWrapper>
      <Label>{label}</Label>
    </Wrapper>
  )
}

BackButton.defaultProps = {
  label: 'Go back'
}

export default BackButton

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 7px;
  width: fit-content;
  color: ${({ theme }) => theme.colors.trout};
  cursor: pointer;
  margin-bottom: 16px;
`

const Label = styled.div`
  font-size: 13px;
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
`
