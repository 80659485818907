import React, { ReactNode } from 'react'
import styled from 'styled-components'

type propsType = {
  title: string | number
  condition?: boolean | string | { value: string; label: string }
  conditionTrue?: string
  conditionFalse?: string
  labelText?: string
  icon?: ReactNode
  iconTrue?: ReactNode
  iconFalse?: ReactNode
  description?: string
  iconFirst?: boolean
}

const InfoWithIcon = ({
  title,
  condition,
  conditionTrue,
  conditionFalse,
  icon,
  iconTrue,
  iconFalse,
  labelText,
  description
}: propsType) => {
  const isObject = typeof condition === 'object'
  const { label, value } = isObject ? condition : { label: '', value: '' }

  return (
    <>
      <ItemInfo>
        <span>{title}</span>
        {isObject ? (
          <ItemInfoValue value={value === 'yes'}>
            {value === 'yes' && icon}
            {label}
          </ItemInfoValue>
        ) : (
          <ItemInfoValue value={condition}>
            {icon}
            {iconTrue}
            {iconFalse}
            {condition ? conditionTrue : conditionFalse}
            {labelText}
          </ItemInfoValue>
        )}
      </ItemInfo>
      {description && <ItemDescription>{description}</ItemDescription>}
    </>
  )
}

export default InfoWithIcon

const ItemInfo = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-size: bolder;
`
const ItemInfoValue = styled.div`
  color: ${({ theme, value }) =>
          value ? theme.colors.copper : theme.colors.lightTrout};
  display: flex;
  align-items: center;
  justify-content: center;
  labelText-transform: capitalize;
  gap: 4px;
`
const ItemDescription = styled.div`
  margin-top: 12px;
  padding: 6px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.lightTrout};
  background-color: ${({ theme }) => theme.colors.copperBg};
`
