import React, { useMemo } from 'react'
import styled from 'styled-components'
import ProgressBar from 'components/atoms/ProgressBar'
import Status from 'components/atoms/Status'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import FaultFormModal from 'modules/forms/faultsForm/FaultFormModal'

import {
  ArrowRightIcon,
  PlusIcon,
  RectifiedFaultsIcon,
  ReportedFaultsIcon
} from 'assets/icons'
import TextButton from 'components/atoms/TextButton'
import theme from 'utils/theme'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { pathOr } from 'ramda'
import { Tooltip } from '@mui/material'

type FormBoxTypes = {
  icon: any
  title: string
  description: string
  progress?: number | null
  onClick?: any
  formKey: string
}

const FormBox = ({
  icon,
  title,
  description,
  progress,
  onClick,
  formKey
}: FormBoxTypes) => {
  const currentUser = useAppSelector(selectCurrentUser)
  // @ts-ignore
  const isFrs = currentUser?.type === 'emergency_service'
  const hasProgressValue = isNotNilOrEmpty(progress)
  // @ts-ignore
  const hasAnyProgress = hasProgressValue && progress > 0
  const isFaultsBox = formKey === 'faults'
  const shouldSeeProgressBar = (hasAnyProgress || isFrs) && !isFaultsBox
  const shouldSeeAddButton = !isFrs && !hasAnyProgress
  const building = useAppSelector(selectCurrentBuilding)
  const faultsReported = building?.activeFaultReportsCount || 0
  // @ts-ignore
  const isBuildingActive = pathOr('', ['buildingStatus', 'value'], building) === 'active'

  const faultsRectified = building?.rectifiedFaultReportsCount || 0

  const buttonLabel = useMemo(() => {
    switch (formKey) {
      case 'plans':
        return 'Add plan'
      case 'wall_design':
        return 'Add wall design'
      default:
        return ''
    }
  }, [formKey])

  const formStatus = useMemo(() => {
    switch (formKey) {
      case 'plans':
        return pathOr('Draft', ['floorPlansStatus', 'label'], building)
      case 'wall_design':
        return pathOr('Draft', ['wallDesignStatus', 'label'], building)
      default:
        return ''
    }
  }, [formKey])

  const getBottomContent = () => {
    switch (true) {
      case shouldSeeProgressBar:
        return <ProgressBar value={progress || 0} />
      case shouldSeeAddButton && !isFaultsBox:
        return (
          <AddReportWrapper>
            {
              isBuildingActive
                ? (
                  <TextButton
                    label={buttonLabel}
                    onClick={onClick}
                    icon={<PlusIcon />}
                  />
                )
                : (
                  <Tooltip title='You have to fill building data first'>
                    <TooltipChildWrapper>
                      <TextButton
                        label={buttonLabel}
                        disabled
                        onClick={onClick}
                        icon={<PlusIcon />}
                      />
                    </TooltipChildWrapper>
                  </Tooltip>
                )
            }

          </AddReportWrapper>
        )
      case shouldSeeAddButton && isFaultsBox:
        return (
          <AddReportWrapper>
            <FaultFormModal disabled={!isBuildingActive} triggerLabel='Add report' />
            <FaultsIconWrapper>
              {/* @ts-ignore */}
              <FaultIcon empty={faultsReported < 1}>
                <ReportedFaultsIcon
                  fill={
                    // @ts-ignore
                    faultsReported > 0
                      ? theme.colors.copper
                      : theme.colors.disabled
                  }
                />
                {faultsReported}
              </FaultIcon>
              {/* @ts-ignore */}
              <FaultIcon empty={faultsRectified < 1}>
                <RectifiedFaultsIcon
                  fill={
                    // @ts-ignore
                    faultsRectified > 0
                      ? theme.colors.darkGrey
                      : theme.colors.disabled
                  }
                />
                {faultsRectified}
              </FaultIcon>
            </FaultsIconWrapper>
          </AddReportWrapper>
        )
      case !shouldSeeAddButton && isFaultsBox:
        return (
          <AddReportWrapper>
            <FaultsIconWrapper>
              {/* @ts-ignore */}
              <FaultIcon empty={faultsReported < 1}>
                <ReportedFaultsIcon
                  fill={
                    // @ts-ignore
                    faultsReported > 0
                      ? theme.colors.copper
                      : theme.colors.disabled
                  }
                />
                {faultsReported}
              </FaultIcon>
              {/* @ts-ignore */}
              <FaultIcon empty={faultsRectified < 1}>
                <RectifiedFaultsIcon
                  fill={
                    // @ts-ignore
                    faultsRectified > 0
                      ? theme.colors.darkGrey
                      : theme.colors.disabled
                  }
                />
                {faultsRectified}
              </FaultIcon>
            </FaultsIconWrapper>
          </AddReportWrapper>
        )
      default:
        return null
    }
  }

  return (
    <Wrapper
      isFrs={isFrs}
      hasAnyProgress={hasAnyProgress}
      onClick={!isFrs && hasAnyProgress ? onClick : () => {
      }}
    >
      <IconWrapper>{icon}</IconWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {hasAnyProgress && (
          <Status status={formStatus} />
        )}
        {getBottomContent()}
      </ContentWrapper>
      {!isFrs && hasAnyProgress && (
        <ArrowIconWrapper>
          <ArrowRightIcon />
        </ArrowIconWrapper>
      )}
    </Wrapper>
  )
}

export default FormBox

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid transparent;
  cursor: ${({ isFrs, hasAnyProgress }) =>
          !isFrs && hasAnyProgress ? 'pointer' : 'default'};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.copperBg};
    border-color: ${({ theme }) => theme.colors.copper} !important;
  }
`

const IconWrapper = styled.div`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.copperBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.copperBg};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const Description = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.trout};
  min-height: 34px;
`

const ArrowIconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
  padding: 5px;
`

const FaultsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 15px;
`
const FaultIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ empty }) => (empty ? theme.colors.disabled : theme.colors.trout)};
`
const AddReportWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

const TooltipChildWrapper = styled.div`
  position: relative;
  z-index: 1;
`
