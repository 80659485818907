// @ts-ignore
import linkedinIcon from 'assets/icons/linkedinIcon.svg'
// @ts-ignore
import twitterIcon from 'assets/icons/twitterIcon.svg'
// @ts-ignore
import instagramIcon from 'assets/icons/instagramIcon.svg'

export const SM_LINKS = {
  linkedin: {
    link: 'https://www.linkedin.com/company/buildingpassport',
    icon: linkedinIcon
  },
  twitter: { link: 'https://twitter.com/Bldng_Pssprt', icon: twitterIcon },
  instagram: {
    link: 'https://www.instagram.com/buildingpassport/',
    icon: instagramIcon
  }
}
