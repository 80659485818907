import React, { ReactNode } from 'react'
import { Collapse } from '@mui/material'
import styled from 'styled-components'
import { ArrowDownIcon } from 'assets/icons'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setActiveStep } from 'redux/slices/formSlice'
import { selectActiveStep } from 'redux/selectors/formSelectors'
import TextButton from 'components/atoms/TextButton'
import FetchIcon from 'assets/icons/FetchIcon'

type FormStepTypes = {
  title: string
  stepKey: string
  children: ReactNode
  action?: {
    label: string
    onClick: any
  }
}

const FormStep = ({ stepKey, title, children, action }: FormStepTypes) => {
  const activeStep = useAppSelector(selectActiveStep)
  const open = activeStep === stepKey
  const dispatch = useAppDispatch()

  const handleOpen = () => {
    dispatch(setActiveStep(stepKey))
  }

  return (
    <Wrapper>
      <Header open={open}>
        <StepTitle>{title}</StepTitle>
        {action && open && (
          <TextButton
            label={action.label}
            onClick={action.onClick}
            fontSize='12px'
            rightIcon={<FetchIcon />}
          />
        )}
        {!open && <ArrowDownIconWrapper onClick={handleOpen} />}
      </Header>
      <Collapse in={open}>
        <StepContent>{children}</StepContent>
      </Collapse>
    </Wrapper>
  )
}

export default FormStep

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  margin-bottom: 20px;
`

const Header = styled.div`
  height: ${({ open }) => (open ? '55px' : '75px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 24px;
`

const StepTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const StepContent = styled.div`
  padding: 24px;
`

const ArrowDownIconWrapper = styled(ArrowDownIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.trout};
`
