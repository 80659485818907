import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { CheckIcon, PlusIcon } from 'assets/icons'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import theme from 'utils/theme'
import Button from 'components/atoms/Button'
import CloseFormModal from 'components/atoms/CloseFormModal'
import BuildingDetailsInForm from 'components/atoms/BuildingDetailsInForm'
import Modal from 'components/atoms/Modal'
import { SectionTitle } from 'components/formComponents'
import { getFormContent } from 'utils/forms'
import { findIndex, keys, pick, propEq } from 'ramda'
import TextButton from 'components/atoms/TextButton'
import FaultsFormStep from 'modules/forms/faultsForm/FaultsFormStep'
import TypeOfEquipmentStep from 'modules/forms/faultsForm/steps/TypeOfEquipmentStep'
import DetailsOfFaultStep from 'modules/forms/faultsForm/steps/DetailsOfFaultStep'
import FaultMitigationStep from 'modules/forms/faultsForm/steps/FaultMitigationStep'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { createFault, updateFault } from 'services/FaultsService'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { getFaults } from 'redux/slices/faultsSlice'
import { getApiErrors } from 'utils/errors'
import toast from 'react-hot-toast'
import { getFaultsFormState } from 'utils/faults'
import qs from 'qs'
import { getBuildingDetails } from 'redux/slices/buildingSlice'

const defaultValues = {
  equipmentTypeId: '',
  equipmentTypeOptionValue: '',
  equipmentTypeFreetext: '',
  details: '',
  location: '',
  repairTimescale: '',
  evacuationStrategyChange: '',
  additionalMeasuresImplemented: null,
  additionalMeasuresDetails: ''
}

const defaultActiveStep = 'type_of_equipment'
const defaultVisibleSteps = ['type_of_equipment']

type FaultFormModalTypes = {
  triggerLabel: string
  fault?: any
  disabled?: boolean
}

const FaultFormModal = ({ triggerLabel, fault, disabled }: FaultFormModalTypes) => {
  const [open, setOpen] = useState(false)
  const [saveAsDraftModalOpen, setSaveAsDraftModalOpen] = useState(false)
  const [finishedSteps, setFinishedSteps] = useState<string[]>([])
  const [visibleSteps, setVisibleSteps] =
    useState<string[]>(defaultVisibleSteps)
  const [activeStep, setActiveStep] = useState(defaultActiveStep)
  const [values, setValues] = useState(defaultValues)
  const dispatch = useAppDispatch()
  const building = useAppSelector(selectCurrentBuilding)
  const isEditForm = isNotNilOrEmpty(fault)

  useEffect(() => {
    if (isNotNilOrEmpty(fault)) {
      setValues(fault)
      const formState = getFaultsFormState(fault)
      setActiveStep(formState.activeStep)
      setFinishedSteps(formState.finishedSteps)
      setVisibleSteps(formState.visibleSteps)
    }
  }, [fault, open])

  const handleClearForm = () => {
    setValues(defaultValues)
    setActiveStep(defaultActiveStep)
    setVisibleSteps(defaultVisibleSteps)
    setFinishedSteps([])
  }

  const handleValuesUpdate = formValues => {
    setValues(prev => ({ ...prev, ...formValues }))
  }

  const formData = getFormContent('faults')
  const formSteps = formData?.steps || []

  const handleChangeStep = (stepName: string) => () => {
    if (visibleSteps.includes(stepName)) {
      setActiveStep(stepName)
    }
  }

  const isVisible = stepName => visibleSteps.includes(stepName)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    handleSaveAsDraftModalClose()
    setOpen(false)
    handleClearForm()
  }

  const handleStepFinish = (stepName: string) => () => {
    if (!finishedSteps.includes(stepName)) {
      setFinishedSteps(prev => [...prev, stepName])
    }

    const index = findIndex(propEq('key', stepName), formSteps)

    if (index !== -1 && isNotNilOrEmpty(formSteps[index + 1])) {
      const nextStepKey = formSteps[index + 1].key
      setActiveStep(nextStepKey)
      if (!visibleSteps.includes(nextStepKey)) {
        setVisibleSteps(prev => [...prev, nextStepKey])
      }
    }
  }

  const handleGoBack = newActiveStep => () => {
    setActiveStep(newActiveStep)
  }

  const isStepVisible = stepName => visibleSteps.includes(stepName)

  const handleSaveAsDraftModalOpen = () => setSaveAsDraftModalOpen(true)
  const handleSaveAsDraftModalClose = () => setSaveAsDraftModalOpen(false)

  const handleFormSubmit = ({
    shouldClose = true,
    toastMessage = 'Fault has been submitted',
    shouldReset = false,
    shouldSubmit = false
  }) => {
    const optionValue = values.equipmentTypeOptionValue
    const valuesKeys = keys(defaultValues)

    const payload = {
      ...pick(valuesKeys, values),
      submit: shouldSubmit,
      equipmentTypeOptionValue: isNilOrEmpty(optionValue)
        ? null
        : typeof optionValue === 'boolean'
        ? optionValue
          ? '1'
          : '0'
        : optionValue,
      buildingId: building.id,
      faultId: fault?.id
    }

    if (isEditForm) {
      updateFault(payload)
        .then(() => {
          const query = {
            limit: 20,
            page: 1,
            filter: { is_rectified: false }
          }
          toast.success(toastMessage)
          dispatch(getFaults({ id: building.id, query: qs.stringify(query) }))
          dispatch(getBuildingDetails({ id: building.id }))
        })
        .catch(e => {
          toast.error(getApiErrors(e))
        })
    } else {
      createFault(payload)
        .then(() => {
          const query = {
            limit: 20,
            page: 1,
            filter: { is_rectified: false }
          }
          toast.success(toastMessage)
          dispatch(getFaults({ id: building.id, query: qs.stringify(query) }))
          dispatch(getBuildingDetails({ id: building.id }))
          shouldClose && handleClose()
          if (shouldReset) {
            handleClose()
            setTimeout(() => {
              handleOpen()
            }, 0)
          }
        })
        .catch(e => {
          toast.error(getApiErrors(e))
        })
    }
  }

  const handleSaveAsDraft = () => {
    handleFormSubmit({
      shouldClose: true,
      toastMessage: 'Fault has been saved as a draft'
    })
  }

  const handleFaultSubmit = () => {
    handleFormSubmit({
      shouldClose: true,
      shouldSubmit: true
    })
  }

  const handleFormSubmitAndStartNew = () => {
    handleFormSubmit({
      shouldClose: false,
      shouldReset: true,
      shouldSubmit: true
    })
  }

  return (
    <>
      {isEditForm ? (
        <Button size='medium' onClick={disabled ? () => {} : handleOpen}>
          {triggerLabel}
        </Button>
      ) : (
        <TextButton
          disabled={disabled}
          label={triggerLabel}
          icon={<PlusIcon />}
          onClick={handleOpen}
        />
      )}
      {open && (
        <>
          <Backdrop onClick={handleClose}>
            <FakeModal onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <ModalTitle>Report fault</ModalTitle>
                <ModalActions>
                  <Button
                    variant='secondary'
                    size='medium'
                    onClick={handleSaveAsDraftModalOpen}
                  >
                    Save Draft & Close
                  </Button>
                  <Divider />
                  <CloseFormModal
                    closeAction={handleClose}
                    onDraftSave={handleSaveAsDraft}
                  />
                </ModalActions>
              </ModalHeader>
              <ModalContent>
                <StepsPanel>
                  <div>
                    <BuildingDetailsInForm />
                    <SectionTitle>Steps</SectionTitle>
                    {isNotNilOrEmpty(formSteps) &&
                      formSteps.map(step => (
                        <StepWrapper
                          key={step.key}
                          isVisible={isStepVisible(step.key)}
                          onClick={handleChangeStep(step.key)}
                        >
                          <StepTitle active={activeStep === step.key}>
                            {step.title}
                            {finishedSteps.includes(step.key) && (
                              <CheckIcon
                                style={{ fill: theme.colors.primary }}
                              />
                            )}
                          </StepTitle>
                        </StepWrapper>
                      ))}
                  </div>
                </StepsPanel>
                <FormContentWrapper>
                  <FormContent>
                    {isVisible('type_of_equipment') && (
                      <FaultsFormStep
                        title='Type of Equipment'
                        open={activeStep === 'type_of_equipment'}
                        onOpen={handleChangeStep('type_of_equipment')}
                      >
                        <TypeOfEquipmentStep
                          onStepFinish={handleStepFinish('type_of_equipment')}
                          onValuesChange={handleValuesUpdate}
                          initialValues={values}
                        />
                      </FaultsFormStep>
                    )}
                    {isVisible('details_of_fault') && (
                      <FaultsFormStep
                        title='Details of Fault'
                        open={activeStep === 'details_of_fault'}
                        onOpen={handleChangeStep('details_of_fault')}
                      >
                        <DetailsOfFaultStep
                          onGoBack={handleGoBack('type_of_equipment')}
                          onStepFinish={handleStepFinish('details_of_fault')}
                          onValuesChange={handleValuesUpdate}
                          initialValues={values}
                        />
                      </FaultsFormStep>
                    )}
                    {isVisible('fault_mitigation') && (
                      <FaultsFormStep
                        title='Fault Mitigation'
                        open={activeStep === 'fault_mitigation'}
                        onOpen={handleChangeStep('fault_mitigation')}
                      >
                        <FaultMitigationStep
                          onGoBack={handleGoBack('details_of_fault')}
                          onStepFinish={handleFaultSubmit}
                          onSaveAndNew={handleFormSubmitAndStartNew}
                          onValuesChange={handleValuesUpdate}
                          initialValues={values}
                          isEditForm={isEditForm}
                        />
                      </FaultsFormStep>
                    )}
                  </FormContent>
                </FormContentWrapper>
              </ModalContent>
            </FakeModal>
          </Backdrop>
          <Modal
            open={saveAsDraftModalOpen}
            title='Save as draft'
            onClose={handleSaveAsDraftModalClose}
          >
            Do you want to save the given data?
            <ButtonsWrapper>
              <Button variant='secondary' onClick={handleSaveAsDraftModalClose}>
                Cancel
              </Button>
              <Button onClick={handleSaveAsDraft}>Save & Close</Button>
            </ButtonsWrapper>
          </Modal>
        </>
      )}
    </>
  )
}

export default FaultFormModal

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
`

const FakeModal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
`

const ModalHeader = styled.div`
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafbfc;
  filter: drop-shadow(0px 0px 4px rgba(101, 118, 184, 0.08))
    drop-shadow(0px 2px 4px rgba(101, 118, 184, 0.12))
    drop-shadow(0px 8px 16px rgba(101, 118, 184, 0.06))
    drop-shadow(0px 1px 2px rgba(101, 118, 184, 0.06));
  border-bottom: 1px solid #dce0f2;
  border-radius: 8px 8px 0 0;
`

const ModalTitle = styled.div`
  font-size: 24px;
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`

const Divider = styled.div`
  height: 32px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.border};
`

const ModalContent = styled.div`
  display: flex;
  height: calc(100% - 80px);
`

const StepsPanel = styled.div`
  min-width: 280px;
  max-width: 280px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 16px;
  height: 100%;
  border-radius: 0 0 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StepTitle = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.background};
      font-weight: bold;
    `}
`

const FormContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
`

const FormContent = styled.div`
  width: 640px;
  margin: 0 auto;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  gap: 10px;

  button {
    width: fit-content;
  }
`

const StepWrapper = styled.div`
  width: 100%;

  ${({ isVisible }) =>
    isVisible &&
    css`
      font-weight: bold;
      cursor: pointer;
    `}
`
