import { EmailIcon, PhoneIcon } from 'assets/icons'
import { propOr } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'

type personPropsType = {
  person: {
    firstName: string
    lastName: string
    email: string
    phoneNumber?: string
  }
  withSmallFont?: boolean
}

const ResponsiblePerson = ({ person, withSmallFont }: personPropsType) => {
  return (
    <PersonWrapper key={person.email}>
      {(isNotNilOrEmpty(person.firstName) ||
        isNotNilOrEmpty(person.lastName)) && (
        <PersonDetailsName
          withSmallFont={withSmallFont}
        >{`${person.firstName} ${person.lastName}`}</PersonDetailsName>
      )}
      <PersonDetailsItem withSmallFont={withSmallFont}>
        <IconWrapper>
          <MailIcon />
          <a href={`mailto:${person.email}`}>
            {' '}
            {propOr('-', 'email', person)}{' '}
          </a>
        </IconWrapper>
      </PersonDetailsItem>
      <PersonDetailsItem withSmallFont={withSmallFont}>
        {isNotNilOrEmpty(person.phoneNumber) && (
          <IconWrapper>
            <PhoneIcon /> {person.phoneNumber}
          </IconWrapper>
        )}
      </PersonDetailsItem>
    </PersonWrapper>
  )
}

export default ResponsiblePerson

const PersonWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
const PersonDetailsName = styled.div`
  font-weight: bold;
  ${({ withSmallFont }) => withSmallFont && `font-size: 13px;`}
  margin-bottom: 4px;
`
const PersonDetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.trout};

  ${({ withSmallFont }) => withSmallFont && `font-size: 13px;`}
  a {
    color: ${({ theme }) => theme.colors.primary};
    ${({ withSmallFont }) => withSmallFont && `font-size: 13px;`}
  }
`
const MailIcon = styled(EmailIcon)`
  font-size: 14px !important;
`

const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
  display: flex;
  align-items: center;
  gap: 6px;
`
