import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const uploadedInfo = building => {
  return {
    isAnythingCombustible: pathOr(false, ['isAnythingCombustible'], building),
    isExternalMaterialCombustible: pathOr(
      { value: 'no', label: 'No' },
      ['isExternalMaterialCombustible'],
      building
    ),
    externalMaterialCombustibilityDetails: pathOr(
      '',
      ['externalMaterialCombustibilityDetails'],
      building
    ),
    isTraditionalMasonry: pathOr(false, ['isTraditionalMasonry'], building),
    hasOuterCladding: pathOr(false, ['hasOuterCladding'], building),
    externalFacingMaterials: pathOr([], ['externalFacingMaterials'], building),
    otherWallFeatures: pathOr(null, ['otherWallFeatures'], building),
    externalWallSystems: pathOr([], ['externalWallSystems'], building),
    wallFeaturesOrAttachments: pathOr(
      [],
      ['wallFeaturesOrAttachments'],
      building
    ),
    wallFeaturesAdditionalInfo: pathOr(
      '',
      ['wallFeaturesAdditionalInfo'],
      building
    ),
    fireRiskAssessmentStatus: pathOr(
      { label: '', value: '' },
      ['fireRiskAssessmentStatus'],
      building
    ),
    fireSpreadRiskLevel: pathOr(
      { label: '', value: '' },
      ['fireSpreadRiskLevel'],
      building
    ),
    fireRiskMitigationAdditionalMeasures: pathOr(
      [],
      ['fireRiskMitigationAdditionalMeasures'],
      building
    ),
    areAdditionalMeasuresUnnecessary: pathOr(
      false,
      ['areAdditionalMeasuresUnnecessary'],
      building
    ),
    otherAdditionalMeasuresTaken: pathOr(
      '',
      ['otherAdditionalMeasuresTaken'],
      building
    )
  }
}

export const getBuildingAddress = building => {
  if (building) {
    const { street, city, postalCode } = building
    return [street, city, postalCode]
      .filter(v => isNotNilOrEmpty(v))
      .join(', ')
  } else {
    return ''
  }
}

