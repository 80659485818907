import React from 'react'
import styled from 'styled-components'
import { getFloorsIntervals } from 'utils/buildings'

const FloorBadges = ({ floorNumbers }) => {

  if (floorNumbers) {
    const sorted = floorNumbers
      .map(num => num === 'G' ? 0 : num === 'LG' ? -0.5 : Number(num))
      .sort((a, b) => b - a)

    const intervals = getFloorsIntervals(sorted)

    return (
      <BadgesWrapper>
        {intervals.map(badge => <Badge key={badge}>{badge}</Badge>)}
      </BadgesWrapper>
    )
  } else {
    return null
  }
}

export default FloorBadges

const BadgesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`

const Badge = styled.div`
  height: 20px;
  border-radius: 4px;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.haiti};
  background-color: #dfe4f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: 3px 5px 3px 0;
`
