import React from 'react'
import styled from 'styled-components'

type propsType = {
  children: React.ReactNode
  size?: 'small' | 'large'
  onClick: any
}

const DecorativeButton = ({ children, onClick, size }: propsType) => {
  return (
    <StyledButton onClick={onClick} size={size}>
      <ButtonContent>{children}</ButtonContent>
    </StyledButton>
  )
}

export default DecorativeButton

const StyledButton = styled.button`
  display: flex;
  padding: ${({ size }) =>
  size === 'large' ? '34px' : size === 'small' ? '8px' : '20px'}
    30px;
  min-width: 90px;
  background-color: ${({ theme }) => theme.colors.haiti};
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 100px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.teal};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.darkTeal};
  }
`
const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: #fff;
`
