import React, { useState } from 'react'
import styled from 'styled-components'
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons'
import { Collapse } from '@mui/material'

const FiltersSection = ({ title, counter, children }) => {
  const [open, setOpen] = useState(true)

  const toggleOpen = () => setOpen(prev => !prev)

  return (
    <Wrapper onClick={toggleOpen}>
      <Header>
        <TitleWrapper>
          <Title>{title}</Title>
          {counter !== 0 && <Counter>{counter}</Counter>}
        </TitleWrapper>
        {
          open
           ? <ArrowDownIcon />
           : <ArrowUpIcon />
        }
      </Header>
      <Collapse in={open}>
        <Content onClick={e => e.stopPropagation()}>
          {children}
        </Content>
      </Collapse>
    </Wrapper>
  )
}

export default FiltersSection

const Wrapper = styled.div`
  width: 327px;
  overflow: hidden;
  &:not(:last-of-type) {
    margin-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 15px;
  cursor: pointer;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const Counter = styled.div`
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.trout};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  padding-bottom: 15px;
`
