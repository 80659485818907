import React from 'react'
import Icon from './Icon'

export const DoubleCheckIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='12'
      height='7'
      viewBox='0 0 12 7'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.39647 0.646447C9.59173 0.841709 9.59173 1.15829 9.39647 1.35355L6.49999 4.25003C6.30473 4.44529 5.98815 4.44529 5.79289 4.25003C5.59762 4.05477 5.59762 3.73818 5.79289 3.54292L8.68936 0.646447C8.88462 0.451184 9.20121 0.451184 9.39647 0.646447Z'
      
      />
      <path
        d='M1.04293 3.54295C1.23819 3.34769 1.55478 3.34769 1.75004 3.54295L4.25004 6.04295C4.4453 6.23822 4.4453 6.5548 4.25004 6.75006C4.05478 6.94532 3.73819 6.94532 3.54293 6.75006L1.04293 4.25006C0.847669 4.0548 0.847669 3.73822 1.04293 3.54295Z'
      
      />
      <path
        d='M11.8536 1.14651C12.0489 1.34177 12.0489 1.65835 11.8536 1.85361L6.85359 6.85361C6.65833 7.04888 6.34175 7.04888 6.14648 6.85361L3.64648 4.35361C3.45122 4.15835 3.45122 3.84177 3.64648 3.64651C3.84175 3.45125 4.15833 3.45125 4.35359 3.64651L6.50004 5.79295L11.1465 1.14651C11.3417 0.951246 11.6583 0.951246 11.8536 1.14651Z'
      
      />
    </Icon>
  )
}

export default DoubleCheckIcon
