import React from 'react'
import styled from 'styled-components'

type propsType = {
  icon: any
  title: string
  content: string
}

const ContentItem = ({ icon, title, content }: propsType) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Wrapper>
  )
}

export default ContentItem

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;
  max-width: 500px;
`
const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(#fae4de 50%, #fcf4f2 30%);
  border-radius: 50%;
`
const Title = styled.h3`
  font-size: 20px;
  font-weight: bolder;
  margin: 20px 0 8px;
`
const Content = styled.div`
  font-size: 18px;
`
