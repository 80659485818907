import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectFinishedSteps, selectFormSteps, selectFormVisibleSteps } from 'redux/selectors/formSelectors'
import ProgressBar from 'components/atoms/ProgressBar'
import styled from 'styled-components'
import FormStep from 'modules/forms/components/FormStep'
import TraditionalMasonryConstructionStep from 'modules/forms/wallsForm/steps/TraditionalMasonryConstructionStep'
import ExternalWallSystemsStep from 'modules/forms/wallsForm/steps/ExternalWallSystemsStep'
import WallFeaturesAndAttachments from 'modules/forms/wallsForm/steps/WallFeaturesAndAttachments'
import RiskAndMitigationStep from 'modules/forms/wallsForm/steps/RiskAndMitigationStep'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import { getExternalFacingMaterials, getFormsDictionary } from 'redux/slices/dictionarySlice'

const WallsForm = () => {
  const steps = useAppSelector(selectFormSteps)
  const finishedSteps = useAppSelector(selectFinishedSteps)
  const visibleSteps = useAppSelector(selectFormVisibleSteps)
  const dispatch = useAppDispatch()
  const isVisible = stepName => visibleSteps.includes(stepName)

  const progressBarCount = (finishedSteps.length / steps.length) * 100

  useEffect(() => {
    dispatch(getExternalFacingMaterials())
    dispatch(getFormsDictionary())
  }, [])

  return (
    <div>
      <ProgressBarWrapper>
        <ProgressBar value={progressBarCount} />
      </ProgressBarWrapper>
      <ExpandableInfo
        teal
        title='Historically, the external walls of high rise buildings were generally uniform in both construction and
          appearance.'
      >
        <div>
          However, it is now common for modern residential buildings to incorporate different external walls systems as
          part of their design.
          To inform their operational response, the fire and rescue service need to be aware of the risk of external
          fire spread and how that can vary across the elevations of a building. In order to do this, the Responsible
          Person should provide information on the different materials and construction techniques present in their
          building.
          The responsible person will need to consider the walls types discretely and provide details of each
          separately. This form allows up to 4 different external wall designs to be recorded.
        </div>
      </ExpandableInfo>
      {
        isVisible('traditional_masonry_construction') && (
          <FormStep
            stepKey='traditional_masonry_construction'
            title='Traditional Masonry Construction'
          >
            <TraditionalMasonryConstructionStep />
          </FormStep>
        )
      }
      {
        isVisible('external_wall_system') && (
          <FormStep
            stepKey='external_wall_system'
            title='External Wall System(s)'
          >
            <ExternalWallSystemsStep />
          </FormStep>
        )
      }
      {
        isVisible('wall_features_and_attachments') && (
          <FormStep
            stepKey='wall_features_and_attachments'
            title='Wall Features and Attachments'
          >
            <WallFeaturesAndAttachments />
          </FormStep>
        )
      }
      {
        isVisible('risk_and_mitigation') && (
          <FormStep
            stepKey='risk_and_mitigation'
            title='Risk and Mitigation'
          >
            <RiskAndMitigationStep />
          </FormStep>
        )
      }
    </div>
  )
}

export default WallsForm

const ProgressBarWrapper = styled.div`
  width: 80%;
  margin: 0 auto 20px;
`
