import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fetchAuthUser,
  LoginPayloadTypes,
  loginUser,
  logoutUser,
  registerUser,
  RegisterPayloadTypes,
  verifyEmail
} from 'services/AuthService'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { getApiToken } from 'services/FilesService'
import { KEYS } from 'utils/keys'

export type AuthStateTypes = {
  user: object | null
  verificationStatus?: string
  loading: boolean
}

const initialState: AuthStateTypes = {
  user: null,
  verificationStatus: '',
  loading: false
}

export const login = createAsyncThunk(
  'auth/loginUser',
  async (payload: LoginPayloadTypes) => {
    return await loginUser(payload)
      .then(async resp => {
        const authToken = await getApiToken()
        window.localStorage.setItem(KEYS.token, authToken.data.data.token)
        return resp.data.data
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }
)

export const logout = createAsyncThunk('auth/logoutUser', async () => {
  return await logoutUser().catch(err => {
    toast.error(getApiErrors(err))
  })
})

export const fetchUser = createAsyncThunk('auth/fetchAuthUser', async () => {
  return await fetchAuthUser().then(resp => resp.data.data)
})

export const signUp = createAsyncThunk(
  'auth/registerUser',
  async (payload: RegisterPayloadTypes) => {
    return await registerUser(payload)
      .then(resp => resp.data)
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }
)

export const verify = createAsyncThunk(
  'auth/verifyEmail',
  async (payload: string) => {
    return await verifyEmail(payload)
      .then(resp => {
        return resp.data
      })
      .catch(e => toast.error(getApiErrors(e)))
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // login
    builder.addCase(login.pending, state => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload
      state.loading = false
    })
    builder.addCase(login.rejected, state => {
      state.user = initialState.user
      state.loading = false
    })

    // logout
    builder.addCase(logout.fulfilled, state => {
      state.user = initialState.user
    })

    // fetch auth user
    builder.addCase(fetchUser.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload
      state.loading = false
    })
    builder.addCase(fetchUser.rejected, state => {
      state.user = {}
      state.loading = false
    })

    // sign up
    builder.addCase(signUp.pending, state => {
      state.loading = true
    })
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.user = action.payload
      state.loading = false
    })
    builder.addCase(signUp.rejected, state => {
      state.user = {}
      state.loading = false
    })

    // verify email
    builder.addCase(verify.pending, state => {
      state.loading = true
    })
    builder.addCase(verify.fulfilled, state => {
      state.verificationStatus = 'success'
      state.loading = false
    })
    builder.addCase(verify.rejected, state => {
      state.verificationStatus = 'failure'
      state.loading = false
    })
  }
})

export const authReducer = authSlice.reducer
