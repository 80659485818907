import React from 'react'
import { Navigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { isEmpty, isNil } from 'ramda'
import Loading from 'components/atoms/Loading'

const PrivateRoute = ({ children }) => {
  const currentUser = useAppSelector(selectCurrentUser)

  if (isNil(currentUser)) {
    return (
      <Loading />
    )
  } else {
    if (isEmpty(currentUser)) {
      return <Navigate to={PATHS.login} replace />
    }
    return children
  }
}

export default PrivateRoute
