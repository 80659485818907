import APIService from './APIService'
import { omit } from 'ramda'

const api = APIService()

export const fetchFaults = payload => api.get(`/building/${payload.id}/fault-reports${payload.query ? `?${payload.query}` : ''}`)
export const createFault = payload =>
  api.post(`/building/${payload.buildingId}/fault-reports`, omit(['buildingId', 'faultId'], payload))
export const updateFault = payload =>
  api.patch(`/building/${payload.buildingId}/fault-reports/${payload.faultId}`, omit(['buildingId', 'faultId'], payload))
export const removeFault = payload => api.delete(`/building/${payload.buildingId}/fault-reports/${payload.id}`)

export const createOrUpdateRectification = payload =>
  api.patch(
    `/building/${payload.buildingId}/fault-reports/${payload.faultId}/rectification`,
    omit(['buildingId', 'faultId'], payload)
  )