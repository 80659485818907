import React from 'react'
import theme from 'utils/theme'
import Icon from './Icon'

export const RectifiedFaultsIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.19101 2.03487C4.99914 1.64892 5.90704 1.52299 6.78971 1.67443C7.67237 1.82587 8.48638 2.24724 9.11964 2.88049C9.7529 3.51375 10.1743 4.32776 10.3257 5.21043C10.4638 6.01533 10.3713 6.84122 10.0612 7.59329L13.9007 11.4328C14.2279 11.76 14.4118 12.2038 14.4118 12.6666C14.4118 13.1294 14.2279 13.5732 13.9007 13.9004C13.5735 14.2277 13.1296 14.4115 12.6669 14.4115C12.2041 14.4115 11.7603 14.2277 11.433 13.9004L7.59354 10.061C6.84146 10.371 6.01557 10.4636 5.21067 10.3255C4.328 10.174 3.514 9.75266 2.88074 9.1194C2.24748 8.48614 1.82612 7.67213 1.67468 6.78946C1.52324 5.9068 1.64916 4.99889 2.03512 4.19076C2.08138 4.09389 2.1718 4.0255 2.2776 4.00735C2.38341 3.9892 2.49145 4.02354 2.56736 4.09945L4.80383 6.33592H6.33617V4.80359L4.09969 2.56711C4.02378 2.4912 3.98944 2.38317 4.00759 2.27736C4.02575 2.17155 4.09413 2.08114 4.19101 2.03487Z'
        fill={props.fill || theme.colors.grey}
      />
    </Icon>
  )
}

export default RectifiedFaultsIcon
