import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'

type OptionTypes = {
  label: string
  value: string
}

type OptionSelectTypes = {
  title: string
  onChange: any
  options: OptionTypes[]
  error?: string
  initialValue?: string
}

const OptionSelect = ({ title, onChange, options, error, initialValue }: OptionSelectTypes) => {
  const [value, setValue] = useState(initialValue || '')

  const handleSelect = value => () => {
    setValue(value)
  }

  useEffect(() => {
    isNotNilOrEmpty(value) && onChange(value)
  }, [value])

  return (
    <>
      <Wrapper>
        <TextWrapper>
          <Title>{title}</Title>
        </TextWrapper>
        <Options>
          {
            options && options.map(option => (
              <Option
                key={option.value}
                active={value === option.value}
                onClick={handleSelect(option.value)}
              >
                <Radio active={value === option.value}>
                  <span className='dot' />
                </Radio>
                {option.label}
              </Option>
            ))
          }
        </Options>
      </Wrapper>
      {
        error && (
          <Error>
            {error}
          </Error>
        )
      }
    </>
  )
}

export default OptionSelect

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`

const Options = styled.div`
  display: flex;
  gap: 6px;
  border-radius: 8px;
  flex-wrap: nowrap;

  & > div {
    flex: 1;
  }
`

const Option = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 42px;
  white-space: nowrap;
  cursor: pointer;

  ${({ active }) => active && css`
    box-shadow: 0 0 4px rgba(209, 73, 37, 0.08), 0 2px 4px rgba(209, 73, 37, 0.12), 0 8px 16px rgba(209, 73, 37, 0.06), 0 1px 2px rgba(209, 73, 37, 0.06);
    border-color: ${({ theme }) => theme.colors.primary};
  `}
`

const Radio = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.copper};;
    border-radius: 50%;
    opacity: 0;
  }

  ${({ active }) => active && css`
    border-color: ${({ theme }) => theme.colors.copper};

    .dot {
      opacity: 1;
    }
  `}
`

const TextWrapper = styled.div`
  flex: 1;
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
