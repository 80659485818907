import React from 'react'
import styled from 'styled-components'
import InfoWithIcon from 'modules/buildingDetails/components/InfoWithIcon'
import { isNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { FlameIcon } from 'assets/icons'

type propsType = {
  item: {
    title?: string
    list?: { id: string; name: string }[]
    isCombustible: boolean | { value: string; label: string }
    description?: string
  }
}

const BuildingUploadedInfoItem = ({ item }: propsType) => {
  const { title, list, isCombustible, description } = item

  return (
    <>
      <ItemList>
        {isNilOrEmpty(list) ? (
          <ItemTitle color={isNilOrEmpty(list)}>
            <>
              <span>{title}</span>
              <span>None</span>
            </>
          </ItemTitle>
        ) : (
          <ItemTitle>{title}</ItemTitle>
        )}
        {list && (
          <StyledUl>
            {list.map(listItem => (
              <li key={listItem.id}>{propOr('', 'name', listItem)}</li>
            ))}
          </StyledUl>
        )}
      </ItemList>
      <InfoWithIcon
        title='Likely to ignite and spread fire easily'
        icon={
          <IconWrapper>
            <FlameIcon />
          </IconWrapper>
        }
        condition={isCombustible}
        conditionTrue='Yes'
        conditionFalse='No'
        description={description}
      />
    </>
  )
}

export default BuildingUploadedInfoItem

const ItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: bolder;
  color: ${({ theme, color }) => color && theme.colors.lightTrout};
`
const ItemList = styled.div`
  margin-top: 12px;
`
const StyledUl = styled.ul`
  list-style-type: none;

  li {
    display: list-item !important;
    color: ${({ theme }) => theme.colors.lightTrout};
    margin-top: 6px;
  }

  li:before {
    content: '• ';
  }
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.copper};
`
