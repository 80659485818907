import ContentItem from './ContentItem'
import React from 'react'
import styled from 'styled-components'

import { JoinIcon, FilesIcon, ShareIcon } from 'assets/icons'

const ExplanationPanel = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>How it works</HeaderTitle>
        <HeaderContent>Together we can make our buildings safer.</HeaderContent>
      </HeaderWrapper>
      <ContentWrapper>
        <ContentItem
          icon={<JoinIcon />}
          title='Set up free Building Passport account'
          content='Join the community that actively cares about building security and keep a historic record of your buildings.'
        />
        <ContentItem
          icon={<FilesIcon />}
          title='Provide important building information'
          content='Be compliant with Fire Safety (England) Regulations. With less paper work and with much ease.'
        />
        <ContentItem
          icon={<ShareIcon />}
          title='Share information with FRS'
          content='Securely transfer information to the Fire Service. It will be used by operational firefighters during an incident.'
        />
      </ContentWrapper>
    </Wrapper>
  )
}

export default ExplanationPanel

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  background-color: #fff;
`
const HeaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 64px;
  width: 800px;
`
const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.copper};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bolder;
`
const HeaderContent = styled.h2`
  font-size: 36px;
  margin: 12px 0;
`
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 100px;
`
