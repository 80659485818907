import styled, { css } from 'styled-components'

export const SectionTitle = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.haiti};
  text-transform: uppercase;
  gap: 4px;
`

export const FormSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border};
  margin: 24px 0;

  ${({ smallMargin }) =>
    smallMargin &&
    css`
      margin: 12px 0;
    `}
`

export const InputDescription = styled.div`
  margin-bottom: 20px;
  font-size: 13px;

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`
