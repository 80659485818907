import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const fetchUserBps = () => api.get('/building-passport?limit=100')
export const addNewBuilding = payload => api.post('/building', payload)
export const updateBuilding = payload => api.patch(`/building/${payload.id}`, dissoc('id', payload))
export const fetchUserBuildings = payload => api.get(`/building?${payload}`)
export const fetchBuildingDetails = payload => api.get(`/building/${payload.id}`)
export const removeBuilding = payload => api.delete(`/building/${payload.id}`)
