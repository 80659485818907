import React, { useEffect } from 'react'
import FormStep from 'modules/forms/components/FormStep'
import BuildingInformationStep from 'modules/forms/addBuildingForm/steps/BuildingInformationStep'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getFrsStations } from 'redux/slices/dictionarySlice'
import ResponsiblePersonsStep from 'modules/forms/addBuildingForm/steps/ResponsiblePersonsStep'
import {
  selectFinishedSteps,
  selectFormSteps,
  selectFormVisibleSteps
} from 'redux/selectors/formSelectors'
import ProgressBar from 'components/atoms/ProgressBar'
import styled from 'styled-components'

const EditBuildingForm = () => {
  const dispatch = useAppDispatch()
  const visibleSteps = useAppSelector(selectFormVisibleSteps)
  const steps = useAppSelector(selectFormSteps)
  const finishedSteps = useAppSelector(selectFinishedSteps)

  const isVisible = stepName => visibleSteps.includes(stepName)

  useEffect(() => {
    dispatch(getFrsStations())
  }, [])

  const progressBarCount = (finishedSteps.length / steps.length) * 100

  return (
    <div>
      <ProgressBarWrapper>
        <ProgressBar value={progressBarCount} />
      </ProgressBarWrapper>
      {
        isVisible('building_information') && (
          <FormStep
            stepKey='building_information'
            title='Building Information'
          >
            <BuildingInformationStep />
          </FormStep>
        )
      }
      {
        isVisible('responsible_persons') && (
          <FormStep
            stepKey='responsible_persons'
            title='Responsible Person(s)'
          >
            <ResponsiblePersonsStep />
          </FormStep>
        )
      }
    </div>
  )
}

export default EditBuildingForm

const ProgressBarWrapper = styled.div`
  width: 80%;
  margin: 0 auto 20px;
`
