import React, { ReactNode } from 'react'
import { Collapse } from '@mui/material'
import styled from 'styled-components'
import { ArrowDownIcon } from 'assets/icons'

type FaultsFormStepTypes = {
  title: string
  children: ReactNode
  open: boolean
  onOpen: any
}

const FaultsFormStep = ({ onOpen, title, children, open }: FaultsFormStepTypes) => {
  return (
    <Wrapper>
      <Header open={open}>
        <StepTitle>{title}</StepTitle>
        {!open && <ArrowDownIconWrapper onClick={onOpen} />}
      </Header>
      <Collapse in={open}>
        <StepContent>{children}</StepContent>
      </Collapse>
    </Wrapper>
  )
}

export default FaultsFormStep

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  margin-bottom: 20px;
`

const Header = styled.div`
  height: ${({ open }) => (open ? '55px' : '75px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 24px;
`

const StepTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const StepContent = styled.div`
  padding: 24px;
`

const ArrowDownIconWrapper = styled(ArrowDownIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.trout};
`
