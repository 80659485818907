import React from 'react'
import theme from 'utils/theme'
import Icon from './Icon'

export const LevelBarsIcon = (props: { [x: string]: any}) => {
 const { level } = props

  return (
    <Icon
      width='12'
      height='12'
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      {...props}
    >
      <path
        d='M10.3323 1.83268C10.3323 1.18835 9.81001 0.666016 9.16567 0.666016C8.52134 0.666016 7.99901 1.18835 7.99901 1.83268V11.612C7.99901 12.2563 8.52134 12.7786 9.16567 12.7786C9.81001 12.7786 10.3323 12.2563 10.3323 11.612V1.83268Z'
        fill={level === 'high' ? theme.colors.copper : theme.colors.grey}
      />
      <path
        d='M5.16567 3.9259C5.81001 3.9259 6.33234 4.44824 6.33234 5.09257V11.6121C6.33234 12.2564 5.81001 12.7788 5.16567 12.7788C4.52134 12.7788 3.99901 12.2564 3.99901 11.6121V5.09257C3.99901 4.44824 4.52134 3.9259 5.16567 3.9259Z'
        fill={level === 'medium' || level === 'high' ? theme.colors.copper : theme.colors.grey}
      />
      <path
        d='M1.16569 7.18576C1.81002 7.18576 2.33236 7.7081 2.33236 8.35243V11.6122C2.33236 12.2565 1.81002 12.7789 1.16569 12.7789C0.521358 12.7789 -0.000976562 12.2565 -0.000976562 11.6122V8.35243C-0.000976562 7.7081 0.521358 7.18576 1.16569 7.18576Z'
        fill={level ? theme.colors.copper : theme.colors.grey}
      />
    </Icon>
  )
}

export default LevelBarsIcon
