import React, { useEffect, useCallback } from 'react'
import Table from 'components/atoms/Table'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useSearchParams, useLocation } from 'react-router-dom'
import {
  selectIsFetchingBuildings,
  selectUserBuildings,
  selectUserBuildingsPagination
} from 'redux/selectors/buildingSelectors'
import { getUserBuildings } from 'redux/slices/buildingSlice'
import styled from 'styled-components'
import { head, propOr } from 'ramda'
import ProgressBar from 'components/atoms/ProgressBar'
import Status from 'components/atoms/Status'
import Loading from 'components/atoms/Loading'
import { debounce } from 'lodash'
import { isNotNilOrEmpty } from 'utils/ramda'
import qs from 'qs'

const FrsBuildingsTable = () => {
  const dispatch = useAppDispatch()
  const buildings = useAppSelector(selectUserBuildings)
  const pagination = useAppSelector(selectUserBuildingsPagination)
  const isFetching = useAppSelector(selectIsFetchingBuildings)
  const [, setSearchParams] = useSearchParams()
  const { search } = useLocation()

  useEffect(() => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    // @ts-ignore
    if (pagination?.totalPages < parsedQuery.page) {
      setSearchParams(qs.stringify({
        ...parsedQuery,
        page: 1
      }))
    }
  }, [pagination])

  const fetchUserBuildings = useCallback(
    debounce(searchParams =>
        dispatch(getUserBuildings(searchParams)),
      500
    ), [])

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      fetchUserBuildings(search)
    } else {
      const query = qs.stringify({
        sort: '-updated_at',
        limit: 20,
        page: 1
      })
      setSearchParams(query)
    }
  }, [search])

  const headers = [
    { children: 'Name' },
    { children: 'Address' },
    { children: 'Postcode' },
    { children: 'Responsible Person' },
    { children: 'FRS Info' },
    { children: 'Plans' },
    { children: 'Wall Design' },
    { children: 'Faults' }
  ]

  const rows = buildings.map(row => {
    const responsiblePerson = head(propOr([], 'responsiblePersons', row))
    const rp = responsiblePerson
      ? // @ts-ignore
      `${responsiblePerson.firstName} ${responsiblePerson.lastName}`
      : '---'
    const floorsCompletionPercentage: number = propOr(
      '',
      'floorsCompletionPercentage',
      row
    )
    const wallDesignCompletionPercentage: number = propOr(
      '',
      'wallDesignCompletionPercentage',
      row
    )

    return {
      redirectPath: `/buildings/${row.id}`,
      cells: [
        { children: row.name },
        { children: row.street },
        { children: row.postalCode },
        { children: <ResponsiblePersonName>{rp}</ResponsiblePersonName> },
        { children: <Status status='in_progress' /> },
        {
          children: (
            <ProgressBarWrapper>
              <ProgressBar value={floorsCompletionPercentage} />
            </ProgressBarWrapper>
          )
        },
        {
          children: (
            <ProgressBarWrapper>
              <ProgressBar value={wallDesignCompletionPercentage} />
            </ProgressBarWrapper>
          )
        },
        { children: row?.activeFaultReportsCount || 0 }
      ]
    }
  })
  const handlePageChange = (_, page) => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    setSearchParams(qs.stringify(
      { ...parsedQuery, page }
    ))
  }

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <Table
          emptyState='No buildings have been added in this area'
          headers={headers}
          rows={rows}
          pagination={pagination}
          onPageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default FrsBuildingsTable

const ProgressBarWrapper = styled.div`
  width: 50px;
`

const ResponsiblePersonName = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`
