import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Landing from 'pages/Landing'
import Login from 'pages/Login'
import Register from 'pages/Register'
import NotFound from 'pages/NotFound'
import TopNav from 'modules/layout/TopNav'
import { PATHS } from 'utils/paths'
import AppLayout from 'modules/layout/AppLayout'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { fetchUser } from 'redux/slices/authSlice'
import { fetchAuthUser } from 'services/AuthService'
import PrivateRoute from 'components/PrivateRoute'
import MyBuildings from 'pages/MyBuildings'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import BuildingDetails from 'pages/BuildingDetails'
import RegisterConfirmation from 'pages/RegisterConfirmation'
import EmailConfirmation from 'pages/EmailConfirmation'
import FrsBuildingsList from 'pages/FrsBuildingsList'
import Terms from 'pages/Terms'
import { clearActiveForm } from 'redux/slices/formSlice'
import PasswordResetRequest from 'pages/PasswordResetRequest'
import PasswordReset from 'pages/PasswordReset'

const App = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const redirectPaths = [
    PATHS.buildingsList,
    PATHS.buildingDetails,
  ]

  const authPaths = [PATHS.login, PATHS.register]
  const currentUser = useAppSelector(selectCurrentUser)
  const isLoggedIn = isNotNilOrEmpty(currentUser)
  // @ts-ignore
  const isFrs = currentUser?.type === 'emergency_service'

  const handleAuthUserFetch = async () => {
    const result = await dispatch(fetchUser())

    if (!result.payload) {
      if (redirectPaths.includes(pathname)) {
        dispatch(clearActiveForm())
        navigate(PATHS.login)
      }
    }
  }

  useEffect(() => {
    if (authPaths.includes(pathname) && isLoggedIn) {
      navigate(PATHS.buildingsList)
    }
  }, [pathname, isLoggedIn])

  useEffect(() => {
    handleAuthUserFetch()
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        const path = window.location.pathname
        fetchAuthUser().catch(() => {
          if (redirectPaths.includes(path)) {
            navigate(PATHS.login)
          }
        })
      }
    })
  }, [])

  return (
    <AppLayout>
      <TopNav />
      <Routes>
        <Route path={PATHS.home} element={<Landing />} />
        <Route path={PATHS.homeWithSlug} element={<Landing />} />
        <Route path={PATHS.login} element={<Login />} />
        <Route path={PATHS.register} element={<Register />} />
        <Route
          path={PATHS.terms}
          element={
            <Terms url='https://www.iubenda.com/terms-and-conditions/11279900' />
          }
        />
        <Route
          path={PATHS.cookies}
          element={
            <Terms url='https://www.iubenda.com/privacy-policy/11279900/cookie-policy' />
          }
        />
        <Route
          path={PATHS.privacy}
          element={
            <Terms url='https://www.iubenda.com/privacy-policy/11279900' />
          }
        />
        <Route
          path={PATHS.registerConfirm}
          element={<RegisterConfirmation />}
        />
        <Route path={PATHS.emailConfirmation} element={<EmailConfirmation />} />
        <Route path={PATHS.forgottenPassword} element={<PasswordResetRequest />} />
        <Route path={PATHS.passwordReset} element={<PasswordReset />} />
        <Route
          path={PATHS.buildingsList}
          element={
            <PrivateRoute>
              {isFrs ? <FrsBuildingsList /> : <MyBuildings />}
            </PrivateRoute>
          }
        />
        <Route
          path={PATHS.buildingDetails}
          element={
            <PrivateRoute>
              <BuildingDetails />
            </PrivateRoute>
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </AppLayout>
  )
}

export default App
