import React, { useEffect, useState } from 'react'
import BuildingInfo from 'modules/buildingDetails/BuildingInfo'
import BackButton from 'components/atoms/BackButton'
import { PATHS } from 'utils/paths'
import styled from 'styled-components'
import FaultsList from 'modules/buildingDetails/FaultsList'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { getBuildingDetails } from 'redux/slices/buildingSlice'
import Plans from 'modules/buildingDetails/Plans'
import BuildingUploadedInformation from 'modules/buildingDetails/BuildingUploadedInformation'
import Loading from 'components/atoms/Loading'
import { selectIsFetchingBuildingDetails } from 'redux/selectors/buildingSelectors'
import { getFaultsDictionary, getFileTypes } from 'redux/slices/dictionarySlice'
import ThanksMessageModal from 'modules/forms/components/ThanksMessageModal'

const BuildingDetails = () => {
  const isFetching = useAppSelector(selectIsFetchingBuildingDetails)
  const [thanksModalOpen, setThanksModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    // @ts-ignore
    dispatch(getBuildingDetails({ id }))
  }, [id])

  useEffect(() => {
    dispatch(getFileTypes())
    dispatch(getFaultsDictionary())
  }, [])

  useEffect(() => {
    if (searchParams.get('modal') === 'true') {
      setThanksModalOpen(true)
    }
  }, [searchParams])

  return isFetching ? (
    <Loading />
  ) : (
    <PageWrapper>
      <ThanksMessageModal
        open={thanksModalOpen}
        onClose={() => setThanksModalOpen(false)}
        mode='buildingPlans'
      />
      <BuildingInfoWrapper>
        <BackButton path={PATHS.buildingsList} />
        <BuildingInfo />
        <Plans />
        <FaultsList />
      </BuildingInfoWrapper>
      <UploadedInfoWrapper>
        <BuildingUploadedInformation />
      </UploadedInfoWrapper>
    </PageWrapper>
  )
}

export default BuildingDetails

const PageWrapper = styled.div`
  display: flex;
  padding: 0 418px 0 58px;
  width: 100%;
`

const BuildingInfoWrapper = styled.div`
  width: 100%;
  padding: 20px 0 16px;
`

const UploadedInfoWrapper = styled.div`
  width: 360px;
  background-color: #fff;
  padding: 0 18px;
  padding-top: calc(${({ theme }) => theme.dimensions.topNavHeight} + 24px);
  padding-bottom: 24px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;

  &:hover {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`
