import { createSelector } from 'reselect'
import { RootState } from 'redux/store'
import { isNotNilOrEmpty } from 'utils/ramda'

const dictionaryState = (state: RootState) => state.dictionary

export const selectFrsStations = createSelector(
  dictionaryState,
  state => state.frs
)

export const selectExternalFacingMaterials = createSelector(
  dictionaryState,
  state => state.externalFacingMaterials
)

export const selectFormsDictionary = createSelector(
  dictionaryState,
  state => state.formsDictionary
)

export const selectFaultsDictionary = createSelector(
  dictionaryState,
  state => state.faultsDictionary
)

export const selectIsCheckingEmail = createSelector(
  dictionaryState,
  state => state.isCheckingUser
)

export const selectBuildingFilters = createSelector(
  dictionaryState,
  state => state.buildingFilters
)

export const selectBuildingPlanFileTypeId = createSelector(
  dictionaryState,
  state => {
      const types = state.fileTypes
        ?.filter(type => type.name === 'Info Exchange Building plan')
        ?.map(type => type.id)
      return isNotNilOrEmpty(types[0]) ? types[0] : ''
  }
)

export const selectFloorPlanFileTypeId = createSelector(
  dictionaryState,
  state => {
      const types = state.fileTypes
        ?.filter(type => type.name === 'Info Exchange Floor plan')
        ?.map(type => type.id)
      return isNotNilOrEmpty(types[0]) ? types[0] : ''
  }
)
