import React from 'react'
import Icon from './Icon'

export const ReportedFaultsIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 14L8 1.87537L15 14H1ZM7.66667 9.33337L7.66667 6.66671H8.33333L8.33333 9.33337L8.33333 9.33337H7.66667L7.66667 9.33337ZM8.33333 12V10.6667H7.66667V12H8.33333Z'
      />
    </Icon>
  )
}

export default ReportedFaultsIcon
