import React from 'react'
import Icon from './Icon'

export const PlusIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='11'
      height='12'
      viewBox='0 0 11 12'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6.16125 0.838135H5.1713V5.50504H0.504395V6.49499H5.1713V11.1619H6.16125V6.49499H10.8282V5.50504H6.16125V0.838135Z' />
    </Icon>
  )
}

export default PlusIcon
