import React from 'react'
import theme from 'utils/theme'
import Icon from './Icon'

export const ExclamationIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_3142_51686)'>
        1
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.99984 0.666626C3.94975 0.666626 0.666504 3.94987 0.666504 7.99996C0.666504 12.05 3.94975 15.3333 7.99984 15.3333C12.0499 15.3333 15.3332 12.05 15.3332 7.99996C15.3332 3.94987 12.0499 0.666626 7.99984 0.666626ZM8.6665 5.33329C8.6665 4.9651 8.36803 4.66663 7.99984 4.66663C7.63165 4.66663 7.33317 4.9651 7.33317 5.33329V7.99996C7.33317 8.36815 7.63165 8.66663 7.99984 8.66663C8.36803 8.66663 8.6665 8.36815 8.6665 7.99996V5.33329ZM7.99984 9.99996C7.63165 9.99996 7.33317 10.2984 7.33317 10.6666C7.33317 11.0348 7.63165 11.3333 7.99984 11.3333H8.0065C8.37469 11.3333 8.67317 11.0348 8.67317 10.6666C8.67317 10.2984 8.37469 9.99996 8.0065 9.99996H7.99984Z'
          fill={props.fillBg || theme.colors.copper}
        />
      </g>
      <defs>
        <clipPath id='clip0_3142_51686'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default ExclamationIcon
