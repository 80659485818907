import React from 'react'
import styled from 'styled-components'

const AppLayout = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default AppLayout

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.dimensions.topNavHeight};
`
