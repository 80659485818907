import { createSelector } from 'reselect'
import { RootState } from 'redux/store'

const formState = (state: RootState) => state.buildings

export const selectUserBuildings = createSelector(
  formState,
  state => state.userBuildings
)

export const selectIsAddingBuilding = createSelector(
  formState,
  state => state.isAddingBuilding
)

export const selectUserBuildingsPagination = createSelector(
  formState,
  state => state.userBuildingsPagination
)

export const selectCurrentBuilding = createSelector(
  formState,
  state => state.currentBuilding
)

export const selectCurrentBuildingFiles = createSelector(
  formState,
  state => state.currentBuilding?.files || []
)

export const selectIsUploading = createSelector(
  formState,
  state => state.isUploading
)

export const selectIsFetchingBuildings = createSelector(
  formState,
  state => state.isFetchingBuildings
)

export const selectIsFetchingBuildingDetails = createSelector(
  formState,
  state => state.isFetchingBuildingDetails
)

export const selectBuildingFormsState = createSelector(
  formState,
  state => state.currentBuilding?.buildingFormsState
)

