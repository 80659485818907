import React, { useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { useForm } from 'react-hook-form'
import Input from 'components/atoms/Input'
import { string, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import { selectAuthLoading } from 'redux/selectors/authSelectors'
import { requestResetPassword } from 'services/AuthService'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import AuthFormWrapper from 'modules/auth/AuthFormWrapper'
import Button from 'components/atoms/Button'

const schema = object({
  email: string().required('Email is required').email('Incorrect email address')
})

const PasswordResetRequest = () => {
  const isLoading = useAppSelector(selectAuthLoading)
  const [isSent, setIsSent] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })

  // const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleResetPasswordRequest = async data => {
    setIsSent(true)
    await requestResetPassword(data)
      .then(res => {
        toast.success('Email with reset link has been sent.')
        setTimeout(() => navigate(PATHS.login), 2000)
      })
      .catch(e => toast.error(getApiErrors(e)))
  }

  return (
    <AuthFormWrapper>
      {' '}
      <FormWrapper>
        <HeaderText>
          Forgotten your&nbsp;
          <span>Password?</span>
        </HeaderText>
        <HeaderExtraText>
          Enter the e-mail address associated with your account, and we’ll email
          you a link to reset your password.
        </HeaderExtraText>
        <form onSubmit={handleSubmit(handleResetPasswordRequest)}>
          <Input
            register={register}
            name='email'
            error={errors.email?.message}
            label='Email'
          />
          <ButtonWrapper>
            <Button
              isLoading={isLoading}
              disabled={isLoading || isSent}
              type='submit'
            >
              Send reset link
            </Button>
          </ButtonWrapper>
        </form>
      </FormWrapper>
    </AuthFormWrapper>
  )
}

export default PasswordResetRequest

const FormWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  padding: 35px 70px;
  background-color: #fff;
  margin-bottom: ${({ theme }) => theme.dimensions.topNavHeight};
`

const ButtonWrapper = styled.div`
  margin-top: 30px;

  button {
    width: 100%;
  }
`

const HeaderText = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;

  span {
    font-weight: bold;
  }
`
const HeaderExtraText = styled.div`
  margin-bottom: 20px;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.copper};
  }
`
