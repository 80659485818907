import React, { useState } from 'react'
import styled from 'styled-components'
import ContentPanel from 'components/atoms/ContentPanel'
import { SectionTitle } from 'components/formComponents'
import { Collapse } from '@mui/material'
import {
  selectBuildingPlanFileTypeId,
  selectFloorPlanFileTypeId
} from 'redux/selectors/dictionarySelectors'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentBuildingFiles } from 'redux/selectors/buildingSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import FloorFilters from 'modules/buildingDetails/components/FloorFilters'
import PlanThumbnail from 'modules/buildingDetails/components/PlanThumbnail'
import { ArrowUpIcon, ArrowDownIcon } from 'assets/icons'

const Plans = () => {
  const [open, setOpen] = useState(false)
  const floorPlanTypeId = useAppSelector(selectFloorPlanFileTypeId)
  const buildingPlanTypeId = useAppSelector(selectBuildingPlanFileTypeId)
  const currentBuildingFiles = useAppSelector(selectCurrentBuildingFiles)
  const [activeFilter, setActiveFilter] = useState('all')

  const floorPlans = currentBuildingFiles.filter(
    file => file.fileTypeId === floorPlanTypeId
  )
  const buildingPlans = currentBuildingFiles.filter(
    file => file.fileTypeId === buildingPlanTypeId
  )

  const numberOfBuildingPlans = buildingPlans.length

  const filteredFloorPlans =
    activeFilter === 'all'
      ? floorPlans
      : floorPlans.filter(plan => plan.floorNumbers?.includes(activeFilter))

  const numberOfFloorPlans = filteredFloorPlans.length
  const hasAnyPlans =
    isNotNilOrEmpty(floorPlans) || isNotNilOrEmpty(buildingPlans)

  const toggleOpen = () => (hasAnyPlans ? setOpen(prev => !prev) : () => {})

  return (
    <ContentPanel>
      <Header hasAnyPlans={hasAnyPlans} onClick={toggleOpen}>
        <PanelTitle>Plans</PanelTitle>
        {hasAnyPlans ? (
          <>
            {open ? (
              <IconWrapper>
                <ArrowUpIcon />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <ArrowDownIcon />
              </IconWrapper>
            )}
          </>
        ) : (
          <PlansEmptyState>No plans have been submitted yet</PlansEmptyState>
        )}
      </Header>
      <Collapse in={open}>
        <Separator />
        <PlansContent>
          <Section>
            {isNotNilOrEmpty(floorPlans) && (
              <FloorFilters onChange={setActiveFilter} />
            )}
            <SectionTitle>{`Floor plans (${numberOfFloorPlans})`}</SectionTitle>
            <PlansList>
              {isNotNilOrEmpty(filteredFloorPlans) ? (
                filteredFloorPlans.map(plan => (
                  <PlanThumbnail key={plan.id} plan={plan} />
                ))
              ) : (
                <EmptyState>
                  {activeFilter === 'all' ? (
                    <div>No Floor Plans have been uploaded</div>
                  ) : (
                    <div>There are no plans for this floor</div>
                  )}
                </EmptyState>
              )}
              {}
            </PlansList>
          </Section>
          <Section>
            <SectionTitle>{`Building plans (${numberOfBuildingPlans})`}</SectionTitle>
            <PlansList>
              {isNotNilOrEmpty(buildingPlans) ? (
                buildingPlans.map(plan => (
                  <PlanThumbnail key={plan.id} plan={plan} isBuildingPlan />
                ))
              ) : (
                <EmptyState>No Building Plan has been uploaded</EmptyState>
              )}
            </PlansList>
          </Section>
        </PlansContent>
      </Collapse>
    </ContentPanel>
  )
}

export default Plans

const Header = styled.div`
  display: flex;
  align-items: ${({ hasAnyPlans }) => (hasAnyPlans ? 'center' : 'flex-start')};
  justify-content: space-between;
  flex-direction: ${({ hasAnyPlans }) => (hasAnyPlans ? 'default' : 'column')};
  cursor: ${({ hasAnyPlans }) => (hasAnyPlans ? 'pointer' : 'default')};
`

const PlansContent = styled.div`
  overflow: hidden;
  padding: 10px 5px;
`

const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  &:last-of-type {
    margin-bottom: 10px;
  }
`

const PlansList = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

const PanelTitle = styled.div`
  font-weight: bold;
`
const PlansEmptyState = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.trout};
  margin-top: 4px;
`

const EmptyState = styled.div`
  display: flex;
  align-items: center;
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
`
const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border};
  margin: 17px 0;
`
