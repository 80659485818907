export const fileToUploadPath = (universalPath, addExtension) => {
  return universalPath
      .replace(/.*com\/(.*)\//, '')
      .replace(/%2F/gi, '/')
    + `${addExtension ? '.pdf' : ''}`
}

export const thumbnailToUploadPath = (universalPath, addExtension) => {
  return universalPath
      .replace(/.*com\/(.*)\//, '')
      .replace(/%2F/gi, '/')
    + `${addExtension ? '.png' : ''}`
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
export const convertBytes = (x) => {
  let l = 0
  let n = parseInt(x, 10) || 0

  while (n >= 1024 && ++l) {
    n = n / 1024
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
}
