import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useSearchParams, useLocation } from 'react-router-dom'
import { selectUserBuildingsPagination } from 'redux/selectors/buildingSelectors'
import { getFrsStations } from 'redux/slices/dictionarySlice'
import {
  selectFrsStations,
  selectBuildingFilters
} from 'redux/selectors/dictionarySelectors'
import styled from 'styled-components'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { dissoc, propOr, pathOr, keys, equals } from 'ramda'
import { useForm } from 'react-hook-form'
import Select from 'components/atoms/Select'
import { SectionTitle } from 'components/formComponents'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import FrsBuildingsTableFilters from 'modules/myBuildings/components/FrsBuildingsTableFilters'
import FrsBuildingsTable from 'modules/myBuildings/components/FrsBuildingsTable'
import Input from 'components/atoms/Input'
import qs from 'qs'
import { isNotNilOrEmpty } from 'utils/ramda'
import { SearchIcon, CloseIcon } from 'assets/icons'
import ChosenFilter from 'modules/myBuildings/components/filters/ChosenFilter'
import { startCase } from 'lodash'

const defaultValues = {
  sort: '-updated_at',
  search: ''
}

const FrsBuildingsList = () => {
  const { watch, setValue, control, register } = useForm({ defaultValues })
  const dispatch = useAppDispatch()
  const pagination = useAppSelector(selectUserBuildingsPagination)
  const frsStations = useAppSelector(selectFrsStations)
  const currentUser = useAppSelector(selectCurrentUser)
  // @ts-ignore
  const userDomain = currentUser.email?.replace(/.*@/, '')
  const userFrsStation = frsStations.find(station => {
    return station.domain === userDomain
  })
  const [hasChanged, setHasChanged] = useState(false)
  const [lastValues, setLastValues] = useState({})

  const buildingFilters = useAppSelector(selectBuildingFilters)
  const valuesOfExternalFilters = propOr(
    {},
    'externalFacingMaterials',
    buildingFilters
  )
  const valuesOfFaultFilters = propOr(
    {},
    'faultReportEquipmentTypes',
    buildingFilters
  )

  const [, setSearchParams] = useSearchParams()
  const { search } = useLocation()

  const handleClearAll = () => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const query = qs.stringify({
      ...parsedQuery,
      filter: {}
    })
    setSearchParams(query)
  }

  const usersChosenFilters = useMemo(() => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const filters: {} = propOr({}, 'filter', parsedQuery)
    // @ts-ignore
    const filterWithoutSearch = dissoc('search', filters)
    const nameOfChosenFilters = keys(filterWithoutSearch)

    const valueFilters = (value, key) => {
      if (key === 'external_facing_materials') {
        const getObjectForExternalFilter = value =>
          Object.values(
            // @ts-ignore
            valuesOfExternalFilters
          ).find(
            // @ts-ignore
            obj => obj.id === value
          )
        return value.split(',').map(item => {
          const getObjectForItem = getObjectForExternalFilter(item)
          // @ts-ignore
          return getObjectForItem?.name
        }).join(', ')
      } else if (key === 'fault_report_equipment_types') {
        const getObjectForExternalFilter = value =>
          Object.values(
            // @ts-ignore
            valuesOfFaultFilters
          ).find(
            // @ts-ignore
            obj => obj.id === value
          )
        return value.split(',').map(item => {
          const getObjectForItem = getObjectForExternalFilter(item)
          // @ts-ignore
          return getObjectForItem?.name
        }).join(', ')
      } else {
        return value.split(',').map(item => {
          if (item === '1') {
            return 'Yes'
          } else if (item === '0') {
            return 'No'
          } else {
            return startCase(item)
          }
        }).join(', ')
      }
    }
    if (isNotNilOrEmpty(filterWithoutSearch)) {
      const nameOfChosenFilter = Object.entries(filterWithoutSearch).map(
        ([key, value], index) => {
          if (index === nameOfChosenFilters.length - 1) {
            return (
              <React.Fragment key={`${key}-${index}`}>
                <ChosenFilter
                  key={`${key}-${index}`}
                  label={`${startCase(key)}: ${valueFilters(value, key)}`}
                />
                <IconWrapper onClick={handleClearAll}>
                  <CloseIcon width='20px' height='20px' />
                  <TextWrapper>CLEAR ALL</TextWrapper>
                </IconWrapper>
              </React.Fragment>
            )
          } else {
            return (
              <ChosenFilter
                key={`${key}-${index}`}
                label={`${startCase(key)}: ${valueFilters(value, key)}`}
              />
            )
          }
        }
      )
      return (
        <UsersFiltersWrapper>
          <ChosenFiltersContainer>{nameOfChosenFilter}</ChosenFiltersContainer>
        </UsersFiltersWrapper>
      )
    }
    return null
  }, [search, valuesOfExternalFilters, valuesOfFaultFilters])

  const values = watch()

  const handleParamsChange = v => {
    const sorting = v.sort
    const searching = v.search

    const currentParsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const filter = propOr({}, 'filter', currentParsedQuery)

    const filterPayload = isNotNilOrEmpty(searching)
      ? {
        // @ts-ignore
        ...filter,
        search: searching
      }
      : // @ts-ignore
      dissoc('search', filter)

    const query = qs.stringify({
      ...currentParsedQuery,
      filter: filterPayload,
      sort: sorting
    })

    setLastValues(v)

    setSearchParams(query)
  }

  useEffect(() => {
    const shouldChange = !equals(values, lastValues)
    if (equals(values, defaultValues)) {
      if (hasChanged) {
        shouldChange && handleParamsChange(values)
      }
    } else {
      shouldChange && handleParamsChange(values)
      setHasChanged(true)
    }
  }, [values])

  useEffect(() => {
    dispatch(getFrsStations())
  }, [])

  useEffect(() => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const searchPhraseFromQuery = pathOr('', ['filter', 'search'], parsedQuery)
    // @ts-ignore
    setValue('search', searchPhraseFromQuery)
  }, [])

  return (
    <Wrapper>
      <Header>
        <PageTitle>Buildings in the area</PageTitle>
        <FrsName>{userFrsStation?.name}</FrsName>
      </Header>
      <FiltersContainer>
        <UsersChosenFiltersWrapper>
          {usersChosenFilters}
        </UsersChosenFiltersWrapper>
        <SearchWrapper>
          <Input
            register={register}
            name='search'
            placeholder='Search'
            leftIcon={<SearchIcon />}
            value={values.search}
          />
        </SearchWrapper>
      </FiltersContainer>
      <TableDetailsWrapper>
        <div>{propOr(0, 'itemsCount', pagination)} building(s) found</div>
        <SortInputWrapper>
          <SectionTitle>Sort by:</SectionTitle>
          <Select
            control={control}
            name='sort'
            options={[
              {
                label: (
                  <Label>
                    Building name <ArrowUp />
                  </Label>
                ),
                value: 'name'
              },
              {
                label: (
                  <Label>
                    Building name <ArrowDown />
                  </Label>
                ),
                value: '-name'
              },
              {
                label: (
                  <Label>
                    UPRN <ArrowUp />
                  </Label>
                ),
                value: 'uprn'
              },
              {
                label: (
                  <Label>
                    UPRN <ArrowDown />
                  </Label>
                ),
                value: '-uprn'
              },
              {
                label: (
                  <Label>
                    City <ArrowUp />
                  </Label>
                ),
                value: 'city'
              },
              {
                label: (
                  <Label>
                    City <ArrowDown />
                  </Label>
                ),
                value: '-city'
              },
              {
                label: (
                  <Label>
                    Update date <ArrowUp />
                  </Label>
                ),
                value: 'updated_at'
              },
              {
                label: (
                  <Label>
                    Update date <ArrowDown />
                  </Label>
                ),
                value: '-updated_at'
              }
            ]}
          />
        </SortInputWrapper>
      </TableDetailsWrapper>
      <FrsBuildingsTable />
      <FrsBuildingsTableFilters />
    </Wrapper>
  )
}

export default FrsBuildingsList

const Wrapper = styled.div`
  padding: 20px 136px 20px 72px;
  position: relative;
`

const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  gap: 10px;

  button {
    width: 110px;
  }
`

const PageTitle = styled.div`
  font-size: 36px;
  font-weight: lighter;
`

const FrsName = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.trout};
`

const TableDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.trout};
  margin-bottom: 10px;
`

const SortInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ArrowUp = styled(ArrowUpwardIcon)`
  color: ${({ theme }) => theme.colors.trout};
  font-size: 13px !important;
`

const ArrowDown = styled(ArrowDownwardIcon)`
  color: ${({ theme }) => theme.colors.trout};
  font-size: 14px !important;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.copper};
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.copper};
`
const TextWrapper = styled.div`
  width: 100px;
`

const UsersFiltersWrapper = styled.div`
  display: flex;
  gap: 10px;
`
const SearchWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const ChosenFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 900px;
`
const UsersChosenFiltersWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`
const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
