import React, { useEffect, useState } from 'react'
import Input from 'components/atoms/Input'
import {
  FormSeparator,
  InputDescription,
  SectionTitle
} from 'components/formComponents'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import styled, { css } from 'styled-components'
import { checkUserEmail } from 'redux/slices/dictionarySlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectIsCheckingEmail } from 'redux/selectors/dictionarySelectors'
import { CircularProgress } from '@mui/material'
import { isEmpty, isNil } from 'ramda'
import toast from 'react-hot-toast'
import { addFinishedFormStep, validateEmail } from 'utils/forms'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import {
  addBuilding,
  editBuilding,
  getUserBuildings
} from 'redux/slices/buildingSlice'
import { clearActiveForm } from 'redux/slices/formSlice'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  selectCurrentBuilding,
} from 'redux/selectors/buildingSelectors'
import { useNavigate, createSearchParams } from 'react-router-dom'

// import qs from 'qs'

const schema = object({
  email: string()
    .required('Email address is required')
    .email('Incorrect email address'),
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  phoneNumber: string()
    .nullable()
    .test(
      'match',
      'Phone number is incorrect',
      // @ts-ignore
      value => !value || /^\d*$/.test(value)
    )
    .test(
      'max',
      'Phone number should not be longer than 11 characters',
      // @ts-ignore
      value => !value || value.length <= 11
    )
    .test(
      'min',
      'Phone number should be at least 10 characters',
      // @ts-ignore
      value => !value || value.length >= 10
    )
})

const ResponsiblePersonForm = ({
  onSubmit,
  onEdit,
  onTrigger,
  triggerSubmit,
  added,
  stepSubmit,
  formsCount,
  onRemove,
  values
}) => {
  const [editMode, setEditMode] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [lastTrigger, setLastTrigger] = useState(0)
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const isEditForm = isNotNilOrEmpty(currentBuilding)
  const navigate = useNavigate()

  useEffect(() => {
    if (isNotNilOrEmpty(values)) {
      setSubmitted(true)
      setEditMode(false)
    }
  }, [values])

  const defaultValues = isNotNilOrEmpty(values)
    ? values
    : {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
      }

  const dispatch = useAppDispatch()
  const isCheckingEmail = useAppSelector(selectIsCheckingEmail)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const formValues = useAppSelector(selectActiveFormValues)

  const handleRedirectToBuildings = latestBuildingId => {
    navigate({
      pathname: `/buildings/${latestBuildingId}`,
      search: createSearchParams({ modal: 'true' }).toString()
    })
  }

  const openThanksModal = latestBuildingId => {
    dispatch(clearActiveForm())
    const query = '?limit=100&page=1&sort=-updated_at'
    dispatch(getUserBuildings(query))
    // getLatestBuildingId()
    handleRedirectToBuildings(latestBuildingId)
    // setThanksModalOpen(true)
  }

  const handleStepSubmit = async data => {
    const isOnTheList = isNotNilOrEmpty(
      added.find(person => person.email === data.email)
    )
    const payload = {
      ...formValues,
      responsiblePersons: isOnTheList ? added : [...added, data],
      buildingFormsState: addFinishedFormStep(
        'edit_building',
        'responsible_persons',
        formValues?.buildingFormsState || []
      )
    }
    const result = await dispatch(addBuilding(payload))
    if (isNotNilOrEmpty(result.payload)) {
      const latestBuildingId = result.payload?.id
      openThanksModal(latestBuildingId)
      const query = '?limit=100&page=1&sort=-updated_at'
      dispatch(getUserBuildings(query))
      dispatch(clearActiveForm())
    }
  }

  const handleEditStepSubmit = async data => {
    const isOnTheList = isNotNilOrEmpty(
      added.find(person => person.email === data.email)
    )
    const payload = {
      ...formValues,
      responsiblePersons: isOnTheList ? added : [...added, data],
      buildingFormsState: addFinishedFormStep(
        'edit_building',
        'responsible_persons',
        formValues?.buildingFormsState || []
      )
    }
    const result = await dispatch(
      editBuilding({
        id: currentBuilding.id,
        ...payload
      })
    )

    if (isNotNilOrEmpty(result.payload)) {
      dispatch(clearActiveForm())
    }
  }

  useEffect(() => {
    if (triggerSubmit !== 0 && triggerSubmit !== lastTrigger) {
      if (submitted && !stepSubmit) {
        handleSubmit(data => {
          setEditMode(false)
          onEdit(data)
          setLastTrigger(triggerSubmit)
        })()
      } else if (!submitted && !stepSubmit) {
        handleSubmit(data => {
          setEditMode(false)
          setSubmitted(true)
          onSubmit(data)
          setLastTrigger(triggerSubmit)
        })()
      } else if (stepSubmit) {
        handleSubmit(data => {
          isEditForm ? handleEditStepSubmit(data) : handleStepSubmit(data)
        })()
      }
    }
  }, [triggerSubmit])

  const emailValue = watch('email')
  const firstNameValue = watch('firstName')
  const lastNameValue = watch('lastName')
  const phoneNumberValue = watch('phoneNumber')

  const handleUserEmailCheck = async () => {
    const codedEmail = emailValue.replace('+', '%2B').toLowerCase()
    const result = await dispatch(checkUserEmail({ email: codedEmail }))
    if (!isNil(result.payload)) {
      if (isEmpty(result.payload)) {
        toast('Email not found')
      } else {
        const user = result.payload[0]
        const { firstName, lastName, phoneNumber } = user
        setValue('firstName', firstName)
        setValue('lastName', lastName)
        setValue('phoneNumber', phoneNumber)
      }
    }
  }

  return editMode ? (
    <Form submitted={submitted}>
      {formsCount > 1 && (
        <SectionTitleWrapper>
          <SectionTitle>Add responsible person</SectionTitle>
          <RemoveIcon onClick={onRemove} />
        </SectionTitleWrapper>
      )}
      <CheckInputWrapper>
        <Input
          register={register}
          label='Email'
          name='email'
          error={errors.email?.message}
        />
        <button
          disabled={isCheckingEmail || !validateEmail(emailValue)}
          className='button'
          type='button'
          onClick={handleUserEmailCheck}
        >
          {isCheckingEmail ? <CircularProgress size={20} /> : <>Check</>}
        </button>
      </CheckInputWrapper>
      <InputDescription>
        Type the email first - the fields below will be autocompleted if it
        exists in our data base
      </InputDescription>
      <FormSeparator />
      <Row>
        <Input
          register={register}
          label='First Name'
          name='firstName'
          error={errors.firstName?.message}
        />
        <Input
          register={register}
          label='Last name'
          name='lastName'
          error={errors.lastName?.message}
        />
      </Row>
      <Input
        register={register}
        name='phoneNumber'
        error={errors.phoneNumber?.message}
        label='Phone Number'
        leftIcon={<PhonePrefixWrapper>+44</PhonePrefixWrapper>}
      />
      {submitted && (
        <>
          <SaveButtonWrapper>
            <SaveButton
              onClick={e => {
                e.preventDefault()
                if (isNilOrEmpty(errors)) {
                  onTrigger()
                }
              }}
              disabled={isNotNilOrEmpty(errors)}
            >
              Save
            </SaveButton>
          </SaveButtonWrapper>
        </>
      )}
    </Form>
  ) : (
    <>
      <AddedPersonBox>
        <AddedPersonName>
          <div>
            {firstNameValue} {lastNameValue}
          </div>
          <AddedPersonIcons>
            <EditIcon onClick={() => setEditMode(true)} />
            {formsCount > 1 && <RemoveIcon onClick={onRemove} />}
          </AddedPersonIcons>
        </AddedPersonName>
        <PersonDetailsItem>
          <MailIcon />
          <a href={`mailto:${emailValue}`}>{emailValue}</a>
        </PersonDetailsItem>
        {phoneNumberValue && (
          <PersonDetailsItem>
            <PhoneIcon />
            {phoneNumberValue}
          </PersonDetailsItem>
        )}
      </AddedPersonBox>
    </>
  )
}

export default ResponsiblePersonForm

const Row = styled.div`
  display: flex;
  gap: 24px;

  & > * {
    flex: 1;
  }
`

const CheckInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  & > div:first-of-type {
    width: 100%;
  }

  .button {
    width: 70px;
    height: 44px;
    background-color: ${({ theme }) => theme.colors.buttonSecondary};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 23.5px;
    border-left: none;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabledBg};
      color: ${({ theme }) => theme.colors.disabled};
    }

    &:hover:enabled {
      background-color: ${({ theme }) =>
        theme.colors.buttonSecondaryHover};
    }
    &:active:enabled {
      background-color: ${({ theme }) => theme.colors.disabled};
    }
  }

  input {
    border-radius: 8px 0 0 8px;
  }
`

const AddedPersonBox = styled.div`
  background-color: ${({ theme }) => theme.colors.copperBg};
  padding: 12px;
  font-size: 13px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
`

const AddedPersonName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PersonDetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.trout};

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const EditIcon = styled(ModeEditOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

const PhoneIcon = styled(LocalPhoneOutlinedIcon)`
  color: ${({ theme }) => theme.colors.trout};
  font-size: 14px !important;
`

const MailIcon = styled(AlternateEmailOutlinedIcon)`
  color: ${({ theme }) => theme.colors.trout};
  font-size: 14px !important;
`

const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const SaveButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.copper};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
  background-color: transparent;
`

const Form = styled.form`
  position: relative;
  ${({ submitted }) =>
    submitted &&
    css`
      background-color: ${({ theme }) => theme.colors.copperBg};
      padding: 20px;
      margin-bottom: 20px;
    `}
`

const RemoveIcon = styled(DeleteOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

const AddedPersonIcons = styled.div`
  display: flex;
  gap: 10px;
`

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PhonePrefixWrapper = styled.div`
  color: ${({ theme }) => theme.colors.disabled};
`
