import ExplanationPanel from 'modules/landing/explanation/ExplanationPanel'
import Footer from 'modules/layout/Footer'
import InformationPanel from 'modules/landing/information/InformationPanel'
import Invitation from 'modules/landing/Invitation'
import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import background from 'assets/background.svg'

const Landing = () => {
  return (
    <Wrapper>
      <Background>
        <img src={background} alt='background' />
      </Background>
      <Invitation />
      <ExplanationPanel />
      <InformationPanel />
      <Footer />
    </Wrapper>
  )
}

export default Landing

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
`
const Background = styled.div`
  background: linear-gradient(#fff, ${({ theme }) => theme.colors.background});
  padding-top: 100px;
  z-index: -1;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 630px;
  }
`
