import APIService from './APIService'
import axios from 'axios'
import { getApiErrors } from 'utils/errors'
import toast from 'react-hot-toast'
import { KEYS } from 'utils/keys'
import FormData from 'form-data'

const api = APIService()

export const getApiToken = () => api.get('/internal-api-token')

export const uploadFile = ({
  file,
  buildingId
}) => {
  const apiToken = window.localStorage.getItem(KEYS.token) || ''

  const fd = new FormData()
  fd.append('uploadedImage', file)
  fd.append('objectID', buildingId)
  fd.append('token', apiToken)
  fd.append('fileType', 'building_file')
  fd.append('private', 'true')
  fd.append('isFloorPlan', 'true')

  // @ts-ignore
  return axios.post(process.env.REACT_APP_FILE_SERVICE_URL, fd)
    .then(resp => resp.data)
    .catch(error => {
      toast.error(getApiErrors(error))
    })
}

