import { isNotNilOrEmpty } from 'utils/ramda'

export const getFaultsFormState = values => {
  const {
    equipmentTypeId,
    equipmentTypeOptionValue,
    equipmentTypeFreetext,
    details,
    location,
    repairTimescale
  } = values
  const firstStepFinished = isNotNilOrEmpty(equipmentTypeId) && (isNotNilOrEmpty(equipmentTypeOptionValue) || isNotNilOrEmpty(equipmentTypeFreetext))
  const secondStepFinished = isNotNilOrEmpty(details) && isNotNilOrEmpty(location) && isNotNilOrEmpty(repairTimescale)

  switch (true) {
    case secondStepFinished:
      return {
        activeStep: 'fault_mitigation',
        visibleSteps: ['type_of_equipment', 'details_of_fault', 'fault_mitigation'],
        finishedSteps: ['type_of_equipment', 'details_of_fault']
      }
    case firstStepFinished:
      return {
        activeStep: 'details_of_fault',
        visibleSteps: ['type_of_equipment', 'details_of_fault'],
        finishedSteps: ['type_of_equipment']
      }
    default:
      return {
        activeStep: 'type_of_equipment',
        visibleSteps: ['type_of_equipment'],
        finishedSteps: []
      }
  }
}
