import APIService from './APIService'

const api = APIService()

export type LoginPayloadTypes = {
  email: string
  password: string
}

export type RegisterPayloadTypes = {
  email: string
  password: string
  type: string
  source: string
  disclaimer: boolean
  updates: boolean
  first_name?: string
  last_name?: string
  phone_number?: string
  company?: string
  street?: string
  postal_code?: string
  city?: string
  referral?: string
  accreditations?: any // json
}
export const loginUser = (payload: LoginPayloadTypes) => {
  // @ts-ignore
  const APIBareUrl = process.env.REACT_APP_API_URL.replace(/(\/api)$/, '')

  return api
    .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
    .then(() => api.post('/login', { ...payload }))
}

export const registerUser = (payload: RegisterPayloadTypes) => {
  return api.post('/register', { ...payload })
}

export const logoutUser = () => api.get('/logout')

export const fetchAuthUser = () => api.get('/me')

export const requestResetPassword = payload =>
  api.post('/password/request-reset', payload)

export const resetPassword = payload => api.post('/password/reset', payload)

export const verifyEmail = payload => api.get(`/verify-email${payload}`)


