import React, { ReactNode } from 'react'
import styled from 'styled-components'
import {isNotNilOrEmpty} from 'utils/ramda'

type CheckboxTypes = {
  id?: string
  name: string
  label: ReactNode | string
  register: any
  error?: string | null
  disabled?: boolean
  checked?: boolean
  color?: string
  colorBg?: string
  value?: boolean
  onChange?: any
  noMargin?: boolean
}

const Checkbox = ({
  disabled,
  id,
  name,
  label,
  register,
  error,
  color,
  colorBg,
  onChange,
  noMargin
}: CheckboxTypes) => {
  const changeProps = isNotNilOrEmpty(onChange)
    ? { onChange }
    : {}

  return (
    <>
      <Wrapper noMargin={noMargin}>
        <CheckboxLabel
          error={error}
          disabled={disabled}
          color={color}
          colorBg={colorBg}
        >
          <CheckboxInput
            type='checkbox'
            id={id}
            {...register(name)}
            disabled={disabled}
            color={color}
            colorBg={colorBg}
            {...changeProps}
          />
          {label}
          <Checkmark color={color} />
        </CheckboxLabel>
        {error && <Error>{error}</Error>}
      </Wrapper>
    </>
  )
}

export default Checkbox

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: ${({ noMargin }) => noMargin ? '0' : '15px'};
`
const CheckboxInput = styled.input`
  accent-color: ${({ theme, color }) => color || theme.colors.primary};
  margin-top: 1px;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme, colorBg }) =>
      colorBg || theme.colors.copperBg};
    border-color: ${({ theme, color }) => color || theme.colors.copper};
  }

  &:checked ~ span::after {
    display: block;
  }
`
const CheckboxLabel = styled.label`
  display: flex;
  position: relative;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  padding-left: 30px;
  color: ${({ theme, error, disabled }) =>
    disabled ? theme.colors.trout : error ? theme.colors.error : 'inherit'};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};

  a {
    color: ${({ theme, color }) => color || theme.colors.copper};

    &:hover {
      color: ${({ theme, colorBg }) => colorBg || theme.colors.lightCopper};
    }
  }
`
const Checkmark = styled.span`
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.border};
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme, color }) => color || theme.colors.copper};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 8px;
`
