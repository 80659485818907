import theme from './theme'

export const STATUS_LABEL = {
  complete: 'Complete',
  submitted: 'Submitted',
  completed: 'Completed',
  in_progress: 'In progress',
  reported: 'Reported',
  not_required: 'Not required',
  required: 'Required',
  rectified: 'Rectified',
  draft: 'Draft',
  '': 'Draft'
}

export const STATUS_COLOR = {
  complete: {
    font: theme.colors.green,
    background: theme.colors.lightGreen
  },
  completed: {
    font: theme.colors.green,
    background: theme.colors.lightGreen
  },
  submitted: {
    font: theme.colors.green,
    background: theme.colors.lightGreen
  },
  rectified: {
    font: theme.colors.green,
    background: theme.colors.lightGreen
  },
  in_progress: {
    font: theme.colors.trout,
    background: theme.colors.border
  },
  not_required: {
    font: theme.colors.trout,
    background: theme.colors.border
  },
  draft: {
    font: theme.colors.trout,
    background: theme.colors.border
  },
  required: {
    font: theme.colors.copper,
    background: theme.colors.copperBorder
  },
  reported: {
    font: theme.colors.copper,
    background: theme.colors.copperBorder
  },
  '': {
    font: theme.colors.trout,
    background: theme.colors.border
  }
}
