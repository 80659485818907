import React from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components/formComponents'
import ExpandableStepInfo from 'components/atoms/ExpandableStepInfo'
import BuildingUploadedInfoItem from 'modules/buildingDetails/components/BuildingUploadedInfoItem'
import { pathOr, propOr } from 'ramda'
import BuildingUploadedRiskItem from 'modules/buildingDetails/components/BuildingUploadedRiskItem'
import SectionNotCompletedInfo from './SectionNotCompletedInfo'
import { isNotNilOrEmpty } from 'utils/ramda'
import { uploadedInfo } from 'utils/building'
import { FlameIcon, PlusIcon } from 'assets/icons'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentUser } from 'redux/selectors/authSelectors'

const WallDesignInformationSection = ({ building }) => {
  const formState = pathOr([], ['buildingFormsState'], building)

  const {
    isAnythingCombustible,
    isExternalMaterialCombustible,
    externalMaterialCombustibilityDetails,
    isTraditionalMasonry,
    hasOuterCladding,
    externalFacingMaterials,
    otherWallFeatures,
    externalWallSystems,
    wallFeaturesOrAttachments,
    wallFeaturesAdditionalInfo,
    fireRiskAssessmentStatus,
    fireSpreadRiskLevel,
    fireRiskMitigationAdditionalMeasures,
    areAdditionalMeasuresUnnecessary,
    otherAdditionalMeasuresTaken
  } = uploadedInfo(building)

  const buildingStatus = pathOr('', ['buildingStatus', 'value'], building)
  // @ts-ignore
  const isBuildingActive = buildingStatus === 'active'

  const currentUser = useAppSelector(selectCurrentUser)
  // @ts-ignore
  const isFrs = currentUser?.type === 'emergency_service'
  return (
    <Section>
      <SectionTitle>
        Wall design
        {isAnythingCombustible && (
          <IconWrapper>
            <FlameIcon />
          </IconWrapper>
        )}
      </SectionTitle>
      {formState.length >= 1 ? (
        <>
          <ExpandableStepInfo
            title='Traditional Masonry Construction'
            isCompleted
            status={isTraditionalMasonry}
          >
            {hasOuterCladding && (
              <BuildingUploadedInfoItem
                item={{
                  title: 'External Cladding',
                  list: externalFacingMaterials,
                  isCombustible: isExternalMaterialCombustible,
                  description: externalMaterialCombustibilityDetails
                }}
              />
            )}
          </ExpandableStepInfo>
          {externalWallSystems.map((wallSystem, index) => (
            <ExpandableStepInfo
              title={
                externalWallSystems.length < 2
                  ? 'External Wall System'
                  : 'External Wall System ' + (index + 1)
              }
              isCompleted
              key={propOr('', 'id', wallSystem)}
            >
              <BuildingUploadedInfoItem
                item={{
                  title: 'External Facing Materials',
                  list: propOr([], 'externalFacingMaterials', wallSystem),
                  isCombustible: propOr({}, 'isCombustible', wallSystem),
                  description: propOr(
                    '',
                    'combustibilityFurtherDetails',
                    wallSystem
                  )
                }}
              />
            </ExpandableStepInfo>
          ))}
          <ExpandableStepInfo title='Wall Features and Attachments' isCompleted>
            <BuildingUploadedInfoItem
              item={{
                title: 'Features/Attachments',
                list: otherWallFeatures
                  ? [
                      ...wallFeaturesOrAttachments,
                      { id: '', name: otherWallFeatures }
                    ]
                  : wallFeaturesOrAttachments,
                isCombustible: isNotNilOrEmpty(wallFeaturesAdditionalInfo),
                description: wallFeaturesAdditionalInfo
              }}
            />
          </ExpandableStepInfo>
          <ExpandableStepInfo title='Risk and Mitigation' isCompleted>
            <BuildingUploadedRiskItem
              item={{
                list: otherAdditionalMeasuresTaken
                  ? [
                      ...fireRiskMitigationAdditionalMeasures,
                      otherAdditionalMeasuresTaken
                    ]
                  : fireRiskMitigationAdditionalMeasures,
                status: fireRiskAssessmentStatus,
                riskLevel: fireSpreadRiskLevel,
                noAdditionalMeasures: areAdditionalMeasuresUnnecessary
              }}
            />
          </ExpandableStepInfo>
        </>
      ) : !isFrs ? (
        <SectionNotCompletedInfo
          disabled={!isBuildingActive}
          title='No wall design information has been submitted'
          content='Submit a record of the design and construction of external walls.'
          icon={
            <IconWrapper isBuildingActive={isBuildingActive}>
              <PlusIcon />
            </IconWrapper>
          }
          buttonLabel='Add wall design'
          activeForm='wall_design'
        />
      ) : (
        <FrsNoButtonView>No floor plans has been submitted</FrsNoButtonView>
      )}
    </Section>
  )
}

export default WallDesignInformationSection

const Section = styled.div`
  margin-top: 26px;
`
const IconWrapper = styled.div`
  fill: ${({ theme, isBuildingActive }) => isBuildingActive ? theme.colors.copper : theme.colors.gray};
`
const FrsNoButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
`
