import React from 'react'
import Icon from './Icon'

export const CheckIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.5 6.66659H1.5M10.1667 1.33325V3.99992M4.83333 1.33325V3.99992M4.7 14.6666H10.3C11.4201 14.6666 11.9802 14.6666 12.408 14.4486C12.7843 14.2569 13.0903 13.9509 13.282 13.5746C13.5 13.1467 13.5 12.5867 13.5 11.4666V5.86659C13.5 4.74648 13.5 4.18643 13.282 3.7586C13.0903 3.38228 12.7843 3.07632 12.408 2.88457C11.9802 2.66659 11.4201 2.66659 10.3 2.66659H4.7C3.5799 2.66659 3.01984 2.66659 2.59202 2.88457C2.21569 3.07632 1.90973 3.38228 1.71799 3.7586C1.5 4.18643 1.5 4.74648 1.5 5.86659V11.4666C1.5 12.5867 1.5 13.1467 1.71799 13.5746C1.90973 13.9509 2.21569 14.2569 2.59202 14.4486C3.01984 14.6666 3.5799 14.6666 4.7 14.6666Z'
        stroke='#98A2B3'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  )
}

export default CheckIcon
