import React from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components/formComponents'
import TextButton from 'components/atoms/TextButton'
import { EditIcon } from 'assets/icons'
import { pathOr } from 'ramda'
import { Tooltip } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setActiveForm } from 'redux/slices/formSlice'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'

type floorsPropsType = {
  floors?: {
    floorsNumber: number
    groundFloor: boolean
    lowerGroundFloor: boolean
    undergroundFloorsNumber: number
  }
}

const FloorsInformationSection = ({ floors }: floorsPropsType) => {
  const {
    floorsNumber,
    groundFloor,
    lowerGroundFloor,
    undergroundFloorsNumber
  } = floors || {}

  const building = useAppSelector(selectCurrentBuilding)

  const buildingStatus = pathOr('', ['buildingStatus', 'value'], building)
  // @ts-ignore
  const isBuildingActive = buildingStatus === 'active'

  const isFloorsInfoEmpty =
    floorsNumber === 0 &&
    groundFloor === false &&
    lowerGroundFloor === false &&
    undergroundFloorsNumber === 0

  const dispatch = useAppDispatch()

  const hasFloors = floorsNumber && floorsNumber > 0 ? floorsNumber : '-'
  const hasUndergroundFloors =
    undergroundFloorsNumber && undergroundFloorsNumber > 0
      ? undergroundFloorsNumber
      : '-'

  const handleOpenEdit = () => {
    dispatch(setActiveForm('plans'))
  }

  return (
    <Section>
      {isFloorsInfoEmpty ? (
        <SectionWrapper>
          <SectionTitle>Floors</SectionTitle>
          {isBuildingActive ? (
            <TextButton
              label='Edit'
              onClick={handleOpenEdit}
              icon={
                <IconWrapper>
                  <EditIcon />
                </IconWrapper>
              }
            />
          ) : (
            <Tooltip title='You have to fill building data first'>
              <TooltipChildWrapper>
                <TextButton
                  label='Edit'
                  onClick={handleOpenEdit}
                  disabled
                  icon={
                    <IconWrapper isBuildingActive={isBuildingActive}>
                      <EditIcon />
                    </IconWrapper>
                  }
                />
              </TooltipChildWrapper>
            </Tooltip>
          )}
        </SectionWrapper>
      ) : (
        <SectionTitle>Floors</SectionTitle>
      )}
      <Item>
        <ItemTitle>Floors Number (above ground)</ItemTitle>
        <ItemValue value={floorsNumber}>{hasFloors}</ItemValue>
      </Item>
      <Item>
        <ItemTitle>Ground Floor</ItemTitle>
        <ItemValue value={groundFloor}>{groundFloor ? 'Yes' : 'No'}</ItemValue>
      </Item>
      <Item>
        <ItemTitle>Lower ground floor</ItemTitle>
        <ItemValue value={lowerGroundFloor}>
          {lowerGroundFloor ? 'Yes' : 'No'}
        </ItemValue>
      </Item>
      <Item>
        <ItemTitle>Underground Floors Number</ItemTitle>
        <ItemValue value={undergroundFloorsNumber}>
          {hasUndergroundFloors}
        </ItemValue>
      </Item>
    </Section>
  )
}

export default FloorsInformationSection

const Section = styled.div`
  margin-top: 26px;
`
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
`
const ItemTitle = styled.div``
const ItemValue = styled.div`
  color: ${({ theme, value }) =>
    value ? theme.colors.copper : theme.colors.lightTrout};
`
const IconWrapper = styled.div`
  fill: ${({ theme, isBuildingActives }) => isBuildingActives ? theme.colors.copper : theme.colors.gray};
`
const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const TooltipChildWrapper = styled.div`
  position: relative;
  z-index: 1;
`
