import React, { ReactNode } from 'react'
import styled from 'styled-components'

type ContentPanelProps = {
  children: ReactNode
}

const ContentPanel = ({ children }: ContentPanelProps) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default ContentPanel

const Wrapper = styled.div`
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(101, 118, 184, 0.08),
  0 2px 4px rgba(101, 118, 184, 0.12),
  0 8px 16px rgba(101, 118, 184, 0.06),
  0 1px 2px rgba(101, 118, 184, 0.06);
  border-radius: 6px;
  margin-bottom: 24px;
`
