import React from 'react'
import styled from 'styled-components'

const CounterInput = props => {
  const { error, label, register, name, additionalLabel, setValue, value, min = 0 } = props

  const handleIncrement = () => setValue(name, Number(value) + 1)
  const handleDecrement = () => setValue(name, Math.max(min, Number(value) - 1))

  return (
    <Wrapper>
      {label && (
        <LabelWrapper>
          <Label>
            {label}
          </Label>
          {additionalLabel && additionalLabel}
        </LabelWrapper>
      )}
      <InputWrapper>
        <Decrement onClick={handleDecrement}>-</Decrement>
        <StyledInput
          {...register(name)}
          {...props}
        />
        <Increment onClick={handleIncrement}>+</Increment>
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default CounterInput

const Wrapper = styled.div`
  margin-bottom: 15px;
`

const InputWrapper = styled.div`
  display: flex;
`

const Decrement = styled.div`
  border-radius: 8px 0 0 8px;
  height: 44px;
  width: 52px;
  background-color: #EDF1F7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-right: none;
  cursor: pointer;
  transition: all .3s;
  font-size: 20px;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.border};
  }
`

const Increment = styled.div`
  border-radius: 0 8px 8px 0;
  height: 44px;
  width: 52px;
  background-color: #EDF1F7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-left: none;
  cursor: pointer;
  transition: all .3s;
  font-size: 20px;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.border};
  }
`


const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  outline: none;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  text-align: center;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
