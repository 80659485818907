import React from 'react'
import Icon from './Icon'

export const EmailIcon = (props: { [x: string]: any }) => {
  return (
    <Icon
      width='14'
      height='14'
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path fillRule='evenodd' clipRule='evenodd'
            d='M8.83588 0.924956C7.53214 0.530493 6.13602 0.566468 4.85432 1.02755C3.57263 1.48863 2.47355 2.3503 1.71989 3.4849C0.966226 4.61951 0.598075 5.9667 0.669973 7.3269C0.741871 8.68711 1.24999 9.98798 2.11907 11.0368C2.98814 12.0856 4.17194 12.8266 5.49509 13.15C6.81825 13.4734 8.21039 13.362 9.46527 12.8323C9.63775 12.7595 9.71855 12.5607 9.64575 12.3882C9.57294 12.2157 9.3741 12.1349 9.20162 12.2077C8.08095 12.6808 6.8377 12.7803 5.65606 12.4915C4.47442 12.2026 3.41723 11.5409 2.6411 10.6042C1.86498 9.66759 1.4112 8.50585 1.34699 7.29112C1.28279 6.07639 1.61156 4.87328 2.28462 3.86003C2.95767 2.84677 3.93921 2.07726 5.08382 1.66549C6.22843 1.25372 7.47524 1.2216 8.63954 1.57387C9.80384 1.92614 10.8237 2.64408 11.548 3.62133C12.2724 4.59858 12.6627 5.78315 12.6611 6.99958L12.6611 7.00001V8.00001C12.6611 8.35214 12.5213 8.68984 12.2723 8.93883C12.0233 9.18782 11.6856 9.3277 11.3334 9.3277C10.9813 9.3277 10.6436 9.18782 10.3946 8.93883C10.1456 8.68984 10.0058 8.35214 10.0058 8.00001V7.00001C10.0058 6.99887 10.0058 6.99773 10.0057 6.99659C10.0039 5.33817 8.65895 3.99432 7.00011 3.99432C5.34013 3.99432 3.99446 5.34 3.99446 6.99997C3.99446 8.65995 5.34013 10.0056 7.00011 10.0056C8.01886 10.0056 8.91924 9.49877 9.46283 8.72348C9.56265 8.98159 9.71577 9.21875 9.91524 9.41822C10.2914 9.79436 10.8015 10.0057 11.3334 10.0057C11.8654 10.0057 12.3755 9.79436 12.7517 9.41822C13.1278 9.04209 13.3391 8.53195 13.3391 8.00001V7.00045C13.3408 5.63835 12.9038 4.3119 12.0927 3.21762C11.2816 2.12334 10.1396 1.31942 8.83588 0.924956ZM4.67242 6.99997C4.67242 5.71443 5.71456 4.67229 7.00011 4.67229C8.28565 4.67229 9.32779 5.71443 9.32779 6.99997C9.32779 8.28552 8.28565 9.32766 7.00011 9.32766C5.71456 9.32766 4.67242 8.28552 4.67242 6.99997Z' />
    </Icon>
  )
}

export default EmailIcon
