import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Terms = ({ url }): ReactElement => {
  return <Frame src={url} />
}

export default Terms

const Frame = styled.iframe`
  height: 100vh;
  width: 100vw;
  border: none;
  padding: 36px 0;
  background: #fff;
`
